import { observable, action, runInAction, configure, makeObservable } from 'mobx';
import { ILiabilityInvestigation, IExposureInfo, IRepairStatus, IIncidentDetails } from '../models/claim-interface';
import { getExposureService, liabilityInvestigationService, repairStatusService, paymentInformationService,
  getContactsService, getClaimsIncidentDetailsService } from '../services';
import * as schema from '../validation/claim-search-validation-schema';
import { ErrorMessages } from '../../../utils/errorMessages';
import { IPaymentInfo } from '../../payments/models/payment-info-interface';
import {claimsStatusService} from '../../claim-status/services/get-claim-status-service';
// Enable Restrict mode. Recommended
// configure({ enforceActions: 'observed' });

/**
 * Claim Search Store
 *
 * @export
 * @class ClaimSearchStore
 */

configure({ enforceActions: 'observed' });
export default class ClaimSearchStore {

  constructor() {
    makeObservable(this); //Newer version of mobX
  }

  @observable error: string | null = null; // API error message. Not shown if null

  @observable claimNumber: string = '';

  @observable rentalCoverage: string = '';

  @observable okayToPay: string = '';

  @observable formSubmittable: boolean = false;

  @observable liabilityStatusMessage: string = '';

  @observable blur: boolean = false;

  @observable feedback: string = '';

  @observable searchInit: boolean = false;

  @observable exposureInfoLoading: boolean = false;

  @observable incidentDetailsLoading: boolean = false;

  @observable repairStatusInfoLoading: boolean = false;

  @observable paymentsLoading: boolean = false;

  @observable paymentsError: string = '';

  @observable showPaymentServiceError: boolean = false;

  @observable liabilityInvestigation : ILiabilityInvestigation | null = null;

  @observable exposureInfo: IExposureInfo | null = null;

  @observable incidentDetails: IIncidentDetails | null = null;

  @observable directionToPay: boolean = false;

  @observable repairStatusInfo: IRepairStatus | null = null;

  @observable paymentInformation : IPaymentInfo[] = [];

  @observable activeTab: number = 0;

  @action
  onChange(value: string | undefined): void {
    //@ts-ignore
    this.claimNumber = value;
    this.validateClaimNumber();
  }

  @action
  onTabChange(value: number): void {
    this.activeTab = value;
  }

  @action
  async onSubmit() {
    this.activeTab = 0;
    this.error = null;
    this.searchInit = true;
    this.rentalCoverage = 'N/A';
    this.okayToPay = 'N/A';
    this.showPaymentServiceError = false;
    this.exposureInfoLoading = true;
    this.incidentDetailsLoading = true;
    this.repairStatusInfoLoading = true;
    this.liabilityStatusMessage = '';
    this.paymentInformation = [];
    this.liabilityInvestigation = null;
    let liabilityInvestigationData: ILiabilityInvestigation | null = null;
    try {
      //clear values
      this.paymentsLoading = true;

      const exposureData = await getExposureService.getDeductibleBillingInfo(this.claimNumber);
      const [paymentData, directionToPayData, incidentData, repairStatusData, claimStatusInfo] =  await Promise.all([
        this.getPaymentInformation(),
        this.getContacts(),
        getClaimsIncidentDetailsService.getClaimsIncidentDetails(this.claimNumber),
        this.getRepairStatus(exposureData),
        this.getClaimsStatusInfo()
      ]);

      if (exposureData?.lossParty === 'Third Party') {
        liabilityInvestigationData = await this.getLiabilityInvestigationInfo();
      } else {
        this.liabilityStatusMessage = 'N/A';
      }

      runInAction(() => {
        this.activeTab = 0;
        this.searchInit = true;
        this.rentalCoverage = claimStatusInfo?.rentalCoverage ?? 'N/A';
        this.okayToPay = exposureData.okToPay;
        this.exposureInfoLoading = false;
        this.incidentDetailsLoading = false;
        this.repairStatusInfoLoading = false;
        this.paymentsLoading = false;
        this.exposureInfo = exposureData;
        this.repairStatusInfo = repairStatusData;
        this.paymentInformation = paymentData;
        this.directionToPay = directionToPayData;
        this.liabilityInvestigation = liabilityInvestigationData;
        this.incidentDetails = incidentData;
      });
    } catch (error: any) {
      runInAction(() => {
        this.exposureInfoLoading = false;
        this.incidentDetailsLoading = false;
        this.error = error.message;
        this.searchInit = false;
      });
    }
  }

  @action
  setFeedback(feedback: string){
    this.feedback = feedback;
  }

  @action
  sendFeedback() {

  };

  @action
  skipFeedback() {

  }

  @action
  async validateClaimNumber() {
    try {
      await schema['claimNumber'].validate(this.claimNumber);
      runInAction(() => this.formSubmittable = true);
    } catch (error: any) {
      runInAction(() => this.formSubmittable = false);
    }
  }

  @action
  async getContacts() : Promise<boolean> {
    let directionToPay : boolean = false;
    try {
      let contactsInfo = await getContactsService.getContactsInfo(this.claimNumber);
      let company = contactsInfo.find(eachCompany => {
        return eachCompany.roles.entry.find(eachEntry => eachEntry.roleName === 'repairshop');
      });
      const repairContact = company ? company.roles.entry.find( entry => entry.roleName === 'repairshop') : null;
      directionToPay = repairContact ? repairContact.directionToPayInd : false;

    } catch (error: any) {
      // todo heap
      return directionToPay;
    }
    runInAction(() => {
      this.directionToPay = directionToPay;
    });
    return directionToPay;
  };

  @action
  async getRepairStatus(exposureInfo : IExposureInfo | null): Promise<any> {
    if (exposureInfo) {
      try {
        const repairData = await repairStatusService.getRepairStatusInfo(this.claimNumber, exposureInfo.incident.id);
        return repairData;
        // this.repairStatusInfoLoading = false;
      } catch (error: any) {
        this.repairStatusInfo = null;//Will default to No
        this.repairStatusInfoLoading = false;
      }
    } else {
      this.repairStatusInfo = null;//Will default to No
      this.repairStatusInfoLoading = false;
    };
  };

  @action
  async getPaymentInformation() : Promise<any> {
    this.paymentsError = '';
    this.paymentsLoading = true;
    try {
      const paymentInfo = await paymentInformationService.getPaymentInformation(this.claimNumber);
      this.paymentsLoading = false;
      this.showPaymentServiceError = false;
      return paymentInfo;
    } catch (error: any) {
      this.paymentsLoading = false;
      this.showPaymentServiceError = error;
      this.paymentsError = error.message;
    }
  };

  @action
  async getLiabilityInvestigationInfo() {
    try {
      return await liabilityInvestigationService.getLiabilityInfo(this.claimNumber);
    } catch (error: any) {
      this.liabilityStatusMessage = ErrorMessages.LIABILITY_NOT_AVAILABLE_MESSAGE;
      return null;
    }
  };

  @action
  async getClaimsStatusInfo() {
    try {
      return await claimsStatusService.getClaimStatusInfo(this.claimNumber);
    } catch (error: any) {
      return null;
    }
  }
}

export const claimSearchStore = new ClaimSearchStore();


