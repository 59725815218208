
import { GridCol, IconInfo, Link } from '@lmig/lmds-react';
import styles from '../claim-search.module.scss';
// import FeedbackComponent from '../components/feedback-component';

interface props{
	desktop: boolean;
}

const NotSeeingFeature = (props:props): JSX.Element => (
  <GridCol base={12} md={6} className={props.desktop ? styles.desktop: ''}>
    <IconInfo 
      size="16"
      title={'Contact David.Monken@LibertyMutual.com'}
      style={{
        marginRight: '0.5rem',
        marginTop: '-0.3rem'
      }}
    />
    <b>Not seeing what you need during the pilot program?</b>
    <p>Please contact Senior Repair Network Specialist David Monken at <Link href={'mailto:David.Monken@LibertyMutual.com.'}>David.Monken@LibertyMutual.com.</Link></p>
  </GridCol>
);


export default NotSeeingFeature;
