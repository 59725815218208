import styles from './box-component.module.scss';
import {useThreshold} from '@lmig/lmds-react';

const Box = (): JSX.Element => {
  const threshold = useThreshold();
  if (threshold === 'base' || threshold === 'sm'){
    return (
      <span className={`${styles.mobileBox}  ${styles.box}`}></span>
    );
  } else {
    return (
      <span className={`${styles.desktopBox}  ${styles.box}`}></span>
    );
  }
};

export default Box;
