import {Response} from 'miragejs';
import rs from '../../features/registration/models/registration-statuses-enum';

export let cache: any = {trackingIds: {}};


const statuses = [
  rs.Received, // 0
  rs.Validated, // 1
  rs.ValidationFailed, // 2
  rs.IdentityCreated, // 6
  rs.IdentityCreationFailed, // 7
  rs.IdentityCreationFailedDuplicate, // 8
  rs.ProfileCreated, // 9
  rs.ProfileCreationFailed, // 10
  rs.EmailGenerationFailed, // 13
  rs.RollbackCompleted, //14
  rs.Completed//15
];

const genStatusRes = (status: string) => new Response(200, {}, {status});

const caseGenerator = (trackingIdsCache:any, trackingId:string, steps: { state: number, terminating: boolean }[]) => {
  const step = steps[trackingIdsCache[trackingId]-1];
  if (!step) return genStatusRes('mock_error');
  if (!step.terminating) trackingIdsCache[trackingId]++;
  return genStatusRes(statuses[step.state]);
};

export default function auth0StatusCheckRouter(schema: any, request: any) {
  const trackingId = request.params?.trackingId;

  if (!(trackingId in cache.trackingIds)) {
    cache.trackingIds[trackingId] = 1;
  }

  // [scenario] everything works successfully
  if (trackingId === rs.Completed) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 1, terminating: false},
      {state: 6, terminating: false},
      {state: 9, terminating: false},
      {state: 11, terminating: false},
      {state: 15, terminating: true}
    ]);
  }

  // [scenario] generic validation failed.
  if (trackingId === rs.ValidationFailed) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 2, terminating: true}
    ]);
  }

  // [scenario] identity creation failed.
  if (trackingId === rs.IdentityCreationFailed) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 1, terminating: false},
      {state: 7, terminating: true}
    ]);
  }

  // [scenario] identity creation failed - ACCOUNT DUPLICATE.
  if (trackingId === rs.IdentityCreationFailedDuplicate) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 1, terminating: false},
      {state: 8, terminating: true}
    ]);
  }

  // [scenario] Profile creation failed.
  if (trackingId === rs.ProfileCreationFailed) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 1, terminating: false},
      {state: 6, terminating: false},
      {state: 10, terminating: true}
    ]);
  }

  // [scenario] Email creation failed.
  if (trackingId === rs.EmailGenerationFailed) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 1, terminating: false},
      {state: 6, terminating: false},
      {state: 9, terminating: false},
      {state: 11, terminating: false},
      {state: 13, terminating: true}
    ]);
  }

  // [scenario] rollback successful.
  if (trackingId === rs.RollbackCompleted) {
    return caseGenerator(cache.trackingIds, trackingId, [
      {state: 0, terminating: false},
      {state: 1, terminating: false},
      {state: 6, terminating: false},
      {state: 9, terminating: false},
      {state: 11, terminating: false},
      {state: 14, terminating: true}
    ]);
  }

  return caseGenerator(cache.trackingIds, trackingId, [
    {state: 0, terminating: false},
    {state: 2, terminating: true}
  ]);

}
