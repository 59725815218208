import { Card, Heading, Notification } from '@lmig/lmds-react';
import moment from 'moment';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@lmig/lmds-react-table';
import styles from '../../claim-search/claim-search.module.scss';
import { IIncidentDetails } from '../../claim-search/models/claim-interface';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import { IncidentCategoryLookup, IncidentCauseLookup } from '../../../utils/incident-props-lookup';

interface PropsType {
  isLoading: boolean;
  incidentDetails: IIncidentDetails | null;
}

const formatIncidentCategroy = (categroy: string | null): String => {
  if (categroy === null || categroy === undefined || categroy.length <= 0) return 'N/A';

  return IncidentCategoryLookup.get(categroy) || 'N/A';
};

const formatIncidentCause = (cause: string | null): String => {
  if (cause === null || cause === undefined || cause.length <= 0) return 'N/A';

  return IncidentCauseLookup.get(cause) || 'N/A';
};

const formatDate = (date: string | null, time: string | null): String => {

  if (!date || date === null || date === undefined || date.length <= 0 
    || time === null || time === undefined || time.length <= 0) return 'N/A';

  const dateString = moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  const timeString = moment(time, 'HH:mm').format('h:mm A');

  return dateString + ' at ' + timeString;
};

const formatLocation = (location: {city: string | null, state: string | null}) : String => {

  if (!location || location.state === null || location.state === undefined || location.state.length <= 0 
    || location.city === null || location.city === undefined || location.city.length <= 0) return 'N/A';

  return `${location.city}, ${location.state}`;

};

const IncidentDetails = (props: PropsType): JSX.Element => 
  props.isLoading ? (
    <CardShimmer></CardShimmer>
  ) :  (
    <DataTable>
      <Card className={styles.card} elevation="raised">
        <Heading type='h5-bold'>Incident details</Heading>
        {props.incidentDetails ? (
          <>
            <Table id="incident-details-info-table">
              <TableHeader>
                <TableRow>{<></>}</TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Type of incident</b>
                  </TableCell>
                  <TableCell
                    data-testid="incident-details-info-type"
                    textAlign={'right'}
                  >
                    { formatIncidentCategroy(props.incidentDetails?.lossCategory) }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Incident date and time</b>
                  </TableCell>
                  <TableCell
                    data-testid="incident-details-info-date-time"
                    textAlign={'right'}
                  >
                    { formatDate(props.incidentDetails?.lossDate, props.incidentDetails?.lossTime) } 
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Incident location</b>
                  </TableCell>
                  <TableCell
                    data-testid="incident-details-info-location"
                    textAlign={'right'}
                  >
                    { formatLocation(props.incidentDetails?.lossLocation) } 
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Cause of incident</b>
                  </TableCell>
                  <TableCell
                    data-testid="incident-details-info-cause"
                    textAlign={'right'}
                  >
                    { formatIncidentCause(props.incidentDetails?.lossCause) } 
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <Notification
            alert="There are no incident details"
            highlightType="negative"
          >
              Please try a different claim number.
          </Notification>
        )}
      </Card>
    </DataTable>
  );


export default IncidentDetails;

