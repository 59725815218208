enum RegistrationStatuses {
  Received = 'registration_request_received',
  Validated = 'registration_request_validated',
  ValidationFailed = 'registration_request_validation_failed',
  IdentityCreated = 'registration_request_identity_created',
  IdentityCreationFailed = 'registration_request_identity_creation_failed',
  IdentityCreationFailedDuplicate = 'registration_request_identity_creation_failed_duplicate',
  ProfileCreated = 'registration_request_profile_created',
  ProfileCreationFailed = 'registration_request_profile_creation_failed',
  EmailGenerationFailed = 'registration_request_email_generation_failed',
  Completed = 'registration_request_completed',
  RollbackCompleted = 'registration_request_rollback_complete'
}

export default RegistrationStatuses;
