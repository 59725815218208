import { BodyText, Button, Heading } from '@lmig/lmds-react';
import env from '../../../utils/env';
import { trackCustomHeapEvent } from '../../../utils/heap';

const UploadSupplement = () => {

  const handleSupplementClick = () => {
    trackCustomHeapEvent('view-upload-supplement-clicked-from-claim-status', {});
    window.open(env.supplementURL);
  };

  return (
    <>
      <Heading type={'h3-light'}>Upload Supplement</Heading>
      <BodyText type="article">
        <p>
          Add necessary documents to a claim without logging in.
        </p>
      </BodyText>
      <Button
        size="large"
        variant="secondary"
        data-testid="uploadSupplement"
        onClick={handleSupplementClick}
      >
        Upload supplement
      </Button>
    </>
  );
};

export default UploadSupplement;
