import {
  GridCol,
  GridRow,
  Hide,
  TileRadio,
  TileRadioGroup
} from '@lmig/lmds-react';
import styles from './toggle-button.module.scss';
import { registrationStore as store } from '../store/registration-store';

const ToggleButton = () => {
  const handleOnChange = (event: any) => {
    store.changeSearch();
    store.formErrorShopInfo = [];
  };
  return (
    <TileRadioGroup
      labelVisual=""
      name="chooseSearchOption"
      onChange={handleOnChange}
    >
      <GridRow vAlign="middle" >
        <Hide thresholds={['base', 'sm', 'md']}>
          <GridCol className={styles.newLine} base={1} sm={1}></GridCol>
        </Hide>
        <GridCol base={6} lg={2}>
          <TileRadio
            checked={!store.searchByLmId}
            labelVisual="Tax ID"
            value="searchByTaxId"
            className={styles.toggleButton + ' ' + styles.borderLeft}
          />
        </GridCol>
        <GridCol base={6} lg={2}>
          <TileRadio
            checked={store.searchByLmId}
            labelVisual="Liberty Mutual ID"
            value="searchByLmId"
            data-testid="LMID"
            className={styles.toggleButton + ' ' + styles.borderRight}
          />
        </GridCol>
        <Hide thresholds={['base', 'sm', 'md']}>
          <GridCol className={styles.newLine} base={1} sm={1}></GridCol>
        </Hide>
      </GridRow>
    </TileRadioGroup>
  );
};

export default ToggleButton;
