import { Server } from 'miragejs';
import { mainRouter} from './routes/main-router';
import env from '../utils/env';
import shopListRouter from './routes/shop-list-router';
import createUserRouter from './routes/registration-router';
import auth0StatusCheckRouter from './routes/status-check-router';


// simulate delay, except in cypress env
const simDelay = (fixedDelay: number | null = null) =>
  //@ts-ignore
  window.Cypress ? 0 : fixedDelay !== null ? fixedDelay : (Math.floor(Math.random() * 1000) + 500);

export function makeMockServer(appEnv: number) {
  return new Server({
    environment: process.env.NODE_ENV,
    routes() {
      this.get(`${env.claimStatusApiUrl}/shops`, shopListRouter, { timing: simDelay(0) });

      this.post('*/graphql', mainRouter, { timing: simDelay(500) });

      this.post(env.registrationRequestApiEndpoint, createUserRouter, { timing: simDelay() });

      this.get(`${env.statusCheckUrl}/:trackingId`, auth0StatusCheckRouter, { timing: simDelay(0) });
      this.passthrough('https://siteintercept.qualtrics.com/**');
    }
  });
};

