import axios from 'axios';
// import {authService} from './index';
// import {logoutUser} from '../containers/app-layout/header/logout/logout-component';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

export const axiosProtectedInstance = axios.create();

axiosProtectedInstance.interceptors.response.use((response) => {
  return response;
}, async function (error) {
  // todo: this is temporary until we figure out why lambda authorizer sends 403 for unauthorized access. Remove 403
  // if ((error?.response?.status === 401 || error?.response?.status === 403)) {
  //   return logoutUser();
  // }
  // client doesn't have valid JWT anymore
  return Promise.reject(error);
});

export default axiosInstance;


// export const protectedHeaders = () => {
//   return {
//     'Content-Type': 'application/json',
//     'Authorization': authService.getJWT() || 'invalid'
//   };
// };

