const regexIe11 = /\bTrident\/7.0;.*rv:11/;

const findMatchesIe11 = (userAgent: string) => userAgent.match(regexIe11) || [];

const isUsingIe11 = () => {
  const matchesIe11 = findMatchesIe11(window.navigator.userAgent) || [];
  return matchesIe11.length > 0;
};

export default isUsingIe11;
