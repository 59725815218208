import { Response } from 'miragejs';

export const claimStatusRouter = (
  claimNumber: string,
  exposureNumber: string
) => {
  if (claimNumber === '999999999') {
    return new Response(404, {}, { message: 'No Claim Found' });
  } else if (claimNumber === '111111111') {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimStatus: null
        },
        errors: [
          {
            path: ['getClaimStatus'],
            data: null,
            errorType: '3600',
            errorInfo: null,
            locations: [
              {
                line: 2,
                column: 3,
                sourceName: null
              }
            ],
            message: 'Claim Status Service error'
          }
        ]
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0002') {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimStatus: {
            claimNumber: claimNumber,
            appraisalAvailable: 'No',
            rentalCoverage: 'Yes',
            paymentStatus: 'N/A',
            liabilityStatus: 'Complete',
            appraiser: 'N/A'
          }
        }
      }
    );
  } else if (claimNumber === '123456789' && exposureNumber === '0003') {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimStatus: {
            claimNumber: claimNumber,
            appraisalAvailable: 'No',
            rentalCoverage: 'No',
            paymentStatus: 'Pending',
            liabilityStatus: 'In progress',
            appraiser: 'abc'
          }
        }
      }
    );
  } else {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimStatus: {
            claimNumber: claimNumber,
            appraisalAvailable: 'Yes',
            rentalCoverage: 'Yes',
            paymentStatus: 'Pending',
            liabilityStatus: 'In progress',
            appraiser: 'abc'
          }
        }
      }
    );
  }
};

export default claimStatusRouter;
