import { Response } from 'miragejs';

export class ShopData {
  
  shopListData(): Response {
    return new Response(200, {}, 
      {'data':
      {'getProviders': 
      { 'results':  
            [{
              name: 'pravs grn',
              dba: 'pravs grn',
              recipientId: '1010815',
              providerType: 'Direct Repair Facility',
              providerSubType: 'Guaranteed Repair Network Shop',
              address: {
                addressLine1: '1 Arrowhead Cir',
                addressLine2: '',
                city: 'Ashland',
                county: 'Middlesex',
                state: 'MA',
                country: 'US',
                postalCode: '01721-3906',
                addressType: 'Business',
                primaryAddressIndicator: true,
                addressPublicID: 'ab:297101',
                latitude: 42.248132,
                longitude: -71.432726
              }
            },
            {
              name: 'hello',
              dba: 'pravs grn dba',
              recipientId: '1010816',
              providerType: 'Non Direct Repair Facility',
              providerSubType: '',
              address: {
                addressLine1: '12 Arrowhead Cir',
                addressLine2: '',
                city: 'Ashland',
                county: 'Middlesex',
                state: 'MA',
                country: 'US',
                postalCode: '01721-3904',
                addressType: 'Billing',
                primaryAddressIndicator: true,
                addressPublicID: 'ab:297301',
                latitude: 42.248023,
                longitude: -71.432408
              }
            },
            {
              name: 'Bob\'s Shop',
              dba: 'pravs grn dba',
              recipientId: '1010817',
              providerType: 'Non Direct Repair Facility',
              providerSubType: '',
              address: {
                addressLine1: '15 Maple Street',
                addressLine2: '',
                city: 'Ashland',
                county: 'Middlesex',
                state: 'MA',
                country: 'US',
                postalCode: '01721-3904',
                addressType: 'Billing',
                primaryAddressIndicator: true,
                addressPublicID: 'ab:297301',
                latitude: 42.248023,
                longitude: -71.432408
              }
            },
            {
              name: 'JJ Repair Shop',
              dba: 'JJ Repair Shop',
              recipientId: '1010818',
              providerType: 'Direct Repair Facility',
              providerSubType: 'Guaranteed Repair Network Shop',
              address: {
                addressLine1: '15 Peachtree Street',
                addressLine2: '',
                city: 'Atlanta',
                county: 'Fulton',
                state: 'GA',
                country: 'US',
                postalCode: '30303',
                addressType: 'Business',
                primaryAddressIndicator: true,
                addressPublicID: 'ab:297101',
                latitude: 42.248132,
                longitude: -71.432726
              }
            },
            {
              name: 'Auto Repair 123 Shop',
              dba: 'Auto Repair 123 Shop',
              recipientId: '1010819',
              providerType: 'Direct Repair Facility',
              providerSubType: 'Guaranteed Repair Network Shop',
              address: {
                addressLine1: '11 Main Street',
                addressLine2: '',
                city: 'Atlanta',
                county: 'Fulton',
                state: 'GA',
                country: 'US',
                postalCode: '30303',
                addressType: 'Business',
                primaryAddressIndicator: true,
                addressPublicID: 'ab:297101',
                latitude: 42.248132,
                longitude: -71.432726
              }
            }
            ]
      }}});    
  }

  shopNotFound(): Response {
    return new Response(200, {}, []);
  }
}
export default ShopData;
export const shopData = new ShopData();


