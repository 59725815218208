import RegistrationForm from './components/registration-form';

const RegistrationPage = () => {
  return (
    <>
      <RegistrationForm />
    </>
  );
};

export default RegistrationPage;
