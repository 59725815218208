import {Fragment} from 'react';
import styles from '../common-scss/shimmer.module.scss';


type CardShimmerProps = {
  rows?: number,
  className?: string
}

const TableShimmer = ({rows = 1, className = ''}: CardShimmerProps) => (
  <span className={styles.Content}>
    <Fragment>
      <span className={`${styles.Line1} ${styles.Skeleton}`}/>
    </Fragment>
  </span>
);


export default TableShimmer;
