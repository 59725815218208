import { useState } from 'react';
import { Tabs, Tab, Modal, ModalBody, GridRow, GridCol } from '@lmig/lmds-react';
import { PillButton } from '@lmig/lmds-react-pill-button';
import ContactSupportForm from './contact-support-form';
import FaqDetails from './faq-details';
import ContactSupportFormSuccess from './contact-support-form-success';
import ContactSupportFormError from './contact-support-form-error';

const SupportHelpModal = ( props: {claimNumber: string, customerName: string, vehicleYearMakeModel: string}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const onClose = () => {
    setIsOpen(false);
    setIsError(false);
    setIsSuccess(false);
  };

  const TabView = () : JSX.Element => {
    return (
      <Tabs>
        <Tab 
          data-testid='tab-contact-support'
          labelVisual='Contact CIP Support'
        >
          <ContactSupportForm 

            data={{
              claimNumber: props.claimNumber, 
              customerName: props.customerName, 
              vehicleYearMakeModel: props.vehicleYearMakeModel
            }}
            onClose={onClose} 
            onSuccess={() => { setIsSuccess(true); }}
            onError={() => { setIsError(true); }}
          />
        </Tab>
        <Tab 
          data-testid='tab-faq'
          labelVisual='FAQ'
        >
          <FaqDetails />
        </Tab>
      </Tabs>
    );
  };

  const SuccessView = () : JSX.Element => {
    return (
      <ContactSupportFormSuccess onClose={onClose} />
    );
  };

  const ErrorView = () : JSX.Element => {
    return (
      <ContactSupportFormError onClose={onClose} />
    );
  };

  return (
    <>
      <Modal 
        data-testid='modal-support-help-modal'
        size={(isSuccess || isError) ? 'medium' : 'large'} 
        isOpen={isOpen} 
        onClose={onClose}
      >
        <ModalBody>
          {(!isSuccess && !isError) && <TabView /> }
          {(isSuccess && !isError) && <SuccessView />}
          {isError && <ErrorView />}
        </ModalBody>
      </Modal>
      <GridRow 
        style={{ position: 'fixed', right: '0', bottom: '0', zIndex: 500, margin: '2em' }}
        justify='end'     
      >
        <GridCol base={12}>
          <PillButton 
            data-testid='support-help-modal-button'
            variant='secondary' 
            onClick={() => setIsOpen(!isOpen)}>
              Need help?
          </PillButton>
        </GridCol>

      </GridRow>

    </>
  );
};

export default SupportHelpModal;
