import { Response } from 'miragejs';

export const cancelAppraisalRouter = (claimNumber: string, incidentId: string, exposureNumber: string, cancelationReason: string) => {
  if (incidentId === '12345'){
    return new Response(200, {},
      {
        'data': {
          'cancelAppraisalAppointment': {
            'success': true
          }
        }
      });
  } else if (incidentId === '99999'){
    return new Response(200, {},
      {
        'data': {
          'cancelAppraisalAppointment': {
            'success': false
          }
        }
      });
  } else {
    return new Response(404, {},
      {
        'data': {
          'cancelAppraisalAppointment': {
            'success': false
          }
        }
      });
  }
};

export default cancelAppraisalRouter;

