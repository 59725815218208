import { ILiabilityInvestigation } from '../features/claim-search/models/claim-interface';

export const getLiabilityInvestigationStatus = (liabilityInvestigationResponse: ILiabilityInvestigation | null): string => {
  // if no data (not started) return not started
  if (liabilityInvestigationResponse === null || Object.keys(liabilityInvestigationResponse).length === 0) {
    return 'NOT_STARTED'; 
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus.toUpperCase() === 'IN PROGRESS') {
    return 'IN_PROGRESS';
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus.toUpperCase() === 'COMPLETE' && 
    liabilityInvestigationResponse.liabilityDecision.toUpperCase() === 'LIABILITY ACCEPTED' &&
    liabilityInvestigationResponse.liabilityHighPercent === 100) {
    return 'COMPLETED_ACCEPTED';
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus.toUpperCase() === 'COMPLETE' && 
    liabilityInvestigationResponse.liabilityDecision.toUpperCase() === 'LIABILITY ACCEPTED' &&
    liabilityInvestigationResponse.liabilityHighPercent !== 100) {
    return 'COMPLETED_NOT_ACCEPTED';
  }

  if (liabilityInvestigationResponse.liabilityInvestigationStatus.toUpperCase() === 'COMPLETE' && 
    liabilityInvestigationResponse.liabilityDecision.toUpperCase() !== 'LIABILITY ACCEPTED') {
    return 'COMPLETED_NOT_ACCEPTED';
  }

  // if any other case like not accepted should it be Not started
  return 'NOT_STARTED';
};
