import styles from './logout-component.module.scss';

import {IconLockClosed} from '@lmig/lmds-react/icons';
import { authService } from '../../../../common/services';
import { trackCustomHeapEvent } from '../../../../../utils/heap';

export const logoutUser = async () => {
  trackCustomHeapEvent('logout-redirect', {});
  authService.logout();
};

const LogOut = () => {

  return (
    <button id="btn-logout-user" onClick={() => logoutUser()} className={styles.logoutBtn}>
      <div className={styles.logoutLogo}>
        <IconLockClosed size={'16'} title={'Log out'}/>
      </div>
      <div className={styles.logoutText}>Log out</div>
    </button>
  );

};

export default LogOut;
