import config from '../../../config';
import asyncDelay from '../../../utils/async-delay';
import Auth0Registration from './auth0-registration';
class RegistrationStatusCheck {
  private pollingEnabled: boolean = false;
  private trackingId: string = '';
  private startTime: number = Infinity;
  private registrationStatusCallback: Function | null = null;
  private statusesServiceFailures: number = 0;
  private debugMode = false;
  constructor(trackingId: string) {
    this.trackingId = trackingId;
  }
  start(debugMode: boolean = false) {
    this.debugMode = debugMode;
    this.logger('started');
    this.pollingEnabled = true;
    this.startTime = Date.now();
    this.poller(this.trackingId).then();
  }
  stop() {
    this.pollingEnabled = false;
  }
  onRegistrationStatusChange(callback: Function) {
    // callback assignment
    this.registrationStatusCallback = callback;
  }
  private updateRegistrationStatus(status: string, terminating: boolean) {
    if (this.registrationStatusCallback != null) {
      this.registrationStatusCallback(status, terminating);
    }
  }
  private logger(...values: any) {
    if (this.debugMode) {
      console.log(...values);
    }
  }
  private processStatus(status: string) {
    this.logger('current processing status: ', status);
    const foundStatus = config.auth0RegStatuses.statuses.find((statusObj) => {
      return statusObj.status === status;
    });
    if (foundStatus !== undefined) {
      this.statusesServiceFailures = 0;
      this.updateRegistrationStatus(status, foundStatus.terminating);
      if (foundStatus.terminating) {
        // stop polling.
        this.pollingEnabled = false;
      }
    } else {
      // status not found
      this.statusesServiceFailures++;
    }
  }
  private handleServiceFailure() {
    this.logger('Service failure');
    this.pollingEnabled = false; // disable polling so while loop is not running
    // we want to inform all subscriptions about this failure.
    this.updateRegistrationStatus('service_failure', true);
  }
  private handleTimeout() {
    this.logger('timeout');
    this.pollingEnabled = false; // disable polling so while loop is not running
    // we want to inform all subscriptions about timeout failure.
    this.updateRegistrationStatus('timed_out', true);
  }
  private async poller(trackingId: string) {
    while (this.pollingEnabled) {
      // handle consecutive service failure
      if (this.statusesServiceFailures >= config.auth0RegStatuses.maxConsecutiveFailures) {
        this.handleServiceFailure();
        break;
      }
      // handle timeout
      if (this.startTime + config.auth0RegStatuses.totalTimeoutMS < Date.now()) {
        this.handleTimeout();
        break;
      }
      this.logger(`
        Puller is running
        --------------------------
        Consecutive Failures: ${this.statusesServiceFailures}
        Time elapsed[MS]: ${Date.now() - this.startTime}
        `);
      try {
        // give a delay before initiating a new polling.
        await asyncDelay(config.auth0RegStatuses.pollingDelayMS);
        const latestStatus = await new Auth0Registration().getCurrentStatus(trackingId);
        this.processStatus(latestStatus.status?.toLowerCase() || '');
      } catch (error) {
        // we will ignore the error, but we will increase service failure
        this.statusesServiceFailures++;
      }
    }
  }
}
export default RegistrationStatusCheck;
