import { Envs } from '../features/common/models/generic-enum';
import env from './env';

const browserSessionId: string = (Math.random()*1e17).toString();

export const trackCustomHeapEvent = (event: string, eventProps: any, includeSessionId: boolean = false) =>{

  if (includeSessionId){
    eventProps['browserSessionId']=browserSessionId;
  }

  // @ts-ignore
  window.heap?.track(`cip2-${event}`, eventProps);

  if (env.appEnv===Envs.Local){
    console.log('custom heap event > ', event, eventProps);
  }
};

