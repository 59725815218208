import { useEffect, useState } from 'react';
import { auth0Client } from '../utils/get-auth0-client';
import env from '../utils/env';
import { Envs } from '../features/common/models/generic-enum';

/**
 * Redirects user to universal login page where user can authenticate.
 * @returns isAuthenticated
 */
export const useAuth0Authentication = (performRedirect = false) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    (env.appEnv === Envs.Local || env.appEnv === Envs.Demo) ? true : auth0Client.isAuthenticated());

  useEffect(() => {
    if (env.appEnv === Envs.Local || env.appEnv === Envs.Demo) {
      // setIsAuthenticated(true);
      return;
    }
    (async (): Promise<void> => {
      if (!auth0Client.isAuthenticated()) {
        await auth0Client.init();
        // We need to check !auth0Client.isAuthenticated() again 
        // because init checks and set it to true if user is logged in
        if (performRedirect && !auth0Client.isAuthenticated()) {
          // if user is still not authenticated then send the user to login page
          await auth0Client.loginWithRedirect();
        }
      }
      setIsAuthenticated(auth0Client.isAuthenticated());
    
    })();
  }, [isAuthenticated, performRedirect]);

  return [isAuthenticated];
};
