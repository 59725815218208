import { Response } from 'miragejs';

export const liabilityInvestigationRouter = (claimNumber: string, exposureNumber: string) => {
  if (claimNumber === '123456789' && exposureNumber === '0000'){
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 100,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }          
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0001'){
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 100,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }          
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0002'){
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 100,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }          
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0003'){
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 100,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }          
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0004') {
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 50,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0005') {
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Not Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'not accepted',
            'liabilityHighPercent': 0,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0006') {
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'In progress',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 100,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0007') {
    return new Response(200, {}, {'data': { 'getLiabilityInvestigation': null} }); 
  } else if (claimNumber === '123456789' && exposureNumber === '0008') {
    return new Response(500, {}, {}); 
  } else if (claimNumber === '056359703' && exposureNumber === '0002'){
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': null          
        }
      });
  } else if (claimNumber === '056359703' && exposureNumber === '0003') {
    return new Response(200, {},
      {
        'data': {
          'getLiabilityInvestigation': {
            'coverage': 'Liability Property Damage',
            'liabilityDecision': 'Liability Accepted',
            'liabilityInvestigationStatus': 'Complete',
            'liabilityDescription': 'accepted',
            'liabilityHighPercent': 100,
            'liabilityLowPercent': null,
            'liabilityUpdateTime': '2022-02-11T13:42:26.628Z'
          }
        }
      });
  } else {
    return new Response(200, {}, {
      'data': {
        'getLiabilityInvestigation': null
      },
      'errors': [
        {
          'path': [
            'getLiabilityInvestigation'
          ],
          'data': {
            'result': null,
            '__typename': 'LiabilityInvestigationResponse'
          },
          'errorType': '1300',
          'errorInfo': null,
          'locations': [
            {
              'line': 2,
              'column': 3,
              'sourceName': null
            }
          ],
          'message': 'Authorization Error'
        }
      ]
    }); 
  }
};

export default liabilityInvestigationRouter;

