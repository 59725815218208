import React from 'react';
import EmptySpace from '../empty-space/empty-space-component';
import {Notification} from '@lmig/lmds-react';

type ClaimErrorProp={
    children:JSX.Element | string;
    id?:string,
}

const PageError = ({children, id='claim-info-error-notification'}:ClaimErrorProp) => {
  return (
    <React.Fragment>
      <EmptySpace height={64}/>
      <Notification type="error" highlightType='negative' id={id}>
        {children}
      </Notification>
    </React.Fragment>
  );
};

export default PageError;
