import { GridRow, GridCol, BodyText } from '@lmig/lmds-react';
import styles from './claim-status-component.module.scss';
import {claimsStatusStore as store} from '../store/claims-status-store';

const ClaimStatusSearchResultCard = ({ claimStatus }: any) => {
  return (
    <>
      <GridRow data-testid='claimStatusResultData' gutters vAlign="bottom">
        <GridCol data-testid='claimStatusResultData_claimNumber' className={styles.bold}>
          <b>Claim #{store.claim?.claimNumber}</b>
        </GridCol>
      </GridRow>
      <GridRow className={styles.borderbottomblack}>
      </GridRow>
      {store.claim?.liabilityStatus &&
      <GridRow justify="between" vAlign="top" className={styles.borderbottom}>
        <GridCol>
          <BodyText tag="h5">Liability status</BodyText>
        </GridCol>
        <GridCol data-testid='claimStatusResultData_liabilityStatus'>
          <small><span className={styles.liabilitystatustxt}>{store.claim?.liabilityStatus}</span></small>
        </GridCol>
      </GridRow>
      }
      <GridRow justify="between" className={styles.borderbottom}>
        <GridCol>
          <BodyText tag="h5">Payment status</BodyText>
        </GridCol>
        <GridCol data-testid='claimStatusResultData_paymentStatus'>
          <small>{store.claim?.paymentStatus}</small>
        </GridCol>
      </GridRow>
      <GridRow justify="between" className={styles.borderbottom}>
        <GridCol>
          <BodyText tag="h5">Rental Coverage</BodyText>
        </GridCol>
        <GridCol data-testid='claimStatusResultData_rentalCoverage'>
          <small>{store.claim?.rentalCoverage}</small>
        </GridCol>
      </GridRow>
      <GridRow justify="between" className={styles.borderbottom}>
        <GridCol>
          <BodyText tag="h5">Appraisal available</BodyText>
        </GridCol>
        <GridCol data-testid='claimStatusResultData_appraisalAvailable'>
          <small>{store.claim?.appraisalAvailable}</small>
        </GridCol>
      </GridRow>
      <GridRow justify="between" className={styles.borderbottom}>
        <GridCol>
          <BodyText tag="h5">Appraiser</BodyText>
        </GridCol>
        <GridCol data-testid='claimStatusResultData_appraiser'>
          <small>{store.claim?.appraiser}</small>
        </GridCol>
      </GridRow>
      
    </>
  );
};

export default ClaimStatusSearchResultCard;
