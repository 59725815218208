import { GridRow, GridCol, Heading, Notification } from '@lmig/lmds-react';
import { observer } from 'mobx-react';
import EmptySpace from '../common/components/empty-space/empty-space-component';
import AppointmentDetailCard from './components/appointment-detail-card-component';
import AppraisalDetailCard from './components/appraisal-detail-card-component';
import AppraisalDocumentCard from './components/appraisal-document-card-component';
import AppraisalHistory from './components/appraisal-history-component';
import { appraisalStore  } from './store/appraisal-store';

const appraisalInfoError = (message:string) => {
  return (
    <Notification 
      alert='Appraisal information is not currently available'
      highlightType="negative">
        Please check back later.
    </Notification>
  );
};

const AppraisalTab = observer(() => {
  return (
    <>
      { appraisalStore.appraisalError ? appraisalInfoError(appraisalStore.appraisalError) : 
        <>
          <>
            <Heading type="h4-bold">
            Appraisal information
            </Heading>
            <GridRow gutters justify={'between'}>
              <GridCol base={12} md={6}>
                <AppraisalDetailCard isLoading={appraisalStore.appraisalLoading} appraisalDetail={appraisalStore.appraisalDetail}/>
              </GridCol>
              <GridCol base={12} md={6}>
                <AppointmentDetailCard isLoading={appraisalStore.appraisalLoading} appointment={appraisalStore.appointmentDetail} appraisalDetail={appraisalStore.appraisalDetail}/>
              </GridCol>
            </GridRow>
          </>
      
          <EmptySpace height={20} />
          <AppraisalHistory appraisalHistory={appraisalStore.appraisalHistory}/>
          <EmptySpace height={20} />
          <>
            <Heading type="h4-bold">
            Appraisal documents
              {appraisalStore.downloadUrlError && appraisalInfoError(appraisalStore.downloadUrlError) }
            </Heading>
            <GridRow gutters>
              {appraisalStore?.appraisalDocuments && appraisalStore?.appraisalDocuments.length > 0 ? appraisalStore?.appraisalDocuments?.map((document, i) => (
                <GridCol base={12} sm={6} md={3} key={i} data-testid="appraisal-document-card">
                  <AppraisalDocumentCard  isLoading={appraisalStore?.appraisalDocumentsLoading} document={document}/>
                </GridCol>
              )): <Notification
                alert="There are no documents available."
                highlightType="negative"
              >Please try again later.</Notification>                          
              }
            </GridRow>
            <EmptySpace height={60} />
          </>
        </>
      }
    </>
  );
});

export default AppraisalTab;
