import {Response} from 'miragejs';
import IRegistrationRequest from '../../features/registration/models/registration-request-interface';
import RegistrationStatuses from '../../features/registration/models/registration-statuses-enum';
import { cache } from './status-check-router';


const createSuccessResponse = (trackingId: string): Response =>{
  // please note that for testing purposes, we should ckear trackingID cache. otherwise tester on the demo website
  // will need to refresh the page
  cache.trackingIds={};

  return new Response(200, {}, {
    trackingId,
    currentStatus: RegistrationStatuses.Received
  });
};


export default function createUserRouter(schema: any, request: any) {
  const body: IRegistrationRequest = JSON.parse(request.requestBody);

  switch (body.firstName) {
  case 'field-missing':
    return new Response(400, {}, {error: 'FIELD_REQUIRED'});
  case 'body-error':
    return new Response(400, {}, {message: 'Invalid request body'});
  case 'validation-failed':
    return createSuccessResponse(RegistrationStatuses.ValidationFailed);
  case 'identity-failed':
    return createSuccessResponse(RegistrationStatuses.IdentityCreationFailed);
  case 'duplicate-account':
    return createSuccessResponse(RegistrationStatuses.IdentityCreationFailedDuplicate);
  case 'rollback-completed':
    return createSuccessResponse(RegistrationStatuses.RollbackCompleted);
  default:
    return createSuccessResponse(RegistrationStatuses.Completed);
  }

}
