type IPhone = {
  isPrimary: boolean | null;
  phoneNumber: string;
  extension: string;
};

export const getPrimaryPhoneNumber = (phoneList: Array<IPhone>): string => {
  let primaryPhoneNumber = 'N/A';
  Array.isArray(phoneList) &&
    phoneList.forEach((phone) => {
      if (phone.isPrimary) {
        primaryPhoneNumber = phone.extension
          ? `${phone.phoneNumber} ext. ${phone.extension}`
          : phone.phoneNumber;
      }
    });

  // if there isn't a primary number use the first one
  if (primaryPhoneNumber === 'N/A' && phoneList.length) {
    primaryPhoneNumber = phoneList[0].extension
      ? `${phoneList[0].phoneNumber} ext. ${phoneList[0].extension}`
      : phoneList[0].phoneNumber;
  }
  return primaryPhoneNumber;
};

export const getSecondaryPhoneNumber = (phoneList: Array<IPhone>): string => {
  const nonPrimaryPhoneList = phoneList.filter(
    (ph) => ph.isPrimary === null || ph.isPrimary === false
  );

  if (nonPrimaryPhoneList && nonPrimaryPhoneList.length > 0) {
    if (nonPrimaryPhoneList.length < phoneList.length) {
      return nonPrimaryPhoneList[0].extension
        ? `${nonPrimaryPhoneList[0].phoneNumber} ext. ${nonPrimaryPhoneList[0].extension}`
        : nonPrimaryPhoneList[0].phoneNumber;
    }
    if (
      nonPrimaryPhoneList.length === phoneList.length &&
      nonPrimaryPhoneList.length > 1
    ) {
      return nonPrimaryPhoneList[1].extension
        ? `${nonPrimaryPhoneList[1].phoneNumber} ext. ${nonPrimaryPhoneList[1].extension}`
        : nonPrimaryPhoneList[1].phoneNumber;
    }
  }

  return 'N/A';
};
