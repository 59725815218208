
import { shopData } from '../data/shop-data';

export const shopListRouter = (schema: any, request: any) => {
  switch (request.queryParams.zipCode) {
    
  case '03104':
    return shopData.shopNotFound();
  default:
    return shopData.shopListData();
  }
};
export default shopListRouter;

