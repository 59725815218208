import { Response } from 'miragejs';

export const contactsRouter = (claimNumber: string) => {

  if (claimNumber === '675'){
    return new Response(500, {}, {});
  } else if (claimNumber === '0563'){
    return new Response(200, {},
      {
        'data': {
          'getContactsByClaimNumber': null
        }
      });
  } else return new Response(200, {},
    {
      'data': {
        'getContactsByClaimNumber': [
          {
            'companyName': 'New Hampshire Police Department',
            'roles': {
              'entry': [
                {
                  'roleName': 'official',
                  'directionToPayInd': null
                }
              ]
            }
          },
          {
            'companyName': null,
            'roles': {
              'entry': [
                {
                  'roleName': 'listedoperator',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'injured',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'passenger',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'claimant',
                  'directionToPayInd': null
                }
              ]
            }
          },
          {
            'companyName': 'Little Raski Auto Repair',
            'roles': {
              'entry': [
                {
                  'roleName': 'repairshop',
                  'directionToPayInd': false
                }
              ]
            }
          },
          {
            'companyName': null,
            'roles': {
              'entry': [
                {
                  'roleName': 'none',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'incidentowner',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'driver',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'claimant',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'renter',
                  'directionToPayInd': null
                }
              ]
            }
          },
          {
            'companyName': 'City of Portsmouth',
            'roles': {
              'entry': [
                {
                  'roleName': 'incidentowner',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'none',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'claimant',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'negcontact',
                  'directionToPayInd': null
                }
              ]
            }
          },
          {
            'companyName': 'VISTA INT\'L INS BRKRS LTD LLC',
            'roles': {
              'entry': [
                {
                  'roleName': 'agent',
                  'directionToPayInd': null
                }
              ]
            }
          },
          {
            'companyName': null,
            'roles': {
              'entry': [
                {
                  'roleName': 'insured',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'listedoperator',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'reporter',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'incidentowner',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'driver',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'claimant',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'appraisalcontact',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'claimant',
                  'directionToPayInd': null
                },
                {
                  'roleName': 'renter',
                  'directionToPayInd': null
                }
              ]
            }
          }
        ]
      }		
    });
};

export default contactsRouter;
