import { Card, GridCol, GridRow, Heading, Notification } from '@lmig/lmds-react';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@lmig/lmds-react-table';
import styles from '../../claim-search/claim-search.module.scss';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import { formatAppointmentDateTime, showCancelButton } from '../helpers/appraisal-data-helper';
import { IAppointmentDetail } from '../model/appraisal-Info-interface';
import CancelAppraisalModal from './cancel-appraisal-modal';
import { IAppraisalDetail } from '../model/appraisal-Info-interface';

interface PropsType {
  isLoading: boolean;
  appointment: IAppointmentDetail | null;
  appraisalDetail: IAppraisalDetail | null;
}

const AppointmentDetailCard = (props:PropsType): JSX.Element => (

  (props.isLoading || props.appointment === null) ? <CardShimmer></CardShimmer> :
    <DataTable>
      <Card className={styles.card} elevation="raised">
        <Heading type="h5-bold">
        Appointment details
        </Heading>
        {props.appointment ? <>
          <Table id="deductible-information-table">
            <TableHeader>
              <TableRow>{<></>}</TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <b>Date and time*</b>
                </TableCell>
                <TableCell 
                  data-testid="appointment-date-time"
                  textAlign={'right'}>{ formatAppointmentDateTime(props.appointment?.date) }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>Appointment status</b>
                </TableCell>
                <TableCell 
                  data-testid="appointment-status"
                  textAlign={'right'}>  {props.appointment.status}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {  showCancelButton(props) && 
        <GridRow gutters={true}>
          <GridCol>
            <CancelAppraisalModal {...props} />         
          </GridCol>
        </GridRow>
          }
          <Table>
            <TableRow>
              <TableCell>
                <p>
                *All times and dates are displayed and adjusted for Eastern Time (ET)
                </p>
                <p>
                You may cancel the appraisal appointment at any point before the scheduled time.
                  <br></br>
                  <b>Please note:</b> Cancelling this appointment will also cancel this assignment.
                </p>
              </TableCell>
            </TableRow>
          </Table>
        </> : 
          <Notification
            alert="There is no appraisal appointment info"
            highlightType="negative"
          >
            Please try a different claim number. 
          </Notification>
        }
      </Card>
    </DataTable>
);

export default AppointmentDetailCard;
