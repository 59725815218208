import { observable, action, runInAction, configure } from 'mobx';
import { claimsStatusService } from '../services/get-claim-status-service';
import { IClaimStatus } from '../models/claim-status-interface';

import * as schema from '../../../utils/validation-schema';

// Enable Restrict mode. Recommended
configure({ enforceActions: 'observed' });

export default class ClaimsStatusStore {
  @observable claim: IClaimStatus | null = null;
  @observable loading = false;
  @observable error: any = null;
  @observable claimNumber: string = '';
  @observable validClaimNumber: boolean | undefined = undefined;

  @action
  async fetchClaimByClaimNumber(claimNumber: string) {
    this.error = null;
    this.loading = true;
    this.claim = null;
    try {
      // get claim data
      const claimInfo: IClaimStatus =
        await claimsStatusService.getClaimStatusInfo(claimNumber);
      const claimInfoRes : IClaimStatus= { ...claimInfo };

      claimInfoRes.claimNumber = claimNumber;
      runInAction(() => {
        this.claim = claimInfo;
        this.loading = false;
      });
    } catch (error: any) {
      runInAction(() => {
        this.loading = false;
        this.error = error.message;
      });
    }
  }

  @action
  setClaimNumber(claimNumber: string) {
    this.claimNumber = claimNumber;
    this.validClaimNumber = schema.validClaimNumber(claimNumber);
  }
}

export const claimsStatusStore = new ClaimsStatusStore();
