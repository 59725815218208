import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { ErrorMessages } from '../../../utils/errorMessages';
import { trackCustomHeapEvent } from '../../../utils/heap';
import IClaimStatus from '../models/claim-status-interface';
const getClaimStatus: any = loader(
  '../../../graphql/queries/get-claim-status.graphql'
);

class ClaimStatusService {
  async getClaimStatusInfo(claimNumber: string): Promise<IClaimStatus> {
    try {
      const response = await client(false).query({
        query: getClaimStatus,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          exposureNumber: claimNumber.split('-')[1]
        },
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }
      if (!response?.data?.getClaimStatus === undefined) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      trackCustomHeapEvent('get-claim-status-success', {});
      return response?.data?.getClaimStatus;
    } catch (error) {
      trackCustomHeapEvent('get-claim-status-error', {});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  }
}

export default ClaimStatusService;
export const claimsStatusService = new ClaimStatusService();
