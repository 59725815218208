import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { ErrorMessages } from '../../../utils/errorMessages';

const getAppraisalQuery: any = loader('../../../graphql/queries/get-appraisal-details.graphql');

class AppraisalService {
  async getLatestAppraisalDetails(claimNumber:string, incidentId: string): Promise<any> {
    try {
      const response = await client(true).query({
        query: getAppraisalQuery,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          incidentId: incidentId,
          clientType: 'VENDOR_USER'
        },        
        fetchPolicy: 'no-cache'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }
      if (!response?.data?.getAppraisalDetails) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      return response?.data?.getAppraisalDetails?.result[0] || {};

    } catch (error) {
      trackCustomHeapEvent('get-appraisal-information-error', {error});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  } 
}

export default AppraisalService;

