import { GridRow, GridCol } from '@lmig/lmds-react';
import { observer } from 'mobx-react';
import EmptySpace from '../common/components/empty-space/empty-space-component';
import AdjusterContactInformation from './components/adjuster-contact-information-component';
import ContactInformation from './components/contact-information-component';
import IncidentDetails from './components/incident-detail-card-component';

import { claimSearchStore as store } from '../claim-search/store/claim-search-store';
import {Notification} from '@lmig/lmds-react';


const exposureInfoError = (message:string) => {
  return (
    <Notification 
      alert={message}
      highlightType="negative"
    />
  );
};

const AdditionalInfoTab = observer(() => {
  return (
    <>
      {store.error ? exposureInfoError(store.error) :  
        <GridRow gutters justify={'between'}>
          <GridCol base={12} md={6}>
            <IncidentDetails isLoading={store.incidentDetailsLoading} incidentDetails={store.incidentDetails} />
          </GridCol>
          <GridCol base={12} md={6}>
            <AdjusterContactInformation isLoading={store.exposureInfoLoading} exposureInfo={store.exposureInfo}  />
          </GridCol>
          <GridCol base={12} md={6}>
            <ContactInformation isLoading={store.exposureInfoLoading} exposureInfo={store.exposureInfo}  />            
          </GridCol>
        </GridRow>
      }
      <EmptySpace height={40} />
    </>
  );
});

export default AdditionalInfoTab;
