import { Card, Heading, Notification } from '@lmig/lmds-react';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@lmig/lmds-react-table';
import styles from '../../claim-search/claim-search.module.scss';
import { IExposureInfo } from '../../claim-search/models/claim-interface';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';

interface PropsType {
  isLoading: boolean;
  exposureInfo: IExposureInfo | null;
}

const AdjusterContactInformation = (props: PropsType): JSX.Element =>
  props.isLoading ? (
    <CardShimmer></CardShimmer>
  ) : (
    <DataTable>
      <Card className={styles.card} elevation="raised">
        <Heading type="h5-bold">Adjuster contact information</Heading>
        {props.exposureInfo?.owner ? (
          <>
            <Table id="additional-information-table">
              <TableHeader>
                <TableRow>{<></>}</TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell
                    data-testid="additional-information-owner-name"
                    textAlign={'right'}
                  >
                    {props.exposureInfo?.owner.name || 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell
                    data-testid="additional=information-owner-email"
                    textAlign={'right'}
                    className={styles.wordBreak}
                  >
                    {props.exposureInfo?.owner.communication.emailList?.[0] ? (
                      <a
                        href={`mailto:${props.exposureInfo?.owner.communication.emailList?.[0]}`}
                      >
                        {props.exposureInfo?.owner.communication.emailList?.[0]}
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* <GridRow gutters={true}>  FUTURE BUTTON
            <GridCol>
              <Button size="large" variant="primary">Request Contact</Button>
            </GridCol>
          </GridRow> */}
          </>
        ) : (
          <Notification
            alert="There is no owner contact info"
            highlightType="negative"
          >
            Please try a different claim number.
          </Notification>
        )}
      </Card>
    </DataTable>
  );

export default AdjusterContactInformation;
