import  { useState } from 'react';
import { BodyText, Button, Card, GridCol, GridRow, IconDocumentPDF} from '@lmig/lmds-react';
import { convertToShortDate } from '../../../utils/common';
import styles from '../../claim-search/claim-search.module.scss';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import { IAppraisalDocument } from '../model/appraisal-Info-interface';
import { appraisalStore as store } from '../store/appraisal-store';
import env from '../../../utils/env';

interface PropsType {
  document: IAppraisalDocument;
  isLoading: boolean;
}

const AppraisalDocumentCard = (props:PropsType): JSX.Element => { 
  let [loading, setLoading] = useState<boolean>(false);
  const openDocument = async (documentUrl: string) => {
    setLoading(true);
    await store.getDocumentUrl(documentUrl);
    if (store.downloadUrl) {
      window.open(`${env.documentUrl}${store.downloadUrl}`);
    }
    setLoading(false);
  };

  return (
    (props.isLoading || props.document === null || loading || store.downloadUrlLoading) ? <CardShimmer></CardShimmer> :
      <Card className={styles.card} elevation="raised" key={props.document?.documentId}>
        <GridRow gutters>
          <GridCol>
            <div className="icon-wrapper">
              <GridCol>
                <IconDocumentPDF className={styles.icon} size="48" label={props.document?.documentType.replace('Appraisal', '')}/>
              </GridCol>
            </div>
            <BodyText tag={'h1'} type={'article'}>{convertToShortDate(props.document?.createDate)}</BodyText>
          </GridCol>
        </GridRow>
        <GridRow>
          <GridCol>
            <Button onClick={e =>openDocument(props.document?.documentId)}>View Document</Button>
          </GridCol>
        </GridRow>     
      </Card>
  );
};


export default AppraisalDocumentCard;
