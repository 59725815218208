import React from 'react';
import { GridCol, Show } from '@lmig/lmds-react';
import styles from './divider.module.scss';

type DividerProps = {
  md?: any;
  sm?: any
};

const Divider = ({ md, sm }: DividerProps) => (
  <Show thresholds={['md', 'lg', 'xl']}>
    <GridCol md={ md } sm={ sm } className={styles.dividerCenter}>
      <span className={styles.verticalLine}></span>
    </GridCol>
  </Show>
);

export default Divider;
