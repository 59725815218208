import { GridRow, GridCol, Heading, Notification } from '@lmig/lmds-react';

import {
  DataTable,
  TableHeader,
  TableBody,
  Table,
  TableCell,
  TableRow
} from '@lmig/lmds-react-table';
import { convertToShortDate, formatCurrency } from '../../../utils/common';
import tableSkeleton from '../../common/components/table/table-skeleton-component';
import { IPaymentInfo } from '../models/payment-info-interface';

interface PropsType {
  paymentInformation: IPaymentInfo[];
  isLoading?: boolean;
  paymentsError: string;
}

const getDisplayStatus = (status: string) => {
  let returnStatus = 'N/A';
  if (status.toLowerCase() === 'issued') {
    returnStatus = 'Issued';
  } else if (status.toLowerCase() === 'requested') {
    returnStatus = 'Requested';
  } else if (status.toLowerCase() === 'cleared') {
    returnStatus = 'Cleared';
  } else if (status.toLowerCase() === 'voided') {
    returnStatus = 'Voided';
  }
  return returnStatus;
};

const getDisplayPaymentMethod = (
  paymentMethod: string,
  checkNumber: string
) => {
  let returnPaymentMethod = 'N/A';
  if (paymentMethod === 'manual') {
    returnPaymentMethod = 'Pay your way';
  } else if (paymentMethod === 'check') {
    returnPaymentMethod = checkNumber ? 'Check #' + checkNumber : 'N/A';
  }
  return returnPaymentMethod;
};

const getDisplayPayees = (payees: string[]) => {
  let returnPayees = 'N/A';
  if (payees.length > 0) {
    return payees.join(', ');
  }
  return returnPayees;
};

const getAmount = (amount: string) => {
  let returnAmount = '0';
  if (amount !== null && amount.length !== 0) {
    returnAmount = amount;
  }
  return formatCurrency(returnAmount);
}; 

const PaymentTable = (props: PropsType): JSX.Element => {
  const renderPaymentInfoBody = () => (
    !props.paymentInformation || props.paymentInformation.length === 0 ? (
      !props.paymentsError ? (

        <Notification
          alert="No payment information available"
          highlightType="neutral"
        >
        </Notification>
      ) : (
        <Notification
          alert="There is an error loading payments"
          highlightType="negative"
        >
          {props.paymentsError}
        </Notification>
      )
    ) : 
      <DataTable>
        <Table
          id="payment-for-repairs-table"
          rowStyle={'zebra'}
          data-testid="payment-table-body"
        >
          <TableHeader>
            <TableRow>
              <TableCell key={0} type="colHead">
            Date issued
              </TableCell>
              <TableCell key={1} type="colHead">
            Payment method
              </TableCell>
              <TableCell key={2} type="colHead">
            Status
              </TableCell>
              <TableCell key={3} type="colHead">
            Amount
              </TableCell>
              <TableCell key={4} type="colHead">
            Payees
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody data-testid="payment-table-body"> {
            props.paymentInformation.map(
              (info: IPaymentInfo, i): JSX.Element => (
         
                <TableRow key={i}>
                  <TableCell key={0} data-testid='payment-date-issued'>
                    {info.dateIssued ? convertToShortDate(info.dateIssued) : 'N/A'}
                  </TableCell>
                  <TableCell key={1} data-testid='payment-method'>
                    {getDisplayPaymentMethod(info.paymentMethod, info.checkNumber)}
                  </TableCell>
                  <TableCell key={2} data-testid='payment-status'>{getDisplayStatus(info.paymentStatus)}</TableCell>
                  <TableCell key={3} data-testid='payment-amount'>{getAmount(info.netAmount)}</TableCell>
                  <TableCell key={4} data-testid='payment-payee'>{getDisplayPayees(info.payees)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DataTable>
  

  );
  

  return (
    <>
      <Heading type="h4-bold">
        Payment(s) for repairs and associated appraisals
      </Heading>
      <GridRow gutters justify={'between'}>
        <GridCol base={12}>        
          {props.isLoading
            ? tableSkeleton(5)
            : renderPaymentInfoBody()}
        </GridCol>
      </GridRow> 
    </>
  );
};

export default PaymentTable;
