import React, { Fragment } from 'react';
import styles from './app-layout.module.scss';
import {observer} from 'mobx-react';

type AppLayoutProps = {
  header: JSX.Element,
  menu?: JSX.Element,
  content: JSX.Element,
  footer: JSX.Element,
  banners?: JSX.Element;
}

const AppLayout = observer(({banners, header, menu, content, footer}: AppLayoutProps) => {

  return (
    <Fragment>
      {/* this is the entire application */}
      <div className={styles.rootContainer}>
        
        {/* Displays banner for IE11 */ }
        { banners}

        {/* TOP PART: The header contains LMDS header */}
        <div>{header}</div>

        {/* Bottom Part: Menu, content and footer */}
        
        <div className={styles.mainContainer}>

          {/* side Menu wrapper goes here */}
            
          {/* Body - content and footer */}
          <div className={styles.bodyContainer}>

            <div id="cip-app-main" className={`${styles.contentContainer} ${styles.itemContainer}`}>
              <div className={`${styles.itemWrapper} ${styles.contentPadding}`}>{content}</div>
            </div>

            <div className={`${styles.footerContainer} ${styles.itemContainer}`}>
              <div className={`${styles.itemWrapper} ${styles.contentPadding}`}>
                {footer}
              </div>
            </div>

          </div>
        </div>
      </div>
    </Fragment>
  );
});


export default AppLayout;
