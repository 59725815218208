import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { IContactsInfo} from '../models/claim-interface';
import { ErrorMessages } from '../../../utils/errorMessages';
const getContactsByClaimNumber: any = loader('../../../graphql/queries/get-contacts.graphql');

class GetContactsService {

  async getContactsInfo(claimNumber:string): Promise<IContactsInfo> {
    try {
      const response = await client(true).query({
        query: getContactsByClaimNumber,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          clientType: 'VENDOR_USER'
        },
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }

      if (!response?.data?.getContactsByClaimNumber) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      trackCustomHeapEvent('get-contacts-client-service-success', {});
      return response?.data?.getContactsByClaimNumber;
    } catch (error) {
      trackCustomHeapEvent('get-contacts-client-service-error', {});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  }
}

export default GetContactsService;
