import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { IExposureInfo} from '../models/claim-interface';
import { ErrorMessages } from '../../../utils/errorMessages';
const findExposures: any = loader('../../../graphql/queries/get-exposures.graphql');

class GetExposureService {

  async getDeductibleBillingInfo(claimNumber:string): Promise<IExposureInfo> {
    try {
      const response = await client(true).query({
        query: findExposures,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          exposureNumbers: [claimNumber.split('-')[1]],
          clientType: 'VENDOR_USER'
        },
        fetchPolicy: 'no-cache'
      });

      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }

      if (!response?.data?.findExposures) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      return response?.data?.findExposures[0];
    } catch (error: any) {
      trackCustomHeapEvent('get-exposure-service-error', {});
      if (error.message === 'Authorization Error') {
        throw new Error(ErrorMessages.NOT_AUTHORIZED);
      } else if (error.message === ErrorMessages.CLAIM_NOT_FOUND) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      } else {
        throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
      }
    }
  }

}

export default GetExposureService;
