import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { IShop } from '../models/shop-interface';
import { trackCustomHeapEvent } from '../../../utils/heap';

const getProvidersQuery: any = loader('../../../graphql/queries/get-providers.graphql');

const ERROR_MSG = 'We are sorry, registration is temporarily unavailable. Please try again later.';

class ProviderSearchService {
  async getProviders(phoneNumber: string, zipCode: any): Promise<IShop[]> {
    try {
      const response = await client(false).query({
        query: getProvidersQuery,
        variables: {
          providerSearchRequest: {
            phoneNumber: phoneNumber,
            postalCode: zipCode
          }
        },
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }

      if (!response?.data?.getProviders) {
        throw new Error(ERROR_MSG);
      }
      trackCustomHeapEvent('get-providers-success', {});
      return response?.data?.getProviders?.results || [];
    } catch (error) {
      trackCustomHeapEvent('get-providers-error', {});
      throw error;
    }
  }
}

export default ProviderSearchService;

export const providerSearchService = new ProviderSearchService();
