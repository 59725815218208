

export interface IEmptySpaceProp {
    debug?: boolean,
    height?: number
}

const EmptySpace = ({debug = false, height = 0}: IEmptySpaceProp): JSX.Element => {

  const styles = {
    spaceHeight: {
      fontSize: 48,
      height: `${height}px`,
      backgroundColor: debug === true ? 'lightslategrey' : 'default',
      padding: 0,
      margin: 0,
      width: '100px'
    }
  };

  return (
    <div style={styles.spaceHeight}/>
  );
};

export default EmptySpace;


