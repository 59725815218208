import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache
} from '@apollo/client';
import env from '../../../utils/env';
import { setContext } from '@apollo/client/link/context';
import { authService } from '../../common/services';

const httpLink = createHttpLink({
  uri: env.graphqlUri
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': env.apiKey
    }
  };
});

const authLinkWithAuthorization = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': env.apiKey,
      authorization: authService.getJWT() || 'invalid'
    }
  };
});

const client = (isAuthorziationRequired: boolean) => {
  if (isAuthorziationRequired) {
    return new ApolloClient({
      link: from([authLinkWithAuthorization, httpLink]),
      cache: new InMemoryCache({ addTypename: false })
    });
  }
  return new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache({ addTypename: false })
  });

};

export { client };
