let IncidentCategoryLookup = new Map<string, string>();
let IncidentCauseLookup = new Map<string, string>();

// Incident Categories
IncidentCategoryLookup.set('hailProperty', 'Hail');
IncidentCategoryLookup.set('theftProperty', 'Theft');
IncidentCategoryLookup.set('waterProperty', 'Water');
IncidentCategoryLookup.set('fireProperty', 'Fire');
IncidentCategoryLookup.set('windProperty', 'Wind');
IncidentCategoryLookup.set('otherProperty', 'Other');
IncidentCategoryLookup.set('othedProperty', 'Other');
IncidentCategoryLookup.set('homeownerLiability', 'Home Owner Liability');
IncidentCategoryLookup.set('multiVehicle', 'Multi-Vehicle Accident');
IncidentCategoryLookup.set('singleVehicle', 'Single-Vehicle Accident');
IncidentCategoryLookup.set('otherAuto', 'Other Damage');
IncidentCategoryLookup.set('theftAuto', 'Theft');
IncidentCategoryLookup.set('glassOnly', 'Glass Only');
IncidentCategoryLookup.set('towOnly', 'Tow Only');
IncidentCategoryLookup.set('personalLiability', 'Personal Liability');
IncidentCategoryLookup.set('watercraftDamage', 'Watercraft Damage');
IncidentCategoryLookup.set('watercraftLiability', 'Watercraft Liability');
IncidentCategoryLookup.set('towOnlyWatercraft', 'Tow Only');

// Incidnet Causes
IncidentCauseLookup.set('hail', 'Hail');
IncidentCauseLookup.set('hailRoof', 'Hail - Roof Only');
IncidentCauseLookup.set('boatTheft', 'Boat Theft');
IncidentCauseLookup.set('offPremisesBurglary', 'Off Premises - Burglary');
IncidentCauseLookup.set('offPremisesRobbery', 'Off Premises - Robbery');
IncidentCauseLookup.set('onPremisesBurglary', 'On Premises - Burglary');
IncidentCauseLookup.set('onPremisesRobbery', 'On Premises - Theft');
IncidentCauseLookup.set('theftFromAutoOrBoat', 'Theft from Auto or Boat');
IncidentCauseLookup.set('buildingMaterialsTheft', 'Theft of Building Materials');
IncidentCauseLookup.set('plumbingHvac', 'Plumbing/HVAC - Broken Pipe/Hose/Fixture');
IncidentCauseLookup.set('backupSewerOrDrain', 'Back-up Through Sewer/Drain');
IncidentCauseLookup.set('clogOrWearTear', 'Clog, Wear & Tear, Maintenance');
IncidentCauseLookup.set('constantOrRepeatedLeak', 'Constant/Repeated Seepage/Leak');
IncidentCauseLookup.set('naturalWaterBody', 'Flooding - Overflow of Natural Body of Water');
IncidentCauseLookup.set('freezing', 'Freezing');
IncidentCauseLookup.set('groundWater', 'Ground Water');
IncidentCauseLookup.set('iceDam', 'Ice Dam');
IncidentCauseLookup.set('sumpPump', 'Sump Pump Failure');
IncidentCauseLookup.set('surfaceWater', 'Surface Water');
IncidentCauseLookup.set('other', 'Water - Other');
IncidentCauseLookup.set('unknown', 'Water - Unknown');
IncidentCauseLookup.set('other', 'Fire - Other');
IncidentCauseLookup.set('unknown', 'Fire - Unknown');
IncidentCauseLookup.set('candle', 'Candle');
IncidentCauseLookup.set('cooking', 'Cooking');
IncidentCauseLookup.set('electrical', 'Electrical');
IncidentCauseLookup.set('fireplaceOrChimney', 'Fireplace/Chimney');
IncidentCauseLookup.set('furnace', 'Furnance');
IncidentCauseLookup.set('smoking', 'Smoking');
IncidentCauseLookup.set('wildfires', 'Wildfires');
IncidentCauseLookup.set('hurricaneTropicalStorm', 'Hurricance/Tropical Storm');
IncidentCauseLookup.set('offPremisesPowerOutage', 'Wind - Off Premises Power Outage');
IncidentCauseLookup.set('tornado', 'Tornado');
IncidentCauseLookup.set('windOnly', 'Wind');
IncidentCauseLookup.set('windRain', 'Wind Driven Rain');
IncidentCauseLookup.set('windTrees', 'Wind Felled Trees');
IncidentCauseLookup.set('asbestosAbatement', 'Asbestos Abatement/Removal');
IncidentCauseLookup.set('boatMisc', 'Boat Misc');
IncidentCauseLookup.set('boatDamageCollision', 'Boat Damage Due To Collision');
IncidentCauseLookup.set('collapse', 'Collapse');
IncidentCauseLookup.set('puffback', 'Puff-Back');
IncidentCauseLookup.set('electricity', 'Electricity');
IncidentCauseLookup.set('damageFromAircraft', 'Damage From Aircraft');
IncidentCauseLookup.set('damageFromExplosion', 'Explosion');
IncidentCauseLookup.set('damageFromVehicles', 'Damage From Motor Vehicles');
IncidentCauseLookup.set('sonicBoom', 'Sonic Boom');
IncidentCauseLookup.set('earthquake', 'Earthquake');
IncidentCauseLookup.set('equipmentBreak', 'Equipment Breakdown');
IncidentCauseLookup.set('foodSpoilage', 'Food Spoilage');
IncidentCauseLookup.set('foodSpoilageInternalPower', 'Food Spoilage - Internal Power Failure');
IncidentCauseLookup.set('foodSpoilageMechanical', 'Food Spoilage - Mechanical Breakdown');
IncidentCauseLookup.set('forgeryOther', 'Forgery - Other');
IncidentCauseLookup.set('forgeryCurrency', 'Forgery - Check/EFT/Counterfeit/Credit Card');
IncidentCauseLookup.set('fraud', 'Fraud');
IncidentCauseLookup.set('identityTheft', 'Identity Theft');
IncidentCauseLookup.set('kidnap', 'Kidnap');
IncidentCauseLookup.set('landslide', 'Land Movement');
IncidentCauseLookup.set('leadPaint', 'Lead Paint Abatement/Removal');
IncidentCauseLookup.set('lightning', 'Lightning');
IncidentCauseLookup.set('miscOther', 'Other');
IncidentCauseLookup.set('moldOrFungus', 'Mold/Fungus');
IncidentCauseLookup.set('disappearanceAutoOrBoat', 'Disappearance from Auto or Boat');
IncidentCauseLookup.set('disappearanceOutsidePrem', 'Disappearance from Outside Premises');
IncidentCauseLookup.set('offPremisesPowerOutage', 'Other - Off Premises Power Outage');
IncidentCauseLookup.set('fuelTankLeakUnderground', 'Fuel Tank Leakage Underground');
IncidentCauseLookup.set('fuelTankLeakOther', 'Fuel Tank Leakage Other');
IncidentCauseLookup.set('pollution', 'Pollution');
IncidentCauseLookup.set('riotOrCivilCommotion', 'Riot/Civil Commotion (inc. Strike)');
IncidentCauseLookup.set('serviceLine', 'Service Line');
IncidentCauseLookup.set('sinkhole', 'Sinkhole');
IncidentCauseLookup.set('perilsOfSea', 'Sinking - Perils of the Sea');
IncidentCauseLookup.set('texasFoundation', 'Texas Foundation');
IncidentCauseLookup.set('treeRoots', 'Tree Roots');
IncidentCauseLookup.set('vandalismOrMischief', 'Vandalism/Malicious Mischief');
IncidentCauseLookup.set('volcanicEruption', 'Volcanic Eruption');
IncidentCauseLookup.set('weightOfIceAndSnow', 'Weight of Ice and Snow');
IncidentCauseLookup.set('cyberProtection', 'Cyber Protection');
IncidentCauseLookup.set('cyberProtectionIdentityTheft', 'Cyber Protection and Identity Theft');
IncidentCauseLookup.set('abatementRemoval', 'Abatement-Asbestos Removal');
IncidentCauseLookup.set('actsOfMinors', 'Acts Of Minors');
IncidentCauseLookup.set('animal', 'Animal');
IncidentCauseLookup.set('asbestos', 'Asbestos');
IncidentCauseLookup.set('assaultRape', 'Assault/Rape');
IncidentCauseLookup.set('blasting', 'Blasting');
IncidentCauseLookup.set('breakageRupture', 'Breakage/Rupture');
IncidentCauseLookup.set('caughtInUnderOrBetween', 'Caught In/Under/Between');
IncidentCauseLookup.set('buildingStructureCollapse', 'Collapse-Building/Structure');
IncidentCauseLookup.set('collisionOrUpset', 'Collision/Upset');
IncidentCauseLookup.set('consumeIngestOrInhale', 'Consume/Ingest/Inhale');
IncidentCauseLookup.set('damagedBorrowedPersonalProperty', 'Damaged Borrowed Personal Property');
IncidentCauseLookup.set('daycare', 'Daycare');
IncidentCauseLookup.set('des', 'DES');
IncidentCauseLookup.set('electricalContact', 'Electrical Contact');
IncidentCauseLookup.set('escalatorOrElevator', 'Escalator/Elevator');
IncidentCauseLookup.set('excessEmployersLiability', 'Excess Employers\' Liability');
IncidentCauseLookup.set('explosion', 'Explosion');
IncidentCauseLookup.set('exposureContact', 'Exposure/Contact');
IncidentCauseLookup.set('fallingOrFlyingObject', 'Falling/Flying Object');
IncidentCauseLookup.set('falseArrest', 'False Arrest');
IncidentCauseLookup.set('fireLiability', 'Fire');
IncidentCauseLookup.set('flammableFabrics', 'Flammable Fabrics');
IncidentCauseLookup.set('formaldehyde', 'Formaldehyde');
IncidentCauseLookup.set('leadPaintIngestInhaleOrAbatement', 'Lead Paint-Ingest/Inhale/Abatement');
IncidentCauseLookup.set('legalLiabilityDefense', 'Legal Liability Defense');
IncidentCauseLookup.set('libelOrSlander', 'Libel/Slander');
IncidentCauseLookup.set('malfunction', 'Malfunction');
IncidentCauseLookup.set('miscellaneousToxicTort', 'Miscellaneous Toxic Tort');
IncidentCauseLookup.set('mobileEquipment', 'Mobile Equipment');
IncidentCauseLookup.set('moldOrFungus', 'Mold/Fungus');
IncidentCauseLookup.set('nonOwnedVehicles', 'Non-Owned Vehicles');
IncidentCauseLookup.set('operationsOnOrAboveGround', 'Operations-On Or Above Ground Damage');
IncidentCauseLookup.set('operationsUnderground', 'Operations-Underground Damage');
IncidentCauseLookup.set('pcb', 'PCB');
IncidentCauseLookup.set('pileDriving', 'Pile Driving');
IncidentCauseLookup.set('pollutionDirect', 'Pollution-Direct');
IncidentCauseLookup.set('pollutionDumpSite', 'Pollution-Dump Site');
IncidentCauseLookup.set('sharpObject', 'Sharp Object');
IncidentCauseLookup.set('shootingHunting', 'Shooting/Hunting');
IncidentCauseLookup.set('silicosis', 'Silicosis');
IncidentCauseLookup.set('slipFallNotOnIceOrSnow', 'Slip/Fall Not On Ice Or Snow');
IncidentCauseLookup.set('slipFallOnIceOrSnow', 'Slip/Fall On Ice Or Snow');
IncidentCauseLookup.set('snowmobile', 'Snowmobile');
IncidentCauseLookup.set('sprayedPaintOn', 'Sprayed Paint On');
IncidentCauseLookup.set('steppedOnOrInto', 'Stepped On/Into');
IncidentCauseLookup.set('struckByOrAgainst', 'Struck By/Against');
IncidentCauseLookup.set('swimmingPoolArea', 'Swimming Pool/Area');
IncidentCauseLookup.set('unmannedAircraft', 'Unmanned Aircraft');
IncidentCauseLookup.set('waterDamage', 'Water Damage');
IncidentCauseLookup.set('watercraftOther', 'Watercraft Liability');
IncidentCauseLookup.set('weldosis', 'Weldosis');
IncidentCauseLookup.set('wreckingOrDemolition', 'Wrecking/Demolition');
IncidentCauseLookup.set('yacht', 'Yacht');
IncidentCauseLookup.set('other', 'Other');
IncidentCauseLookup.set('claimantBacking', 'Another Vehicle Backed into Insured');
IncidentCauseLookup.set('insuredParked', 'Another Vehicle Hit Parked Insured');
IncidentCauseLookup.set('insuredHitInRear', 'Another Vehicle Rear-Ended Insured');
IncidentCauseLookup.set('headOnCollision', 'Head-On Collision');
IncidentCauseLookup.set('insuredBacking', 'Insured Hit Another Vehicle while Backing');
IncidentCauseLookup.set('claimantParked', 'Insured Hit Parked Car');
IncidentCauseLookup.set('insuredFromParkedPosition', 'Insured Pulled From Parked Position');
IncidentCauseLookup.set('claimantHitInRear', 'Insured Rear-Ended Another Vehicle');
IncidentCauseLookup.set('intersectionCrossing', 'Intersection Accident - Insured Crossing');
IncidentCauseLookup.set('intersectionLeftTurn', 'Intersection Accident - Insured Turning Left');
IncidentCauseLookup.set('intersectionRightTurn', 'Intersection Accident - Insured Turning Right');
IncidentCauseLookup.set('sideswipe', 'Sideswipe');
IncidentCauseLookup.set('other', 'Other - Collision');
IncidentCauseLookup.set('hitAnimal', 'Hit Animal');
IncidentCauseLookup.set('insuredHitPedOrCyclist', 'Insured Hit Pedestrian/Cyclist/Other');
IncidentCauseLookup.set('hitStationaryObject', 'Insured Hit Stationary Object (Excl. Parked Car)');
IncidentCauseLookup.set('claimantHitPedOrCyclist', 'Insured Hit While Walking/Cycling/Other');
IncidentCauseLookup.set('rollover', 'Insured Vehicle Rollover');
IncidentCauseLookup.set('fireAuto', 'Fire');
IncidentCauseLookup.set('earthquake', 'Earthquake');
IncidentCauseLookup.set('fellOnOffVehicle', 'Fell On/Off Vehicle');
IncidentCauseLookup.set('flood', 'Flood And Rising Water');
IncidentCauseLookup.set('hail', 'Hail');
IncidentCauseLookup.set('hurricaneTropicalStorm', 'Hurricane/Tropical Storm');
IncidentCauseLookup.set('identityTheft', 'Identity Theft');
IncidentCauseLookup.set('fallingObject', 'Struck By Falling Object');
IncidentCauseLookup.set('struckByOrAgainst', 'Struck By/Against Object');
IncidentCauseLookup.set('theftPersonalEffects', 'Theft Of Personal Effects');
IncidentCauseLookup.set('tornado', 'Tornado');
IncidentCauseLookup.set('vandalism', 'Vandalism');
IncidentCauseLookup.set('waterDamage', 'Water Damage');
IncidentCauseLookup.set('wind', 'Wind');
IncidentCauseLookup.set('other', 'Other - Comprehensive');
IncidentCauseLookup.set('partialTheft', 'Partial Theft');
IncidentCauseLookup.set('totalTheft', 'Total Theft');
IncidentCauseLookup.set('glassOnly', 'Glass Only');
IncidentCauseLookup.set('towOnly', 'Tow Only');
IncidentCauseLookup.set('actsOfMinors', 'Acts Of Minors');
IncidentCauseLookup.set('animal', 'Animal');
IncidentCauseLookup.set('assaultRape', 'Assault/Rape');
IncidentCauseLookup.set('breakageRupture', 'Breakage/Rupture');
IncidentCauseLookup.set('caughtInUnderOrBetween', 'Caught In/Under/Between');
IncidentCauseLookup.set('buildingStructureCollapse', 'Collapse-Building/Structure');
IncidentCauseLookup.set('collisionOrUpset', 'Collision/Upset');
IncidentCauseLookup.set('consumeIngestOrInhale', 'Consume/Ingest/Inhale');
IncidentCauseLookup.set('damagedBorrowedPersonalProperty', 'Damaged Borrowed Personal Property');
IncidentCauseLookup.set('electricalContact', 'Electrical Contact');
IncidentCauseLookup.set('excessEmployersLiability', 'Excess Employers\' Liability');
IncidentCauseLookup.set('exposureContact', 'Exposure/Contact');
IncidentCauseLookup.set('fallingOrFlyingObject', 'Falling/Flying Object');
IncidentCauseLookup.set('falseArrest', 'False Arrest');
IncidentCauseLookup.set('fire', 'Fire');
IncidentCauseLookup.set('inboardOutboardMotorBoat', 'Inboard/Outboard Motor Boat');
IncidentCauseLookup.set('leadPaintIngestInhaleOrAbatement', 'Lead Paint-Ingest/Inhale/Abatement');
IncidentCauseLookup.set('legalLiabilityDefense', 'Legal Liability Defense');
IncidentCauseLookup.set('libelOrSlander', 'Libel/Slander');
IncidentCauseLookup.set('nonOwnedVehicles', 'Non-Owned Vehicles');
IncidentCauseLookup.set('sharpObject', 'Sharp Object');
IncidentCauseLookup.set('shootingHunting', 'Shooting/Hunting');
IncidentCauseLookup.set('slipFallNotOnIceOrSnow', 'Slip/Fall Not On Ice Or Snow');
IncidentCauseLookup.set('slipFallOnIceOrSnow', 'Slip/Fall On Ice Or Snow');
IncidentCauseLookup.set('snowmobile', 'Snowmobile');
IncidentCauseLookup.set('steppedOnOrInto', 'Stepped On/Into');
IncidentCauseLookup.set('struckByOrAgainst', 'Struck By/Against');
IncidentCauseLookup.set('swimmingPoolArea', 'Swimming Pool/Area');
IncidentCauseLookup.set('unmannedAircraft', 'Unmanned Aircraft');
IncidentCauseLookup.set('waterDamage', 'Water Damage');
IncidentCauseLookup.set('watercraftLiability', 'Watercraft Liability');
IncidentCauseLookup.set('yacht', 'Yacht');
IncidentCauseLookup.set('other', 'Other');
IncidentCauseLookup.set('boatDamageCollision', 'Boat Damage Due To Collision');
IncidentCauseLookup.set('collisionSubmerged', 'Collision With Submerged Object');
IncidentCauseLookup.set('damageFromVehicles', 'Damage From Motor Vehicles');
IncidentCauseLookup.set('earthquake', 'Earthquake');
IncidentCauseLookup.set('damageFromExplosion', 'Explosion');
IncidentCauseLookup.set('fireWatercraft', 'Fire');
IncidentCauseLookup.set('floodWatercraft', 'Flood And Rising Water');
IncidentCauseLookup.set('freezing', 'Freezing');
IncidentCauseLookup.set('hail', 'Hail');
IncidentCauseLookup.set('hitAnimalWatercraft', 'Hit Animal');
IncidentCauseLookup.set('hurricaneTropicalStorm', 'Hurricane/Tropical Storm');
IncidentCauseLookup.set('hitStationaryObject', 'Insured Hit Stationary Object (Excl. Parked Car)');
IncidentCauseLookup.set('lightning', 'Lightning');
IncidentCauseLookup.set('marineLifeDamage', 'Marine Life Damage');
IncidentCauseLookup.set('mechancialBreakdown', 'Mechanical/Electrical Breakdown');
IncidentCauseLookup.set('moldOrFungus', 'Mold/Fungus');
IncidentCauseLookup.set('partialTheftWatercraft', 'Partial Theft');
IncidentCauseLookup.set('perilsOfSea', 'Sinking - Perils of the Sea');
IncidentCauseLookup.set('fallObjectWatercraft', 'Struck By Falling Object');
IncidentCauseLookup.set('theftOfContents', 'Theft of Contents');
IncidentCauseLookup.set('tornado', 'Tornado');
IncidentCauseLookup.set('totalTheftWatercraft', 'Total Theft');
IncidentCauseLookup.set('vandalismOrMischief', 'Vandalism/Malicious Mischief');
IncidentCauseLookup.set('windOnly', 'Wind');
IncidentCauseLookup.set('windRain', 'Wind Driven Rain');
IncidentCauseLookup.set('windTrees', 'Wind Felled Trees');
IncidentCauseLookup.set('unknownBoatDamage', 'Unknown');
IncidentCauseLookup.set('otherBoatDamage', 'Other');
IncidentCauseLookup.set('collisionOrUpset', 'Collison/Upset');
IncidentCauseLookup.set('explosion', 'Explosion');
IncidentCauseLookup.set('fireWatercraft', 'Fire');
IncidentCauseLookup.set('fuelSpill', 'Fuel Spill');
IncidentCauseLookup.set('malfunction', 'Malfunction');
IncidentCauseLookup.set('sharpObject', 'Sharp Object');
IncidentCauseLookup.set('slipFallNotOnIceOrSnow', 'Slip/Fall Not On Ice Or Snow');
IncidentCauseLookup.set('steppedOnOrInto', 'Stepped On/Into');
IncidentCauseLookup.set('struckByOrAgainst', 'Struck By/Against');
IncidentCauseLookup.set('otherBoatDamage', 'Other');
IncidentCauseLookup.set('towOnLand', 'On Land');
IncidentCauseLookup.set('towOnWater', 'On Water');

export { IncidentCategoryLookup, IncidentCauseLookup };
