import { Envs } from '../features/common/models/generic-enum';

const HOST_NAME: string = window.location.hostname;
const API_KEY: string = 'da2-nv7uzq7mfnht5esqpbxzcjud3e';

interface IAuth0Config {
  domain: string,
  clientId: string,
  redirectUri: string,
  refreshDurationOffset: number,
  enableAutomaticTokenRefresh: boolean,
  inactivityTimeoutDuration: number,
  enableIdleTimeout: boolean,
  debug?: boolean,
  returnTo?: string
}
interface IEnv {
  pageName: string;
  appEnv: number;
  supplementURL:string;
  claimStatusApiUrl: string;
  shopListApiUrl:string;
  statusCheckUrl: string;
  registrationRequestApiEndpoint: string;
  graphqlUri: string;
  apiKey: string;
  auth0Config: IAuth0Config;
  galileoUrl: string;
  tealiumUrl: string;
  documentUrl: string;
}

const localEnv: IEnv = {
  pageName: 'ClaimInformationPortal',
  appEnv: Envs.Local,
  supplementURL: 'https://shop-supplement-intake-app-staging.us-east-1.np.paas.lmig.com',
  claimStatusApiUrl: '/mock-api/cipreg/api',
  shopListApiUrl: '/mock-api/cipreg/api',
  statusCheckUrl: '/mock-api/registrationStatus',
  registrationRequestApiEndpoint: '/mock-api/registrationRequest',
  graphqlUri: '/mock-api/graphql',
  apiKey: API_KEY,
  auth0Config: {
    domain: 'lmgrmdigdirdev.auth0.com',
    clientId: 'sqFPpbph5XgS0j0Pv13i7NNq6LiUM5j0',
    redirectUri: 'http://localhost:3000',
    refreshDurationOffset: 180,
    enableAutomaticTokenRefresh: true,
    inactivityTimeoutDuration: 20*60,
    enableIdleTimeout: true,
    debug: true,
    returnTo: 'https://www.libertymutual.com/logged-out'
  },
  galileoUrl: 'https://dev.galileo.libertymutual.com/v1/apievent',
  tealiumUrl: 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js',
  documentUrl: ''
};

const devEnv: IEnv = {
  pageName: 'ClaimInformationPortal',
  appEnv: Envs.Dev,
  supplementURL: 'https://shop-supplement-intake-app-staging.us-east-1.np.paas.lmig.com',
  claimStatusApiUrl: '/cipreg/api',
  shopListApiUrl: '/cipreg/api',
  statusCheckUrl: 'https://account.dev-eservice.libertymutual.com/account/registration',
  registrationRequestApiEndpoint: 'https://account.dev-eservice.libertymutual.com/account',
  graphqlUri: '/mock-api/graphql',
  apiKey: API_KEY,
  auth0Config: {
    domain: 'lmgrmdigdirdev.auth0.com',
    clientId: 'sqFPpbph5XgS0j0Pv13i7NNq6LiUM5j0',
    redirectUri: 'https://dev-cipautobody.libertymutual.com/callback',
    refreshDurationOffset: 180,
    enableAutomaticTokenRefresh: true,
    inactivityTimeoutDuration: 20*60,
    enableIdleTimeout: true,
    debug: true,
    returnTo: 'https://dev-cipautobody.libertymutual.com'
  },
  galileoUrl: 'https://dev.galileo.libertymutual.com/v1/apievent',
  tealiumUrl: 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js',
  documentUrl: ''
};

const demoEnv: IEnv = {
  pageName: 'ClaimInformationPortal',
  appEnv: Envs.Demo,
  supplementURL: 'https://shop-supplement-intake-app-staging.us-east-1.np.paas.lmig.com',
  claimStatusApiUrl: '/cipreg/api',
  shopListApiUrl: '/cipreg/api',
  statusCheckUrl: 'https://account.np-eservice.libertymutual.com/account/registration',
  registrationRequestApiEndpoint: 'https://account.np-eservice.libertymutual.com/account',
  graphqlUri: '/mock-api/graphql',
  apiKey: API_KEY,
  auth0Config: {
    domain: 'lmgrmdigdirdev.auth0.com',
    clientId: 'uljAPUEM1Fuj4hmoksZA6SUr7Hj2y63E',
    redirectUri: 'https://demo-cipautobody.libertymutual.com/callback',
    refreshDurationOffset: 180,
    enableAutomaticTokenRefresh: true,
    inactivityTimeoutDuration: 20*60,
    enableIdleTimeout: true,
    debug: true,
    returnTo: 'https://demo-cipautobody.libertymutual.com'
  },
  galileoUrl: 'https://dev.galileo.libertymutual.com/v1/apievent',
  tealiumUrl: 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js',
  documentUrl: ''
};

const eteLMEnv: IEnv = {
  pageName: 'ClaimInformationPortal',
  appEnv: Envs.Staging,
  supplementURL: 'https://shop-supplement-intake-app-staging.us-east-1.np.paas.lmig.com',
  claimStatusApiUrl: '/cipreg/api',
  shopListApiUrl: '/cipreg/api',
  statusCheckUrl: 'https://account.np-eservice.libertymutual.com/account/registration',
  registrationRequestApiEndpoint: 'https://account.np-eservice.libertymutual.com/account',
  graphqlUri: 'https://ete-eservice.libertymutual.com/apptrove/api/graphql',
  apiKey: API_KEY,
  auth0Config: {
    domain: 'login-np.libertymutual.com',
    clientId: 'uljAPUEM1Fuj4hmoksZA6SUr7Hj2y63E',
    redirectUri: 'https://ete-cipautobody.libertymutual.com/callback',
    refreshDurationOffset: 180,
    enableAutomaticTokenRefresh: true,
    inactivityTimeoutDuration: 20*60,
    enableIdleTimeout: true,
    debug: true,
    returnTo: 'https://ete-cipautobody.libertymutual.com'
  },
  galileoUrl: 'https://dev.galileo.libertymutual.com/v1/apievent',
  tealiumUrl: 'https://tags.tiqcdn.com/utag/libertymutual/claims/dev/utag.js',
  documentUrl: 'https://ete-claims.libertymutual.com'
};

const prodEnv: IEnv = {
  pageName: 'ClaimInformationPortal',
  appEnv: Envs.Production,
  supplementURL: 'https://supplements.libertymutual.com',
  claimStatusApiUrl: '/cipreg/api',
  shopListApiUrl: '/cipreg/api',
  statusCheckUrl: 'https://account.eservice.libertymutual.com/account/registration',
  registrationRequestApiEndpoint: 'https://account.eservice.libertymutual.com/account',
  graphqlUri: 'https://eservice.libertymutual.com/apptrove/api/graphql',
  apiKey: API_KEY,
  auth0Config: {
    domain: 'login.libertymutual.com',
    clientId: 'WhKnCk7LHMUPGzoC5lSOoKumRBgHiqfl',
    redirectUri: 'https://cipautobody.libertymutual.com/callback',
    refreshDurationOffset: 180,
    enableAutomaticTokenRefresh: true,
    inactivityTimeoutDuration: 20*60,
    enableIdleTimeout: true,
    returnTo: 'https://cipautobody.libertymutual.com'
  },
  galileoUrl: 'https://galileo.libertymutual.com/v1/apievent',
  tealiumUrl: 'https://tags.tiqcdn.com/utag/libertymutual/claims/prod/utag.js',
  documentUrl: 'https://claims.libertymutual.com'
};

//
// ################
const envMap = new Map<string, IEnv>();


envMap.set(
  'libertymutual',
  localEnv
);

envMap.set(
  'demo-cipautobody.libertymutual.com',
  demoEnv
);

envMap.set(
  'dev-cipautobody.libertymutual.com',
  devEnv
);

envMap.set(
  'ete-cipautobody.libertymutual.com',
  eteLMEnv
);

envMap.set(
  'cipautobody.libertymutual.com',
  prodEnv
);

const env: IEnv = process.env.REACT_APP_MOCK_SHOW_DEMO === '1' ? demoEnv : envMap.get(HOST_NAME) || localEnv;
// const env: IEnv = eteLMEnv;
export default env;

