import {
  GridRow,
  GridCol,
  Hide,
  Link,
  Card,
  IconClose,
  useThreshold
} from '@lmig/lmds-react';

import ClaimStatusSearchCard from './components/claim-status-search-card';
import ClaimStatusSearchResultCard from './components/claim-status-search-result-card';
import EmptySpace from '../common/components/empty-space/empty-space-component';
import DividerCol from '../common/components/divider/divider-component';
import { useNavigate } from 'react-router-dom';
import NeedHelp from '../home/components/need-help';
import UploadSupplement from './components/upload-supplement';
import { useState } from 'react';
import ClaimStatusSearchNoResultCard from './components/claim-status-search-no-result-card';
import { claimsStatusStore as store } from './store/claims-status-store';
import styles from './components/claim-status-component.module.scss';
import { observer } from 'mobx-react';
import { IClaimStatus } from './models/claim-status-interface';
import CardShimmer from '../common/components/card-shimmer/card-shimmer-component';

const ClaimStatus = observer(() => {
  const navigate = useNavigate();
  const threshold = useThreshold();
  const [claimStatus, setClaimStatus] = useState<IClaimStatus | null>(null);
  const [error, setError] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const clearStoreData = () => {
    store.setClaimNumber('');
    setClaimStatus(null);
    setError(null);
  };

  const back = () => {
    clearStoreData();
    navigate(-1);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setClaimStatus(null);
    setError(null);
    await store.fetchClaimByClaimNumber(store.claimNumber);
    if (!store.error) setClaimStatus(store.claim);
    else setError(store.error);
    setLoading(false);
  };

  return (
    <>
      <GridRow gutters>
        <GridCol>
          <Link
            caretPosition="left"
            variant="standalone"
            onClick={() => back()}
          >
            Back
          </Link>
        </GridCol>
      </GridRow>
      <EmptySpace height={20} />
      <GridRow>
        <GridCol base={12} md={6}>
          <ClaimStatusSearchCard
            handleSubmit={handleSubmit}
            handleClear={clearStoreData}
          />
        </GridCol>
        <GridCol base={12} md={6}>
          {(threshold === 'md' || threshold === 'lg' || threshold === 'xl' || claimStatus || error) && (
            <Card className={styles.results}>
              <GridRow justify="between" vAlign="bottom">
                <GridCol></GridCol>
                <GridCol>
                  {claimStatus && (
                    <Link variant="navigational" onClick={clearStoreData}>
                      Close
                      <IconClose color="teal" className={styles.exitIcon} />
                    </Link>
                  )}
                </GridCol>
              </GridRow>

              <GridRow>
                <GridCol>                    
                  {loading && <CardShimmer small/>}
                  {!error && claimStatus && <ClaimStatusSearchResultCard />}
                  {error && <ClaimStatusSearchNoResultCard />}
                </GridCol>
              </GridRow>
            </Card>
          )}
        </GridCol>
      </GridRow>
      <EmptySpace height={30} />
      <Hide thresholds={['base', 'sm', 'md']}>
        <EmptySpace height={10} />
      </Hide>
      <GridRow justify="center">
        <GridCol data-name="claim-item-loss-date" sm={12} md={4}>
          <UploadSupplement />
        </GridCol>
        <DividerCol md={1} />
        <GridCol data-name="claim-item-create-date" sm={12} md={6}>
          <NeedHelp></NeedHelp>
        </GridCol>
      </GridRow>
      <EmptySpace height={100} />
      <Hide thresholds={['base', 'sm', 'md']}>
        <EmptySpace height={10} />
      </Hide>
    </>
  );
});
export default ClaimStatus;
