import PaymentInformationResults from './components/payment-information-results-component';
import PaymentTable from './components/payment-table-component';
import { claimSearchStore as store } from '../claim-search/store/claim-search-store';
import EmptySpace from '../common/components/empty-space/empty-space-component';

const PaymentInfoTab = (): JSX.Element => (
  <>
    <PaymentInformationResults
      exposureInfo={store.exposureInfo}
      repairStatusInfo={store.repairStatusInfo}
      exposureInfoLoading={store.exposureInfoLoading}
      repairStatusInfoLoading={store.repairStatusInfoLoading}
      directionToPay={store.directionToPay}
    />
    <EmptySpace height={20} />
    <PaymentTable
      paymentInformation={store.paymentInformation}
      isLoading={store.paymentsLoading}
      paymentsError={store.paymentsError}
    />
    <EmptySpace height={20} />
  </>
);

export default PaymentInfoTab;
