import { observer } from 'mobx-react';
import { AlphanumericInput, Button, Caption, Checkbox, CheckboxGroup, FieldGroup, 
  Form, GridCol, GridRow, Heading, Textarea, LoadingSpinner } from '@lmig/lmds-react';
import { contactSupportStore as store } from '../store/support-store';
import { useState } from 'react';
import styles from './contact-support-form.module.scss';

type FormFunctions = {
  data: {
    claimNumber: string,
    customerName: string,
    vehicleYearMakeModel: string
  },
  onClose: Function,
  onSuccess: Function,
  onError: Function,
}

const ContactSupportForm = observer((props: FormFunctions) => {

  let [ showLoadingOverlay, setShowLoadingOveraly ] = useState(false);
  store.claimNumber = props.data.claimNumber;
  store.customerName = props.data.customerName;
  store.vehicleYearMakeModel = props.data.vehicleYearMakeModel;

  return (
    <Form 
      data-testid='form-contact-support-form'
      onSubmit={(e: any) => {
        store.formSubmittable = false;
        setShowLoadingOveraly(true);
        store.submitContactSupportForm(e)
          .then((result: boolean) => result ? props.onSuccess() : null)
          .catch((error: any) => props.onError()); 
      }}
    >
      <Heading type='h3-light'>Contact CIP Support</Heading>
      <Heading type='h4-light'>Having a problem using our portal?  We can help.</Heading>

      <FieldGroup
        id='fieldgroup--claims-issue' 
        labelA11y='What type of issues are you experiencing:'
        labelVisual='What type of issues are you experiencing:'
        messages={store.formErrorIssueInfo.slice()}
        className={styles.fieldGroup}>
        <CheckboxGroup>
          <Checkbox 
            id='chx_claims-issue-missing-payment' 
            data-testid='chx_claims-issue-missing-payment'
            labelVisual='Missing payment' 
            name='claimsIssue' 
            value='missing-payment' 
            checked={store.claimsIssue.indexOf('missing-payment') !== -1}
            onChange={(e: any) => {
              store.updateCheckboxValue('claimsIssue', e.target.value, e.target.checked);
            }}/>
          <Checkbox 
            id='chx_claims-issue-direction-to-pay' 
            data-testid='chx_claims-issue-direction-to-pay'
            labelVisual='Direction to pay' 
            name='claimsIssue' 
            value='direction-to-pay' 
            checked={store.claimsIssue.indexOf('direction-to-pay') !== -1}
            onChange={(e: any) => {
              store.updateCheckboxValue('claimsIssue', e.target.value, e.target.checked);
            }}/>
          <Checkbox 
            id='chx_claims-issue-modify-assignment' 
            data-testid='chx_claims-issue-modify-assignment'
            labelVisual='Modify assignment' 
            name='claimsIssue' 
            value='modify-assignment' 
            checked={store.claimsIssue.indexOf('modify-assignment') !== -1}
            onChange={(e: any) => {
              store.updateCheckboxValue('claimsIssue', e.target.value, e.target.checked);
            }}/>
          <Checkbox 
            id='chx_claims-issue-send-assignment' 
            data-testid='chx_claims-issue-send-assignment'
            labelVisual='Send new assignment' 
            name='claimsIssue' 
            value='send-new-assignment'  
            checked={store.claimsIssue.indexOf('send-new-assignment') !== -1}
            onChange={(e: any) => {
              store.updateCheckboxValue('claimsIssue', e.target.value, e.target.checked);
            }}/>
          <Checkbox 
            id='chx_claims-issue-technical-problem' 
            data-testid='chx_claims-issue-technical-problem'
            labelVisual='Technical problem' 
            name='claimsIssue' 
            value='technical-problem'  
            checked={store.claimsIssue.indexOf('technical-problem') !== -1}
            onChange={(e: any) => {
              store.updateCheckboxValue('claimsIssue', e.target.value, e.target.checked);
            }}/>
          <Checkbox 
            id='chx_claims-issue-other' 
            data-testid='chx_claims-issue-other'
            labelVisual='Other' 
            name='claimsIssue' 
            value='other'  
            checked={store.claimsIssue.indexOf('other') !== -1}
            onChange={(e: any) => {
              store.updateCheckboxValue('claimsIssue', e.target.value, e.target.checked);
            }}/>
        </CheckboxGroup>
      </FieldGroup>

      <FieldGroup
        id='fieldgroup--claims-issue-details'
        labelA11y='Please let us know what you need help with.  The more detail you can provide the better.'
        labelVisual='Please let us know what you need help with.  The more detail you can provide the better.'
        className={styles.fieldGroup}
        messages={store.formErrorIssueInfo.slice()}>
        <Textarea 
          id='text_claims-issue-details'
          data-testid='text_claims-issue-details'
          labelA11y='I need help with...'
          labelVisual='I need help with...' 
          value={store.issueDetails}
          highlightType={store.formErrors.issueDetails}
          onChange={(e: any) => store.updateCheckboxValue('issueDetails', e.target.value, false)}
          onBlur={() => store.fieldValidation('issueDetails', 'IssueInfo')}/>
        <Caption>For security purposes, please do not include any personal information about your customer (such as SSN or credit card). </Caption>
      </FieldGroup>

      <FieldGroup
        id='fieldgroup--customer-info'
        labelA11y=''
        labelVisual=''
        className={styles.fieldGroup}
        messages={store.formErrorCustomerInfo.slice()}>
        <AlphanumericInput 
          id='input_claims-issue-claim-number' 
          data-testid='input_claims-issue-claim-number'
          labelA11y='Claim number'
          labelVisual='Claim number'
          value={store.claimNumber}
          highlightType={store.formErrors.claimNumber}
          readOnly={true}
        />
        <AlphanumericInput 
          id='input_claims-issue-customer-name' 
          data-testid='input_claims-issue-customer-name'
          labelA11y='Customer name'
          labelVisual='Customer name' 
          value={store.customerName}
          highlightType={store.formErrors.customerName}
          readOnly={true}
        />
        <AlphanumericInput 
          id='input_claims-issue-year-make-model' 
          data-testid='input_claims-issue-year-make-model'
          labelA11y='Year Make Model'
          labelVisual='Year Make Model' 
          value={store.vehicleYearMakeModel}
          highlightType={store.formErrors.vehicleYearMakeModel}
          readOnly={true}
        />
      </FieldGroup>

      <FieldGroup
        id='fieldgroup--shop-info'
        labelA11y='Shop Info'
        labelVisual='Shop Info'
        className={styles.fieldGroup}
        messages={store.formErrorShopInfo.slice()}>
        <AlphanumericInput 
          id='input_claims-issue-shop-name' 
          data-testid='input_claims-issue-shop-name'
          labelA11y='Shop name'
          labelVisual='Shop name' 
          value={store.shopName}
          highlightType={store.formErrors.shopName}
          onChange={(e: any) => {
            store.updateValue('shopName', e.target.value);
          }}
          onBlur={(e: any) => {
            store.fieldValidation('shopName', 'ShopInfo');
          }}/>
        <AlphanumericInput 
          id='input_claims-issue-shop-address' 
          data-testid='input_claims-issue-shop-address'
          labelA11y='Shop address'
          labelVisual='Shop address' 
          value={store.shopAddress}
          highlightType={store.formErrors.shopAddress}
          onChange={(e: any) => {
            store.updateValue('shopAddress', e.target.value);
          }}
          onBlur={(e: any) => {
            store.fieldValidation('shopAddress', 'ShopInfo');
          }}/>

        <GridRow gutters justify='between'>
          <GridCol base={8}>
            <AlphanumericInput 
              id='input_claims-issue-shop-city' 
              data-testid='input_claims-issue-shop-city'
              labelA11y='City'
              labelVisual='City' 
              value={store.shopCity}
              highlightType={store.formErrors.shopCity}
              onChange={(e: any) => {
                store.updateValue('shopCity', e.target.value);
              }}
              onBlur={(e: any) => {
                store.fieldValidation('shopCity', 'ShopInfo');
              }}/>
          </GridCol>
          <GridCol base={2}>
            <AlphanumericInput 
              id='input_claims-issue-shop-state' 
              data-testid='input_claims-issue-shop-state'
              labelA11y='State'
              labelVisual='State' 
              value={store.shopState}
              highlightType={store.formErrors.shopState}
              onChange={(e: any) => {
                store.updateValue('shopState', e.target.value);
              }}
              onBlur={(e: any) => {
                store.fieldValidation('shopState', 'ShopInfo');
              }}/>
          </GridCol>
          <GridCol base={2}>
            <AlphanumericInput 
              id='input_claims-issue-shop-zip-code' 
              data-testid='input_claims-issue-shop-zip-code'
              labelA11y='Zip code'
              labelVisual='Zip code' 
              value={store.shopZipCode}
              highlightType={store.formErrors.shopZipCode}
              onChange={(e: any) => {
                store.updateValue('shopZipCode', e.target.value);
              }}
              onBlur={(e: any) => {
                store.fieldValidation('shopZipCode', 'ShopInfo');
              }}/>
          </GridCol>
        </GridRow>

        <AlphanumericInput 
          id='input_claims-issue-shop-phone-number' 
          data-testid='input_claims-issue-shop-phone-number'
          labelA11y='Shop phone number (eg. 123-456-7890)'
          labelVisual='Shop phone number (eg. 123-456-7890)' 
          value={store.shopPhoneNumber}
          highlightType={store.formErrors.shopPhoneNumber}
          onChange={(e: any) => {
            store.updateValue('shopPhoneNumber', e.target.value);
          }}
          onBlur={(e: any) => {
            store.fieldValidation('shopPhoneNumber', 'ShopInfo');
          }}/>
        <AlphanumericInput 
          id='input_claims-issue-shop-email' 
          data-testid='input_claims-issue-shop-email'
          labelA11y='Shop email'
          labelVisual='Shop email' 
          value={store.shopEmail}
          highlightType={store.formErrors.shopEmail}
          onChange={(e: any) => {
            store.updateValue('shopEmail', e.target.value);
          }}
          onBlur={(e: any) => {
            store.fieldValidation('shopEmail', 'ShopInfo');
          }}/>
      </FieldGroup>

      <Caption>Disclaimer:  The CIP mailbox is staffed Monday-Friday 8am-8pm EST.  We strive to respond to all support emails either same day or within one business day.</Caption>

      <GridRow gutters justify="between">
        {showLoadingOverlay 
          ? 
          <>
            <GridCol base={5}></GridCol>
            <GridCol base={2}>
              <LoadingSpinner></LoadingSpinner>
            </GridCol>
            <GridCol base={5}></GridCol>
          </>
          : 
          <>
            <GridCol base={6}>
              <Button
                id='btn-cancel-support-form'
                data-testid='btn-cancel-support-form'
                dynamicWidth
                variant="secondary"
                onClick={() => { store.clearForm(); props.onClose(); } }>
              Cancel
              </Button>
            </GridCol><GridCol base={6}>
              <Button
                id='btn-submit-support-form'
                data-testid='btn-submit-support-form'
                dynamicWidth
                variant="primary"
                type="submit"
                disabled={!store.formSubmittable}>
                {showLoadingOverlay ? <LoadingSpinner></LoadingSpinner> : 'Submit'}
              </Button>
            </GridCol>
          </>
        }
      </GridRow>
    </Form>
  );
});

export default ContactSupportForm;
