import { Response } from 'miragejs';

export const appraisalDetailsRouter = (
  claimNumber: string,
  incidentId: string
) => {
  if (claimNumber === '123456789' && incidentId === '0005') {
    return new Response(
      200,
      {},
      {
        'data': {
          'getAppraisalDetail': {}
        }
      }
    );
  }
  if (claimNumber === '056185836' && incidentId === '0001') {
    return new Response(
      404,
      {},
      {
        'data': {
          'getAppraisalDetails': {}
        }
      }
    );
  } else if (claimNumber === '123456789' && incidentId === '0002') {
    return new Response(
      200,
      {},
      {
        'data': {
          'getAppraisalDetails': {
            'result': [
              {
                'id': 'cc:594570',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Scheduled',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Boston'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725645',
                        'isPrimary': true,
                        'phoneNumber': '617-776-4500',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'LIBERTY MUTUAL TEST RF 3',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              },
              {
                'id': 'cc:594568',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Scheduled',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Framingham'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725641',
                        'isPrimary': true,
                        'phoneNumber': '603-256-5698',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'JP\'s Junky Jalopy',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              }
            ]
          }
        }
      }
    );
  } else if (claimNumber === '123456789' && incidentId === '12345') {
    return new Response(
      200,
      {},
      {
        'data': {
          'getAppraisalDetails': {
            'result': [
              {
                'id': 'cc:594570',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Pending',
                  'date': null
                },
                'assessor': {
                  'address': {
                    'city': 'Boston'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725645',
                        'isPrimary': true,
                        'phoneNumber': '617-776-4500',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'LIBERTY MUTUAL TEST RF 3',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              },
              {
                'id': 'cc:594568',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'this one',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Framingham'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725641',
                        'isPrimary': true,
                        'phoneNumber': '603-256-5698',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'JP\'s Junky Jalopy',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              }
            ]
          }
        }
      }
    );
  } else if (claimNumber === '123456789' && incidentId === '12346') {
    return new Response(
      200,
      {},
      {
        'data': {
          'getAppraisalDetails': {
            'result': [
              {
                'id': 'cc:594570',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Scheduled',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Boston'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725645',
                        'isPrimary': true,
                        'phoneNumber': '617-776-4500',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'LIBERTY MUTUAL TEST RF 3',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              },
              {
                'id': 'cc:594568',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'this one',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Framingham'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725641',
                        'isPrimary': true,
                        'phoneNumber': '603-256-5698',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'JP\'s Junky Jalopy',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              }
            ]
          }
        }
      }
    );
  } else if (claimNumber === '123456789' && incidentId === '12347') {
    return new Response(
      200,
      {},
      {
        'data': {
          'getAppraisalDetails': {
            'result': [
              {
                'id': 'cc:594570',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Cancelled',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Boston'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725645',
                        'isPrimary': true,
                        'phoneNumber': '617-776-4500',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'LIBERTY MUTUAL TEST RF 3',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              },
              {
                'id': 'cc:594568',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'this one',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Framingham'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725641',
                        'isPrimary': true,
                        'phoneNumber': '603-256-5698',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'JP\'s Junky Jalopy',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              }
            ]
          }
        }
      }
    );
  } else {
    return new Response(
      200,
      {},
      {
        'data': {
          'getAppraisalDetails': {
            'result': [
              {
                'id': 'cc:594571',
                'exposure': '056273848-0003',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Canceled',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Boston'
                  },
                  'communication': {
                    'emailList': [
                      {
                        'id': 'cc:1234',
                        'address': 'supershops@noemail.com'
                      }
                    ],
                    'phoneList': [
                      {
                        'id': 'cc:4725645',
                        'isPrimary': true,
                        'phoneNumber': '617-776-4500',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'LIBERTY MUTUAL TEST RF 3',
                  'doingBusinessAs': null,
                  'providerType': 'Direct Repair Facility'
                },
                'dbaname': null
              },
              {
                'id': 'cc:594568',
                'exposure': '056273848-0002',
                'isOkayToPay': true,
                'directionToPay': true,
                'amount': '100.00',
                'appraisalType': 'Original Appraisal',
                'appraisalEventList': [
                  {
                    'id': 'cc:705955',
                    'status': 'Assignment Scheduled',
                    'description': '213-Reject - Invalid CCC Vendor ID',
                    'note': null,
                    'date': '2022-12-23T15:46:08.014Z'
                  },
                  {
                    'id': 'cc:705855',
                    'status': 'Assignment Scheduled',
                    'description': 'Scheduled',
                    'note': null,
                    'date': '2022-12-23T15:46:00.855Z'
                  }
                ],
                'appraisalAppointment': {
                  'id': 'cc:140099',
                  'type': null,
                  'status': 'Canceled',
                  'date': '2015-01-13T18:00:00Z'
                },
                'assessor': {
                  'address': {
                    'city': 'Framingham'
                  },
                  'communication': {
                    'emailList': [],
                    'phoneList': [
                      {
                        'id': 'cc:4725641',
                        'isPrimary': true,
                        'phoneNumber': '603-256-5698',
                        'type': 'Work'
                      }
                    ]
                  },
                  'name': 'JP\'s Junky Jalopy',
                  'doingBusinessAs': null
                },
                'dbaname': null
              }
            ]
          },
          'providerType': 'Direct Repair Facility'
        }
      }
    );
  }
};

export default appraisalDetailsRouter;

