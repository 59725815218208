import { observer } from 'mobx-react';
import { GridRow, GridCol, Show, Heading, Tabs, Tab } from '@lmig/lmds-react';
import { claimSearchStore as store } from './store/claim-search-store';
import { appraisalStore } from '../appraisal/store/appraisal-store';
import EmptySpace from '../common/components/empty-space/empty-space-component';
import ClaimInformation from './components/claim-information-component';
import ClaimSearchForm from './components/claim-search-form';
import NotSeeingFeature from './components/not-seeing-feature';
import Box from '../common/components/box/box-component';
import styles from './claim-search.module.scss';
import AppraisalTab from '../appraisal/appraisal-supplements-tab';
import PaymentInfoTab from '../payments/payment-information-tab';
import {useThreshold} from '@lmig/lmds-react';
import AdditionalInfoTab from '../appraisal/additional-information-tab';
import SupportHelpModal from '../support-help/components/support-help-modal';

const ClaimSearchPage = observer(() => {
  const threshold = useThreshold();
  
  return (
    <div className={styles.claimSearchPage}>
      <GridCol base={12}>
        <ClaimSearchForm />
      </GridCol>
      <GridCol base={12}>
        <EmptySpace height={50} />
      </GridCol>

      {store.searchInit && (
        <>
          <GridRow gutters justify={'between'}>
            <Box />
            <EmptySpace height={40} />
            <ClaimInformation
              exposureInfo={store.exposureInfo}
              claimNumber={store.claimNumber}
              rentalCoverage={store.rentalCoverage}
              liabilityInvestigation={store.liabilityInvestigation}
              liabilityInvestigationError={store.liabilityStatusMessage}
              okayToPay={store.okayToPay}
              isLoading={store.exposureInfoLoading}
            />
            {/* <Show thresholds={['md', 'lg', 'xl']}>
              <NotSeeingFeature desktop={true}/>
            </Show> */}
          </GridRow>
          <Heading type="h3-bold">Claim details</Heading>
          <GridRow>
            <GridCol>
              <Tabs
                className={(threshold === 'sm' || threshold === 'base')? styles.tabScroll: '' }
                compact={true}
                data-testid="claim-details-tabs"
                activeTab={store.activeTab}
                onTabActivated={(event, index) => {
                  store.onTabChange(index);
                  if (index === 1) {
                    appraisalStore.showAppraisalInfo(
                      store.claimNumber,
                      store.exposureInfo && 'incident' in store.exposureInfo
                        ? store.exposureInfo?.incident?.id
                        : ''
                    );
                  }
                }}
              >
                <Tab labelVisual="Payment information">
                  <PaymentInfoTab></PaymentInfoTab>
                </Tab>
                <Tab
                  data-testid="claim-details-tab-appraisal"
                  labelVisual="Appraisal & supplements"                
                >
                  <AppraisalTab></AppraisalTab>
                </Tab>
                <Tab 
                  data-testid="claim-details-tab-additional-info"
                  labelVisual="Additional information"
                >
                  <AdditionalInfoTab></AdditionalInfoTab>
                </Tab>
              </Tabs>
            </GridCol>
          </GridRow>

          <GridRow>
            <GridCol>
              <Show thresholds={['base', 'sm']}>
                <NotSeeingFeature desktop={false} />
              </Show>
            </GridCol>
          </GridRow>

          <SupportHelpModal 
            claimNumber={store.claimNumber}
            customerName={store.exposureInfo ? store.exposureInfo?.claimant?.name : 'N/A'} 
            vehicleYearMakeModel={store.exposureInfo ? store.exposureInfo?.incident?.name : 'N/A'} 
          />
        </>
      )}
    </div>
  );
});

export default ClaimSearchPage;
