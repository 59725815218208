// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import env from '../../../utils/env';
import { auth0Client } from '../../../utils/get-auth0-client';


export const LOCAL_STORAGE_AUTH0_CACHE_KEY: string = `@@auth0spajs@@::${env.auth0Config.clientId}::default::openid profile email offline_access`;
const RECIPIENT_ID = 'https://lmig.com/recipientId';
class AuthService {

  getJWT(): string | null {
    const auth0CacheValue:any = localStorage.getItem(LOCAL_STORAGE_AUTH0_CACHE_KEY);
    if (!auth0CacheValue) return null;
    // The try catch is here to make sure it doesn't error out 
    try {
      // eslint-disable-next-line camelcase
      const auth0JWT = JSON.parse(auth0CacheValue)?.body?.id_token;
      // console.log(`auth0JWT: ${auth0JWT}`);
      // If the token is not in correct format (unable to parse json), send back null
      return auth0JWT ? `Bearer ${auth0JWT}` : null;
    } catch (error) {
      return null;
    }
  }

  deleteJWT(): void {
    localStorage.removeItem(LOCAL_STORAGE_AUTH0_CACHE_KEY);
  }

  logout() {
    auth0Client.logout({returnTo: env.auth0Config.returnTo});
  }

  getLoggedInUser(jwt: string | null = this.getJWT() ): string | null {
    let decodedToken;
    if (jwt) {
      try {
        decodedToken = jwt_decode<any>(jwt);
      } catch (error) {
        return null;
      }
      return decodedToken.name;
    }
    return null;
  }

  getUserRecipientId(jwt: string | null = this.getJWT() ): string | null {
    let decodedToken;
    if (jwt) {
      try {
        decodedToken = jwt_decode<any>(jwt);
      } catch (error) {
        return null;
      }
      return decodedToken[RECIPIENT_ID];
    }
    return null;
  }

  isAuthorized(){    
    const id = this.getUserRecipientId();
    if (id && id.trim().length > 0) {
      return true;
    }
    return false;
  }
}

export default AuthService;
