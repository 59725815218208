import axios from 'axios';
import env from '../../../utils/env';
import { trackCustomHeapEvent } from '../../../utils/heap';

class Auth0Registration {

  async getCurrentStatus(trackingId: string) {
    try {
      const response = await axios.get(`${env.statusCheckUrl}/${trackingId}`);
      trackCustomHeapEvent('auth0-status-check-success', {});
      return response.data;
    } catch (error) {
      trackCustomHeapEvent('auth0-status-check-error', {});
      throw error;
    }
  }
}

export default Auth0Registration;

