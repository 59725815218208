import {
  BodyText,
  Heading,
  Link,
  List,
  ListItem
} from '@lmig/lmds-react';
import { registrationStore as store } from '../store/registration-store';
import EmptySpace from '../../common/components/empty-space/empty-space-component';

const RegistrationConfirmationPage = (): JSX.Element => {
  return (
    <div>
      <Heading>Check your email!</Heading>
      <BodyText>We've sent an email to <b>{store.email}</b> to complete account setup and creation.</BodyText>
      <p></p>
      <BodyText>Click on the "Create your secure password" link in the email to continue.</BodyText>
      <p></p>
      <BodyText>If you didn't receive an email from us, here are some tips:</BodyText>
      <List>
        <ListItem>Check your spam folder.</ListItem>
        {/* <ListItem>Confirm that you entered the correct policy number and that the email is connected to a Liberty Mutual account. If not, <Link href={'/registration'}>register again here</Link>.</ListItem> */}
        <ListItem>If you need further assistance, please contact us at <Link href="mailto:CIPSupport@LibertyMutual.com">CIPSupport@LibertyMutual.com</Link></ListItem>
      </List>
      <EmptySpace height={100} />
    </div>
  );
};

export default RegistrationConfirmationPage;
