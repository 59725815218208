import { observer } from 'mobx-react';
import {
  Form,
  FieldGroup,
  GridCol,
  GridRow,
  Heading,
  AlphanumericInput,
  Button,
  Link
} from '@lmig/lmds-react';
import { registrationStore as store } from '../store/registration-store';
import ShopList from './shop-list';
import ToggleButton from './toggle-button';
import styles from './registration-form.module.scss';
import { useNavigate } from 'react-router-dom';
import EmptySpace from '../../common/components/empty-space/empty-space-component';
import { Loader } from '../../common/components/loader/loader';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import PageError from '../../common/components/page-error/page-error';

const RegistrationForm = observer(() => {
  let navigate = useNavigate();

  const renderFormHeader = (): JSX.Element => (
    <GridRow>
      <GridCol base={'flex-auto'}>
        <EmptySpace height={50} />
        <Link caretPosition="left" variant="standalone"
          onClick={() => navigate(-1)}
        >
          Back
        </Link>
        {store.error && <PageError>{store.error}</PageError>}
        <Heading>Let's register your autobody shop</Heading>
        <Heading type="h4-light">
          Easily access and manage your shop claims online, 24/7.
        </Heading>
      </GridCol>
    </GridRow>
  );

  const renderUserForm = (): JSX.Element => {
    return (
      <GridRow>
        <GridCol>
          <FieldGroup
            id={'registration-user-info'}
            labelStyle={'h2-light'}
            messages={store.formErrorUserInfo.slice()}
            className={styles.fieldGroup}
          >
            <GridRow gutters={'horizontal'}>
              <GridCol base={12} md={6}>
                <AlphanumericInput
                  labelVisual="First name"
                  data-testid="first-name"
                  onBlur={() => store.fieldValidation('firstName', 'UserInfo')}
                  onChange={(e: any) =>
                    store.onChange('firstName', e.target.value)
                  }
                  highlightType={store.formErrors.firstName}
                  value={store.firstName}
                />
              </GridCol>
              <GridCol base={12} md={6}>
                <AlphanumericInput
                  labelVisual="Last name"
                  data-testid="last-name"
                  onBlur={() => store.fieldValidation('lastName', 'UserInfo')}
                  onChange={(e: any) =>
                    store.onChange('lastName', e.target.value)
                  }
                  highlightType={store.formErrors.lastName}
                  value={store.lastName}
                />
              </GridCol>
              <GridCol base={12} md={6}>
                <AlphanumericInput
                  labelVisual="Shop email address"
                  data-testid="email"
                  onBlur={() => store.fieldValidation('email', 'UserInfo')}
                  onChange={(e: any) => store.onChange('email', e.target.value)}
                  highlightType={store.formErrors.email}
                  value={store.email}
                />
              </GridCol>
            </GridRow>
            {!store.continueForm && <ContinueButton/>}
          </FieldGroup>
        </GridCol>
      </GridRow>
    );
  };

  const ContinueButton = (): JSX.Element => (
    <GridRow gutters >
      <GridCol base={12} md={6}>
        <Button
          data-name="cru-create-account-btn"
          data-testid="continue"
          size={'large'}
          variant={'primary'}
          dynamicWidth
          disabled={store.loading || !store.formSubmittable}
          type={'submit'}
          onClick={e => store.setContinueForm()}
        >
          Continue
        </Button>
      </GridCol>
    </GridRow>
  );


  const renderShopForm = (): JSX.Element => (
    <>
      <GridRow>
        <GridCol>
          <Heading type={'h4-light'}>Connect your autobody shop</Heading>
          <ToggleButton />
        </GridCol>
      </GridRow>
      <GridRow>
        <GridCol>
          <FieldGroup
            id={'registration-user-info'}
            labelStyle={'h2-light'}
            messages={store.formErrorShopInfo.slice()}
            className={styles.fieldGroup}
            data-testid="user-form-error"
          >
            <GridRow gutters={'horizontal'}>
              {!store.searchByLmId ? (
                <>
                  <GridCol base={12} md={6}>
                    <AlphanumericInput
                      labelVisual="IRS Tax ID ex. 01-2345678"
                      data-testid="tax-id"
                      onBlur={() => store.fieldValidation('taxId', 'ShopInfo')}
                      onChange={(e: any) => store.onChange('taxId', e.target.value.trim().replace(/(\d{2})-?(\d{7})/, '$1-$2'))}
                      highlightType={store.formErrors.taxId}
                      value={store.taxId}
                    />
                  </GridCol>
                </>
              ) : (
                <GridCol base={12} md={6}>
                  <AlphanumericInput
                    labelVisual="LM ID ex. TCCC0123456789"
                    onBlur={() => store.fieldValidation('lmId', 'ShopInfo')}
                    onChange={(e: any) => store.onChange('lmId', e.target.value)}
                    highlightType={store.formErrors.lmId}
                    helpText={'ID starts with TCCC or CCC'}
                    data-testid="LMID-input"
                    value={store.lmId}
                  />
                </GridCol>
              )}
              <GridCol base={12} md={6}>
                <AlphanumericInput
                  labelVisual="Shop phone number ex. 012-345-6789"
                  data-testid="phone-number"
                  onBlur={(e:any) => { 
                    store.onChange('phone', e.target.value.trim().replace(/[\s+)(]/g, '').replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3'));
                    return store.fieldValidation('phone', 'ShopInfo');
                  }}              
                  onChange={(e: any) => {                     
                    return store.onChange('phone', e.target.value.trim().replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3'), true);
                  }}
                  highlightType={store.formErrors.phone}
                  value={store.phone}
                />
              </GridCol>
              <GridCol base={12} md={6}>
                <AlphanumericInput
                  labelVisual="Zip code"
                  data-testid="zip-code"
                  onBlur={() => store.fieldValidation('zipCode', 'ShopInfo')}
                  onChange={(e: any) =>
                    store.onChange('zipCode', e.target.value, true)
                  }
                  highlightType={store.formErrors.zipCode}
                  value={store.zipCode}
                />
              </GridCol>
            </GridRow>
            {renderSearchButton()}
          </FieldGroup>
        </GridCol>
      </GridRow>
    </>
  );

  const renderSearchButton = (): JSX.Element => (
    <GridRow gutters>
      <GridCol base={12} md={6}>
        <Button
          data-name="cru-render-search-btn"
          data-testid="search"
          size={'large'}
          variant={store.shops && store.shops.length > 0 ? 'secondary' : 'primary'}
          dynamicWidth
          disabled={store.loading || !store.formSubmittable || store.creatingUser}
          type={'submit'}
          onClick={e => store.getShops()}
        >
          Search
        </Button>
      </GridCol>
    </GridRow>
  );

  return (
    <Form>
      {renderFormHeader()}
      {renderUserForm()}     
      {store.continueForm && renderShopForm()}
      {store.loading && !store.creatingUser && <CardShimmer className={styles.lmCard} />}
      {!store.loading && store.shops != null && <ShopList />}
      {store.creatingUser && <Loader />}
      <EmptySpace height={50} />
    </Form>
  );
});

export default RegistrationForm;
