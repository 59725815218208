
export const ErrorMessages = { 
  CLAIM_NOT_FOUND: 'No Claim Found.',
  SERVICE_UNAVAILABLE: 'We are unable to submit your request at this time as our system is currently unavailable. Please try again later and thank you for your patience.',
  EXPOSURES_SERVICE_ERROR: 'findExposures Service error',
  LIABILITY_NOT_AVAILABLE_MESSAGE: 'Liability Status is not available at the moment. Please check back later.',
  REVIEWING_CLAIM: 'Liberty Mutual is currently reviewing this claim. Check back soon for updates.',
  DOCUMENTS_NOT_FOUND: 'No documents found for this claim.',
  DOCUMENT_URL_NOT_FOUND: 'No document was found.',
  NOT_AUTHORIZED: 'Unfortunately, the claim you’re searching for is not assigned to your shop. Please make sure the claim number you entered is correct.'
};
