import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from './reportWebVitals';
import {makeMockServer} from './mocks/server';
import env from './utils/env';
import { Envs } from './features/common/models/generic-enum';


// will load mock api for the following envs.
// @ts-ignore
if ((env.appEnv === Envs.Local || env.appEnv===Envs.Demo) && !window.Cypress) {
  console.log(`${env.appEnv} init mock`);
  makeMockServer(env.appEnv);
}
const root = document.getElementById('root') as HTMLElement;
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
