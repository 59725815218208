import * as yup from 'yup';

export const claimsIssue = yup.array().of(yup.string())
  .min(1, 'Please select an issue')
  .test({
    name: 'details-missing',
    message: 'Additional details are required',
    test: (value, context) => {

      if (value === undefined) return false;

      if (value.indexOf('other') !== -1) {
        if (context.parent.issueDetails.length === 0) {
          return false;
        } 

        return true;
      }

      return true;
    }
  });

export const issueDetails = yup.string().optional(); 

export const claimNumber = yup.string().required('Please enter a valid claim number');

export const customerName = yup.string()
  .required('Please enter a valid name.');

export const vehicleYearMakeModel = yup.string().required('Please enter a valid vehicle year, make, and model.');

export const shopName = yup.string()
  .required('Please enter a valid shop name.');

export const shopAddress = yup.string().optional();

export const shopCity = yup.string().optional();

export const shopState = yup.string().optional().test({
  name: 'state-error',
  message: 'Enter a valid state (eg. TX)',
  test: (value) => {

    if (value === undefined) return true;

    if (value.length === 0) return true;

    const valid = value.match(/^[A-Z]{2}$/);

    if (valid === null) return false;

    if (valid[0] === value) return true;

    return false;
  }
});

export const shopZipCode = yup.string().optional().test({
  name: 'zipCode-error',
  message: 'Zip Code must be 5 digits',
  test: (value) => {

    if (value === undefined) return true;

    if (value.length === 0) return true;

    const valid = value.match(/^[0-9]{5}$/);

    if (valid === null) return false;

    if (valid[0] === value) return true;

    return false;
  }
});

export const shopEmail = yup.string()
  .email('Please enter a valid email.')
  .required('Please enter a valid email.')
  .max(60, 'email must be maximum of 60 characters.')
  .matches(/^(?!.*[&*+`;/\\'",�<>�%()#\\$!])/g, 'email must not contain characters: & * + `; / \' , % # $ !');

export const shopPhoneNumber = yup.string()
  .required('Please Enter a 10 digit phone number.')
  .matches(/(^\(\d{3}\)\s\d{3}-\d{4}$)|(^\d{3}-\d{3}-\d{4}$)|(^\d{10}$)/, 'Please enter a valid phone number.');

export const issueSchema = yup.object({
  claimsIssue,
  issueDetails
});

export const customerSchema = yup.object({
  customerName,
  claimNumber,
  vehicleYearMakeModel
});

export const shopSchema = yup.object({
  shopName,
  shopAddress,
  shopCity,
  shopState, 
  shopZipCode,
  shopEmail,
  shopPhoneNumber
});

export const formSchema = yup.object().shape({
  customerName,
  claimNumber,
  vehicleYearMakeModel,
  shopName,
  shopAddress,
  shopCity,
  shopState, 
  shopZipCode,
  shopEmail,
  shopPhoneNumber
});
