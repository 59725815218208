import {
  BodyText,
  Button,
  GridCol,
  Heading,
  Link
} from '@lmig/lmds-react';

const RegistrationErrorPage = (): JSX.Element => {
  return (
    <div>
      <Heading>Oops! Something went wrong...</Heading>
      <BodyText>We’re sorry — we seem to be having some technical difficulties. No need to worry, please try again or contact us at <Link href="mailto:CIPSupport@LibertyMutual.com">CIPSupport@LibertyMutual.com</Link>.</BodyText>
      <br />
      <GridCol base={12} md={3}>
        <Link href='/'><Button dynamicWidth variant="primary">Go to home</Button></Link>
      </GridCol>
    </div>
  );
};

export default RegistrationErrorPage;
