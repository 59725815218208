import { Card, IconInfo } from '@lmig/lmds-react';
import { DataTable, TableHeader, TableBody, Table, TableCell, TableRow} from '@lmig/lmds-react-table';
import { IRepairStatus } from '../../claim-search/models/claim-interface';
interface PropsType {
  directionToPay?: boolean,
  repairStatusInfo: IRepairStatus;
}
const yesNoFilter = (input:string | null) => {
  let output = 'N/A';
  if (input !== undefined && input !== '') {
    if (input === 'Final Bill') {
      output = 'Yes';
    } else {
      output = 'No';
    }
  }
  return output;
};

const BillingInformation = (props:PropsType): JSX.Element => (
  <DataTable>           
    <Card
      elevation="raised"
    >
      <p><b>Billing information</b></p>
      <Table id="billing-information-table">
        <TableHeader>
          <TableRow>{<></>}</TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Direction to Pay</b>
            </TableCell>
            <TableCell textAlign={'right'}>
              <span>{props.directionToPay ? 'Pay Shop Directly': 'Pay Customer Directly'}</span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Final Bill received </b>
              <IconInfo 
                title={'For Guaranteed Repair Network shops, the Final Bill in the CCC shop event log must be selected before any payments can be made.'}
                color={'teal'}
                size="16"
                style={{
                  marginRight: '0.5rem',
                  marginTop: '-0.3rem'
                }}/>
            </TableCell>
            <TableCell textAlign={'right'}>
              <span>{props.repairStatusInfo ? yesNoFilter(props.repairStatusInfo!.repairStatus) : 'N/A'}</span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  </DataTable>
);

export default BillingInformation;

