import React, { useState } from 'react';
import { Modal, ModalBody } from '@lmig/lmds-react-modal';
import {Button, GridRow, GridCol, Form, Textarea, FieldGroup, Select, SelectOption, Badge, BodyText, Heading, Table, TableCell, TableRow} from '@lmig/lmds-react';
import styles from './cancel-appraisal-modal.module.scss';
import { appraisalStore as store } from '../store/appraisal-store';
import { claimSearchStore } from '../../claim-search/store/claim-search-store';
import { IAppointmentDetail } from '../model/appraisal-Info-interface';
import { IAppraisalDetail } from '../model/appraisal-Info-interface';
import { DataTable, TableHeader, TableBody } from '@lmig/lmds-react-table';
import { formatAppointmentDateTime } from '../helpers/appraisal-data-helper';

interface PropsType {
  appointment: IAppointmentDetail | null;
  appraisalDetail: IAppraisalDetail | null;
  isLoading: boolean;
}

const CancelAppraisalModal = (props: PropsType): JSX.Element => {

  const [open, setOpen] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [otherReason, setOtherReason] = useState<string>('');

  const closeModal = () => {
    setOpen(false);
  };

  const validCancellation = () => {
    if (reason === 'Other'){
      return otherReason ? false : true;
    } else {
      return reason ? false : true;
    }      
  };

  const handleSubmit = () => {
    store.cancelAppraisal(claimSearchStore.claimNumber, claimSearchStore.exposureInfo && 'incident' in claimSearchStore.exposureInfo ? claimSearchStore.exposureInfo?.incident?.id : '', (reason + ' ' + otherReason).trim());
    setReason('');
    setOtherReason('');
  };
  
  return (
    <>

      <Button 
        variant={'primary'}
        data-testid='cancel-appointment'
        onClick={e => setOpen(true)}
      >
            Cancel appointment
      </Button>
      <Modal
        isOpen={open}
        size="small"
        className={styles['lmig-Modal']}
        takeover={['base', 'sm']}
        onClose={closeModal}
      >
        <ModalBody
				 className={styles.lmigModalbody}
        >
          <Form id="cancelModal" onSubmit={closeModal}>
            <GridRow>
              <GridCol base={12}>
                <Heading type="h3">
                  Cancel appraisal appointment
                </Heading>
                <DataTable>
                  <Heading type="h5-bold">
                    Appraisal appointment details
                  </Heading>
                  <Table className={styles.table} id="cancel-appraisal-appointment-table">
                    <TableHeader>
                      <TableRow>{<></>}</TableRow>
                    </TableHeader>
                    <TableBody>
                      <TableRow>
                        <TableCell type="rowHead">
                          Appraisal status
                        </TableCell>
                        <TableCell className={styles.status} textAlign={'right'}>{props.appointment?.status}</TableCell>
                      </TableRow>

                      {props.appointment?.status === 'Scheduled' && 
                      <>
                        <TableRow>
                          <TableCell type="rowHead">
                          Date and time
                          </TableCell>
                          <TableCell textAlign={'right'}>{ formatAppointmentDateTime(props.appointment?.date) }</TableCell>
                        </TableRow>                                
                        <TableRow>
                          <br/>
                          <br/>
                        </TableRow>
                        <TableRow>
                          <TableCell type="rowHead">
                            Appraiser
                          </TableCell>
                          <TableCell textAlign={'right'}>
                            {props.appraisalDetail?.appraiser}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell type="rowHead">
                          Location
                          </TableCell>
                          <TableCell textAlign={'right'}>
                            {props.appraisalDetail?.location}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell type="rowHead">
                            Email address
                          </TableCell>
                          <TableCell textAlign={'right'}>
                            {props.appraisalDetail?.emailAddress}
                          </TableCell>
                        </TableRow>
                      </>
                      }
                    </TableBody>
                  </Table>
                </DataTable>
                <br />
                <FieldGroup
                  className={styles.lmFieldGroup}
                  messages={reason ? [] : [
                    {
                      text: 'You must make a selection',
                      type: 'error'
                    }
                  ]}
                  labelStyle="h6-small"
                  labelTag="h6"
                  labelVisual="Please select reason for cancellation">
   
                  <Select

                    id="reason-for-cancellation"
                    isRequired
                    labelA11y="Reason for Cancellation"
                    data-testid='reason-for-cancellation'
                    labelVisual="Reason for Cancellation"
                    onChange={e => setReason(e.currentTarget.value)}
                    placeholderOption="Reason for Cancellation"
                  >
                    <SelectOption value="Customer was a no-show">
                        Customer was a no-show
                    </SelectOption>
                    <SelectOption value="Was unable to reach the customer">
                        Was unable to reach the customer
                    </SelectOption>
                    <SelectOption value="Customer decided to use different shop">
                      Customer decided to use different shop
                    </SelectOption>
                    <SelectOption value="Customer decided to use other insurance carrier">
                      Customer decided to use other insurance carrier
                    </SelectOption>
                    <SelectOption value="Customer decided not to pursue claim">
                      Customer decided not to pursue claim
                    </SelectOption>
                    <SelectOption value="Other">
                      Other
                    </SelectOption>                  
                  </Select>
                </FieldGroup>

                {reason === 'Other' && 
                   <FieldGroup
                     className={styles.lmFieldGroup}
                     labelVisual='"Other" reason for cancellation'
                     labelStyle="h6-small"
                     labelTag="h6"
                     messages={otherReason  ? [] : [
                       {
                         text: 'You must enter a reason',
                         type: 'error'
                       }
                     ]}>
                     <Textarea
  
                       onChange={e =>setOtherReason(e.target.value)}
									 	  className={styles.textarea}                     
                       //  labelVisual='"Other" reason for cancellation'
                       value={otherReason}
                     />
                   </FieldGroup>

                }
              </GridCol>
            </GridRow>
    
            <GridRow>
              <GridCol base={2} md={1}>
                <p></p>
                <Badge compact={true} highlightType="caution"></Badge>
              </GridCol>
              <GridCol base={10} md={11}>
                <BodyText inline={true} type="default-medium">
                  <p className={styles.pleaseNote}>
                    <b>Please note:</b> if you cancel this appointment you may no longer have access to view this job.
                  </p>
                </BodyText>
              </GridCol>
            </GridRow>

            <GridRow gutters>
              <GridCol md={12} lg={6}>
                <Button
                  size="large"
                  variant="secondary"
                  dynamicWidth={true}
                  data-testid="keep-appointment"
                  type="submit"
                  // onClick={store.cancelAppraisal}
                >
								Keep Appointment
                </Button>
              </GridCol>
              <GridCol md={12} lg={6}>
                <Button
                  size="large"
                  variant="primary"
                  dynamicWidth={true}
                  data-testid="submit-cancellation"
                  type="submit"
                  onClick={handleSubmit}
                  disabled={validCancellation()}
                >
								Submit Cancellation
                </Button>
              </GridCol>
            </GridRow>
          </Form>
        </ModalBody>
      </Modal>
    </>

  );
};

export default CancelAppraisalModal;
