import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { ErrorMessages } from '../../../utils/errorMessages';
import { IAppraisalCancelRes } from '../model/appraisal-Info-interface';

const cancelAppraisalMutation: any = loader('../../../graphql/mutations/cancel-appraisal.graphql');

class CancelAppraisalService {
  async cancelAppraisalAppointment(claimNumber:string, incidentId: string, cancellationReason: string): Promise<IAppraisalCancelRes> {
    try {
      const [claimNumberPart, exposureNumber] = claimNumber.split('-');

      const response = await client(true).mutate({
        mutation: cancelAppraisalMutation,
        variables: {
          claimNumber: claimNumberPart,
          exposureNumber,
          incidentId,
          clientType: 'VENDOR_USER',
          cancellationReason
        },        
        
        fetchPolicy: 'no-cache'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }
      if (!response?.data?.cancelAppraisalAppointment.success) {
        throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
      }
      trackCustomHeapEvent('cancel-appraisal-appointment-success', { success: response?.data?.cancelAppraisalAppointment });
      return response?.data?.cancelAppraisalAppointment|| {};

    } catch (error) {
      trackCustomHeapEvent('cancel-appraisal-appointment-error', {error});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  } 
}

export default CancelAppraisalService;

