import LogoComponent from './logo/logo-component';
import {GridCol, GridRow, Header} from '@lmig/lmds-react';

import styles from './header-component.module.scss';
import {observer} from 'mobx-react';
import { authService } from '../../../common/services';
import LogOut from './logout/logout-component';
import withRouter from '../../../../utils/with-router';

const HeaderComponent = observer( () => {

  return (
    <Header fullWidth={true} className={styles.header}>
      <GridRow className={styles.headerContent}>
        <GridCol>
          <LogoComponent/>
        </GridCol>
        {/* do not show if user is not logged in */}
        {
          authService.getJWT()?
            (
              <GridCol id="header-auth-menu">
                <span className={'cu-pull-right'}>
                  <LogOut/>
                </span>
              </GridCol>
            ):null
        }
      </GridRow>
    </Header>
  );

});

export default withRouter(HeaderComponent);
