import { convertToShortDateTime } from '../../../utils/common';

export const getAssessorName = (latestAppraisal: any) => {
  if (latestAppraisal.assessor) {
    let assessor = latestAppraisal.assessor;

    if (latestAppraisal && assessor) {
      if (hasValue(assessor.dbaname)) {
        assessor.name = assessor.dbaname;
      }

      switch (latestAppraisal.assessor.providerType) {
      case 'Inside Appraiser':
        if (!hasValue(assessor.name)) {
          assessor.name = 'Express Review';
        }
        break;

      case 'Staff':
        if (!hasValue(assessor.name)) {
          assessor.name = 'Field Appraisal';
        }
        break;

      case 'Independent Auto Appraiser':
        if (!hasValue(assessor.name)) {
          assessor.name = 'Independent Auto Appraisal';
        }
        break;
      }
    }
    return assessor.name;
  }
  return '';
};

export const getAppraisalStatus = (latestAppraisal: any) => {
  let returnStatus = '';
  if (Array.isArray(latestAppraisal.appraisalEventList) && latestAppraisal.appraisalEventList.length > 0){
    const appraisalStatus = latestAppraisal.appraisalEventList[0].status;
    if (appraisalStatus !== null && appraisalStatus !== undefined) {
      if (
        appraisalStatus.indexOf('Cancel') > -1 ||
      appraisalStatus.indexOf('Unable') > -1
      ) {
        returnStatus = 'Canceled';
      } else if (appraisalStatus.indexOf('Completed') > -1) {
        returnStatus = 'Completed';
      } else {
        returnStatus = 'Scheduled';
      }
    }
  }
  return returnStatus;
};

export const getAppraisalDate = (latestAppraisal: any) => {
  let returnDate = '';
  if (Array.isArray(latestAppraisal.appraisalEventList) && latestAppraisal.appraisalEventList.length > 0) {
    const appraisalDate = latestAppraisal.appraisalEventList[0].date;
    if (appraisalDate != null && appraisalDate !== undefined) {
      let date = new Date(appraisalDate);
      returnDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
  }
  return returnDate;
};

export const getAssessorEmails = (latestAppraisal: any) => {
  if (
    latestAppraisal &&
    latestAppraisal.assessor &&
    latestAppraisal.assessor.communication &&
    Array.isArray(latestAppraisal.assessor.communication.emailList)
  ) {
    return latestAppraisal.assessor.communication.emailList.length > 0 ? latestAppraisal.assessor.communication.emailList[0].address : '';
  }
  return '';
};

export const getAssessorPhone = (latestAppraisal: any) => {
  let assessorPhoneFilter;
  let assessor = latestAppraisal.assessor;

  if (assessor && assessor.communication && assessor.communication.phoneList) {
    const phoneList = assessor.communication.phoneList;
    const primaryPhone = extractPhoneNumber(phoneList, { primary: true });
    const businessPhone = extractPhoneNumber(phoneList, { type: 'Business' });
    const mobilePhone = extractPhoneNumber(phoneList, { type: 'Mobile' });
    const otherPhone = extractPhoneNumber(phoneList, { type: 'Other Phone' });
    const homePhone = extractPhoneNumber(phoneList, { type: 'Home' });

    if (
      primaryPhone &&
      primaryPhone.type &&
      primaryPhone.type !== 'Fax' &&
      primaryPhone.type !== 'Unknown'
    ) {
      assessorPhoneFilter = primaryPhone;
    } else if (mobilePhone) {
      assessorPhoneFilter = mobilePhone;
    } else if (businessPhone) {
      assessorPhoneFilter = businessPhone;
    } else if (homePhone) {
      assessorPhoneFilter = homePhone;
    } else if (otherPhone) {
      assessorPhoneFilter = otherPhone;
    }

    return assessorPhoneFilter.phoneNumber;
  }
};

export const extractPhoneNumber = (list: any, opt: any) => {
  if (opt.type) {
    return list.filter((d: { type: any }) => d.type === opt.type)[0];
  }
  return list.filter((d: { isPrimary: any }) => d.isPrimary === opt.primary)[0];
};

export const formatAppointmentDateTime = (appointmentDateTime: string | undefined) => appointmentDateTime ? convertToShortDateTime(appointmentDateTime) : 'Pending CCC update';

export const hasValue = (input: string) => input && input !== '';


export const getDirectionToPay = (latestAppraisal: any) => latestAppraisal?.directionToPay;

export const getOkayToPay = (latestAppraisal: any) => latestAppraisal?.isOkayToPay;

export const getProviderType = (latestAppraisal: any) => latestAppraisal?.assessor ? latestAppraisal.assessor.providerType || '' : '';

export const getAppointmentType = (latestAppraisal: any) => latestAppraisal?.appraisalAppointment ? latestAppraisal?.appraisalAppointment.type : null;

export const showCancelButton = (props:any) => { 
  return props.appointment?.status !== 'Canceled' && props.appraisalDetail?.appointmentType !== 'Valet Pickup' && props.appraisalDetail?.providerType === 'Direct Repair Facility';
};
