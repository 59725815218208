import { Response } from 'miragejs';

export const getDocumentUrlRouter = (documentId: string) => {
  if (documentId !== 'lYOyZezkd3KxLMp7') {
    return new Response(404, {}, { message: 'No Document Url Found' });
  } else {
    return new Response(200, {}, {
      'data': {
        'getDocumentURL': {
          downloadUrl: '/apptrove/document/download/migrated/lmclaim/090248738012ba63.pdf?response-content-type=application%2Fpdf&response-content-disposition=inline%3B%20filename%3D%22090248738012ba63.pdf%22&AWSAccessKeyId=ASIAS4OBZOT7C5QV2JF2&Signature=UUdj76L7IuqixtTIrdQW1ZKAsC0%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEG8aCXVzLWVhc3QtMSJHMEUCIQCr1HCHbBvNhS%2FoanbO%2FGJJgiR9ReM%2BOtXExyOM8jvxYQIgeWQyfUVbRHXhzgzpyCS7g0D3vg3msOzAfnmtb4jngVgqvQMIGBAEGgwxOTg1MTE3ODUyMTQiDCAxt%2FY2f0WLQJ1hqSqaA8mU1%2BaDllM41T01b7rtySRkc1qnS1Qd5L84YAHtmImRwiCmfivEGQMOAK1wzMA%2BcvTLF10htA%2BvJyxQ3YRSJRWWTkS1lS5%2F8a47xETYEuWaL4DaUbNoCoIbd4kOsMVB8jVwytrHn37UQlyqXwipP%2FDc5DnDFFrPaJa5avsiY8T9mZ34LRdb647rJ7xhuIyIyrBHZnuUyAjpuWR%2BuYmW2jt1nqLFGTDt2ZO%2FYm39awcQVDry5T2e9sFUOAQcslO6A%2BDMWIFoSwQZcjwsPB6ZsE2aBmi1S6mc1ATnBGOcLdRBMzOe1kOfOeneGIYAa3gb%2B4o1f1Ez03GNdZ0RvvugAP%2FFH7M%2Fi0faYTfId4AQylWAdUFac4smi%2FAVuNaLBw3HlGMCrPqUHwK9nMF7k6Cein3UmaewcYboD%2F%2B%2F2EFDOkyeMxvgOYYYMArlShwoMe0bxXHZgjyMt6y%2BX1fmnwf9%2F97YbYaFu2tNW5Zk1ZUJP6tQwPa9DHfeU5Ap7AB4Yzvk0MLanNrrYJXzUdPTJM59IP7R2ebLmxJWuMknMISe458GOp4B6dd0Wsjlpq8bmu6rnJNdsOZlgo08spZdJ%2BEbFOd%2F5SjKBTSI3wBnzOvmdeP6LsL3mkF4sy9vPRb%2Fw8VtdjFz4qQUTNIzA8DiXHffS3iVORIs9kG7Su7oeXZ2l2iGX%2B7kbVOfpxxiI19ZjoviA4b3foQOsCrsTJCSQOoAK3cCqAKZ%2BY2QoasiohVYUSvDROjjwUCV%2B%2FLWoIkf3%2BKcmh8%3D&Expires=1677251381'					
        }
      }
    });
  }
};

export default getDocumentUrlRouter;

