import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { IRepairStatus} from '../models/claim-interface';
import { ErrorMessages } from '../../../utils/errorMessages';

const getRepairStatus: any = loader('../../../graphql/queries/repair-status.graphql');
class RepairStatusService {

  async getRepairStatusInfo(claimNumber:string, incidentId:string): Promise<IRepairStatus> {
    try {
      const response = await client(true).query({
        query: getRepairStatus,
        variables: {
          repairStatusRequest: {
            claimNumber: claimNumber.split('-')[0],
            incidentId
          }
        },
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }

      if (!response?.data?.getRepairStatus) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      trackCustomHeapEvent('get-repair-status-success', {});
      return response?.data?.getRepairStatus?.result;

    } catch (error) {
      trackCustomHeapEvent('get-repair-status-error', {});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  }

}

export default RepairStatusService;

