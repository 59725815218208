import { BodyText, Button, GridCol } from '@lmig/lmds-react';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0Authentication } from '../../hooks/use-auth0-authentication';
import env from '../../utils/env';
import EmptySpace from './components/empty-space/empty-space-component';
import { Envs } from './models/generic-enum';
import { authService } from './services';
import { trackCustomHeapEvent } from '../../utils/heap';


const CallBackPage = observer(() => {
  const [isAuthenticated] = useAuth0Authentication(true);
  const location = useLocation();
  const navigate = useNavigate();

  // @ts-ignore
  let redirectPath = location.state?.from?.pathname;

  // NOTE: only will be called locally.
  const setMockToken = () => {
    localStorage.setItem('@@auth0spajs@@::sqFPpbph5XgS0j0Pv13i7NNq6LiUM5j0::default::openid profile email offline_access', '{"body": {"id_token": "yes"}}');
    navigate('/claim-search');
  };

  const renderLogin = () => {
    if (env.appEnv === Envs.Local || env.appEnv === Envs.Demo) {
      return (
        <GridCol id="login-problem-txt">
          <EmptySpace height={64}/>
          <Button id='btn-local-login' onClick={setMockToken}>{env.appEnv === Envs.Demo? 'Demo Login' : 'Local Login'}</Button>
          <BodyText><p>Try claim number: <b>123456789-0002</b></p></BodyText>
        </GridCol>
      );
    } else {
      return <div>Redirecting to login. Please wait</div>;
    }
  };

  const redirectToClaims = () => {
    trackCustomHeapEvent('user-logged-in-with-receipientId', { recipientId: authService.getUserRecipientId()});
    navigate(redirectPath || '/claim-search');
  };

  const renderState = () => {
    if (env.appEnv === Envs.Local || env.appEnv === Envs.Demo) {
      return renderLogin();
    }
    return isAuthenticated ? redirectToClaims() : renderLogin();
  };
  
  return (
    <>
      {renderState()}
    </>

  );
});

export default CallBackPage;
