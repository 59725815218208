import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { ILiabilityInvestigation } from '../models/claim-interface';
import { ErrorMessages } from '../../../utils/errorMessages';
import { trackCustomHeapEvent } from '../../../utils/heap';

const getLiabilityInvestigation: any = loader('../../../graphql/queries/claim-search.graphql');
class LiabilityInvestigationService {
  async getLiabilityInfo(claimNumber:string): Promise<ILiabilityInvestigation | null> {
    try {
      const response = await client(true).query({
        query: getLiabilityInvestigation,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          exposureNumber: claimNumber.split('-')[1]
        },
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }
      if (!response?.data?.getLiabilityInvestigation === undefined) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      trackCustomHeapEvent('get-liability-investigation-success', {});
      return response?.data?.getLiabilityInvestigation;

    } catch (error) {
      trackCustomHeapEvent('get-liability-investigation-error', {});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  }
}

export default LiabilityInvestigationService;

