import {
  observable,
  action,
  runInAction,
  configure,
  makeObservable
} from 'mobx';

import { getAppraisalStatus, getAssessorEmails, getAssessorName, getAssessorPhone, getDirectionToPay, getOkayToPay, getProviderType, getAppointmentType, getAppraisalDate } from '../helpers/appraisal-data-helper';
import { IAppointmentDetail, IAppraisalDetail, IAppraisalDocument, IAppraisalHistory } from '../model/appraisal-Info-interface';
import { appraisalService, cancelAppraisalService, getDocumentsService, getDocumentUrlService, appraisalHistoryService} from '../services';

// Enable Restrict mode. Recommended
// configure({ enforceActions: 'observed' });

/**
 * Claim Search Store
 *
 * @export
 * @class AppraisalStore
 */

configure({ enforceActions: 'observed' });
export default class AppraisalStore {
  constructor() {
    makeObservable(this); //Newer version of mobX
  }

  @observable error: string | null = null; // API error message. Not shown if null

  @observable appraisalLoading: boolean = false;
  @observable appraisalError: string = '';
  @observable appraisalDocumentsLoading: boolean = false;

  @observable appraisalDetail: IAppraisalDetail | null = null;
  @observable appointmentDetail: IAppointmentDetail | null = null;
  @observable appraisalDocuments: IAppraisalDocument[] | null = [];

  @observable downloadUrl: string | null = null;
  @observable downloadUrlLoading: boolean = false;
  @observable downloadUrlError: string = '';
  @observable appraisalHistory: IAppraisalHistory[] | null = null;

  @action
  async showAppraisalInfo(claimNumber:string, incidentId: string) {
    this.error = null;
    //@ts-ignore
    try {
      //clear values
      runInAction(() => {
        this.getAppraisalDetails(claimNumber, incidentId);
        this.getAppraisalHistory(claimNumber, incidentId);
        this.getDocuments(claimNumber);
      });
      
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
      });
    }
  }

  @action
  async resetAppraisal() {
    this.error = null;
    //@ts-ignore
    try {
      //clear values
      runInAction(() => {
        this.appraisalDetail = null;
        this.appointmentDetail = null;
      });
      
    } catch (error: any) {
      runInAction(() => {
        this.error = error.message;
      });
    }
  }

  getAppraisalDetails = async (claimNumber:string, incidentId: string) => {
    this.appraisalError = '';
    if (!this.appraisalDetail) {
      try {
        this.appraisalLoading = true;
        const appraisalData = await appraisalService.getLatestAppraisalDetails(claimNumber, incidentId);
        runInAction(() => {
          this.appraisalDetail = this.mapAppraisalDetails(appraisalData);
          this.appointmentDetail = appraisalData.appraisalAppointment;
          this.appraisalLoading = false;
        });
      } catch (error: any) {
        this.appraisalLoading = false;
        this.appraisalDetail = null; //Will default to No
        this.appraisalError = error.message;
      }
    }
  };

  getAppraisalHistory = async (claimNumber:string, incidentId:string) => {
    this.appraisalError = '';
    if (!this.appraisalHistory) {
      try {
        this.appraisalLoading = true;
        const appraisalHistoryData = await appraisalHistoryService.getAppraisalHistory(claimNumber, incidentId);

        this.appraisalHistory = appraisalHistoryData.map((data: any) => {
          return this.mapAppraisalHistory(data);
        });

      } catch (error: any) {
        this.appraisalLoading = false;
        this.appraisalHistory = null; //Will default to No
        this.appraisalError = error.message;
      }
    }
  };

  getDocuments = async (claimNumber:string) => {
    this.appraisalError = '';    
    try {
      this.appraisalDocumentsLoading = true;
      const appraisalDocumentsData = await getDocumentsService.getDocuments(claimNumber);
      runInAction(() => {
        this.appraisalDocumentsLoading = false;
        this.appraisalDocuments = appraisalDocumentsData;
      });
    } catch (error: any) {
      this.appraisalDocumentsLoading = false;
      this.appraisalError = error.message;
    } 
  };
  

  @action
  async cancelAppraisal(claimNumber:string, incidentId: string, reason:string) {
    this.error = null;
    //@ts-ignore
    try {
      //clear values
      this.appraisalLoading = true;
      await cancelAppraisalService.cancelAppraisalAppointment(claimNumber, incidentId, reason);
      runInAction(() => {
        this.appraisalLoading = false;
        if (this.appointmentDetail)
          this.appointmentDetail.status = 'Canceled';
      });
      
    } catch (error: any) {
      this.appraisalLoading = false;
      this.appraisalDetail = null; //Will default to No
      this.appraisalError = error.message;
    }
  }

  getDocumentUrl = async (documentId:string) => {
    this.error = null;
    this.downloadUrl = null;
    this.downloadUrlError = '';
    //@ts-ignore
    try {
      //clear values
      this.downloadUrlLoading = true;
      let url = await getDocumentUrlService.getDocumentUrl(documentId);

      runInAction(() => {
        this.downloadUrlLoading = false;
        this.downloadUrl = url?.downloadUrl;
      });
      
    } catch (error: any) {
      this.downloadUrlLoading = false;
      this.downloadUrlError = error.message;
    }
  };

  private mapAppraisalDetails = (latestAppraisal: any) : IAppraisalDetail=>{
    return {
      appraiser: getAssessorName(latestAppraisal),
      location: latestAppraisal?.assessor?.address?.city,
      appraisalStatus: getAppraisalStatus(latestAppraisal),
      emailAddress: getAssessorEmails(latestAppraisal),
      phoneNumber: getAssessorPhone(latestAppraisal),
      directionToPay: getDirectionToPay(latestAppraisal),
      isOkayToPay: getOkayToPay(latestAppraisal),
      providerType: getProviderType(latestAppraisal),
      appointmentType: getAppointmentType(latestAppraisal)
    };
  };

  private mapAppraisalHistory = (appraisal: any) : IAppraisalHistory => {

    const appraisalStatus = appraisal.appraisalEventList[0].status;

    return {
      date: getAppraisalDate(appraisal),
      type: (appraisal?.appraisalType === null || appraisal?.appraisalType === undefined || appraisal?.appraisalType.length === 0) ? 'N/A' : appraisal?.appraisalType,
      amount: appraisal?.amount === null || appraisal?.amount.length === 0 ? '$0.00' : `$${appraisal?.amount}`,
      status: (appraisalStatus === null || appraisalStatus === undefined || appraisalStatus.length === 0) ? 'N/A' : appraisalStatus 
    };
  };
}

export const appraisalStore = new AppraisalStore();
