import regStatus from './features/registration/models/registration-statuses-enum';

type Config = {
  auth0RegStatuses: {
    pollingDelayMS: number,
    totalTimeoutMS: number,
    maxConsecutiveFailures: number,
    statuses: { status: string, terminating: boolean }[]
  }
  appTimeoutDuration: number
  claimInfoContacts: {
    maxAllowedPhones: number,
    maxAllowedEmails: number
  },
  enableSaleMove: boolean,
  enableDamageReviewSetup: boolean
}
const config: Config = {
  auth0RegStatuses: {
    pollingDelayMS: 1000,
    totalTimeoutMS: 120000, // 120 seconds. todo: check heap to see if too many people are getting timeout.
    maxConsecutiveFailures: 60, // tries x times to call the statuses api before generating an error. in case the statuses api fails.
    statuses: [
      {
        status: regStatus.Received,
        terminating: false
      },
      {
        status: regStatus.Validated,
        terminating: false
      },
      {
        status: regStatus.ValidationFailed,
        terminating: true
      },
      {
        status: regStatus.IdentityCreated,
        terminating: false
      },
      {
        status: regStatus.IdentityCreationFailed,
        terminating: true
      },
      {
        status: regStatus.IdentityCreationFailedDuplicate,
        terminating: true
      },
      {
        status: regStatus.Completed,
        terminating: true
      },
      {
        status: regStatus.RollbackCompleted,
        terminating: true
      },
      {
        status: '',  // wrong tracking id
        terminating: false
      }
    ]
  },
  appTimeoutDuration: 1200, // seconds
  claimInfoContacts: {
    maxAllowedPhones: 5,
    maxAllowedEmails: 5
  },
  enableSaleMove: true,
  enableDamageReviewSetup: true
};
export default config;
