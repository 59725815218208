import { Card, Heading } from '@lmig/lmds-react';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@lmig/lmds-react-table';
import styles from '../../claim-search/claim-search.module.scss';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import { IAppraisalDetail } from '../model/appraisal-Info-interface';

interface PropsType {
  appraisalDetail: IAppraisalDetail | null;
  isLoading: boolean;

}

const AppraisalDetailCard = (props:PropsType): JSX.Element => (
  (props.isLoading || props.appraisalDetail === null) ? <CardShimmer></CardShimmer> :
    <Card className={styles.card} elevation="raised">
      <DataTable>
        <Heading type="h5-bold">
        Appraisal details
        </Heading>
        <Table id="deductible-information-table">
          <TableHeader>
            <TableRow>{<></>}</TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell type="rowHead">
              Appraisal status
              </TableCell>
              <TableCell 
                data-testid="appraisal-status"
                textAlign={'right'}>{props.appraisalDetail?.appraisalStatus}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell type="rowHead">
              Appraiser
              </TableCell>
              <TableCell 
                data-testid="appraisar"
                textAlign={'right'}
              >
                {props.appraisalDetail?.appraiser}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell type="rowHead">
              Location
              </TableCell>
              <TableCell 
                data-testid="appraisal-location"
                textAlign={'right'}>
                {props.appraisalDetail?.location}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell type="rowHead">
              Phone number
              </TableCell>
              <TableCell 
                data-testid="appraisal-phonenumber"
                textAlign={'right'}>
                {props.appraisalDetail?.phoneNumber}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell type="rowHead">
              Email address
              </TableCell>
              <TableCell 
                data-testid="appraisal-email"
                textAlign={'right'}>
                {props.appraisalDetail?.emailAddress}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DataTable>
    </Card>
);

export default AppraisalDetailCard;
