import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { ErrorMessages } from '../../../utils/errorMessages';
import { ISupportForm } from '../../support-help/models/support-form-interface';

const sendSupportEmailMutation: any = loader('../../../graphql/mutations/send-vendor-user-support-email.graphql');

class SendSupportEmailService {
  async sendSupportEmail(data:any): Promise<ISupportForm> {
    try {
      const response = await client(true).mutate({
        mutation: sendSupportEmailMutation,
        variables: data,                
        fetchPolicy: 'no-cache'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }
      if (!response?.data?.sendVendorUserSupportEmail.result) {
        throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
      }
      trackCustomHeapEvent('send-vendor-user-support-email-success', { success: response?.data?.sendVendorUserSupportEmail });
      return response?.data?.sendVendorUserSupportEmail|| {};

    } catch (error) {
      trackCustomHeapEvent('send-vendor-user-support-email--error', {error});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  } 
}

export default SendSupportEmailService;

