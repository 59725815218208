import { GridRow, GridCol, Heading } from '@lmig/lmds-react';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';
import BillingInformation from './billing-information-component';
import { IExposureInfo, IRepairStatus} from '../../claim-search/models/claim-interface';
import DeductibleInformation from './deductible-information-component';
interface PropsType {
  exposureInfo: IExposureInfo | null;
  exposureInfoLoading: boolean;
  repairStatusInfo: IRepairStatus;
  repairStatusInfoLoading: boolean;
  isLoading?: boolean;
  directionToPay?:boolean;
}


const PaymentInformationResults = (props:PropsType): JSX.Element => (
  <>
    <Heading type="h4-bold">
      Payment information
    </Heading>
    <GridRow gutters justify={'between'}>
      <GridCol base={12} md={6}> 
        { props.exposureInfoLoading ? <CardShimmer small/> :     
          <DeductibleInformation exposureInfo={props.exposureInfo}/> }
      </GridCol>
      <GridCol base={12} md={6}>
        { props.repairStatusInfoLoading ? <CardShimmer small/> : <BillingInformation repairStatusInfo={props.repairStatusInfo} directionToPay={props.directionToPay}/> }
      </GridCol>
    </GridRow>
  </>
);     

export default PaymentInformationResults;


