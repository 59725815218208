import { Response } from 'miragejs';

export const repairStatusRouter = (claimNumber: string, incidentId: string) => {
  if (incidentId === '12345'){
    return new Response(200, {},
      {
        'data': {
          'getRepairStatus': {
            'result': {
              'id': '123',
              'incidentId': '12345',
              'repairStatus': 'Final Bill selected'       
            }            
          }
        }
      });
  } else if (incidentId === '99999'){
    return new Response(200, {},
      {
        'data': {
          'getRepairStatus': {
            'result': {
              'id': '345',
              'incidentId': '99999',
              'repairStatus': 'Not Final Bill selected'       
            }
          }
        }
      });
  } else {
    return new Response(200, {},
      {
        'data': {
          'getRepairStatus': {
            'result': {
              'id': '123',
              'incidentId': '12345',
              'repairStatus': 'Final Bill'     
            }
          }
        }
      });
  }
};

export default repairStatusRouter;

