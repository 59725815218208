import React, {Fragment} from 'react';
import styles from './card-shimmer-component.module.scss';
import {Card} from '@lmig/lmds-react';


type CardShimmerProps = {
  small?: boolean,
  className?: string
}

const CardShimmer = ({small = false, className = ''}: CardShimmerProps) => (
  <Card className={`${styles.Card} ${className}`}>
    <div className={styles.Content}>
      {small ? (
        <Fragment>
          <div className={`${styles.Line1} ${styles.Skeleton}`}/>
          <div className={`${styles.Line2Small} ${styles.Skeleton}`}/>
          <div className={`${styles.Line3Small} ${styles.Skeleton}`}/>
          <div className={`${styles.Line4Small} ${styles.Skeleton}`}/>
        </Fragment>
      ) : (
        <Fragment>
          <div className={`${styles.Line1} ${styles.Skeleton}`}/>
          <div className={`${styles.Line2} ${styles.Skeleton}`}/>
          <div className={`${styles.Line3} ${styles.Skeleton}`}/>
          <div className={`${styles.Line4} ${styles.Skeleton}`}/>
          <div className={`${styles.Line5} ${styles.Skeleton}`}/>
          <div className={`${styles.Line6} ${styles.Skeleton}`}/>
        </Fragment>
      )}
    </div>
  </Card>
);


export default CardShimmer;
