import { observable, configure, makeObservable, action, runInAction } from 'mobx';
import { ISupportFormError, FieldNames, SectionNames, MessageType, ISupportForm } from '../models/support-form-interface';

import * as schema from '../validation/support-form-validation-schema';
import { sendSupportEmailService } from '../../appraisal/services';
import { escapeString } from '../../../utils/common';

configure({ enforceActions: 'observed' });

export default class ContactSupportStore {

  constructor() {
    makeObservable(this);
  }

  @observable formErrorIssueInfo: MessageType[] = [];
  @observable formErrorCustomerInfo: MessageType[] = [];
  @observable formErrorShopInfo: MessageType[] = [];
  @observable formSubmittable: boolean = false;
  @observable sendSupportEmailLoading: boolean = false;

  @observable claimsIssue: string[] = [];
  @observable issueDetails: string = '';
  @observable claimNumber: string = '';
  @observable customerName: string = '';
  @observable vehicleYearMakeModel: string = '';
  @observable shopName: string = '';
  @observable shopAddress: string = '';
  @observable shopCity: string = '';
  @observable shopState: string = '';
  @observable shopZipCode: string = '';
  @observable shopPhoneNumber: string = '';
  @observable shopEmail: string = '';

  @observable formErrors: ISupportFormError = {
    claimsIssue: undefined,
    issueDetails: undefined,
    claimNumber: undefined,
    customerName: undefined,
    vehicleYearMakeModel: undefined,
    shopName: undefined,
    shopAddress: undefined,
    shopCity: undefined,
    shopState: undefined,
    shopZipCode: undefined,
    shopEmail: undefined,
    shopPhoneNumber: undefined
  };

  // add unique MessageType obj to an array.
  private static _addUniqueToArr(fieldName: FieldNames, message: string, arr: MessageType[]): void {
    if (!arr.find((obj: MessageType) => obj.text === message)) {
      arr.push({ fieldName, text: message, type: 'error' });
    }
  }

  // remove all objects by the specified field name from array.
  private static _removeFromArrayByFieldName(fieldName: FieldNames, arr: MessageType[]): void {
    while (arr.find(obj => obj.fieldName === fieldName)) {
      // @ts-ignore
      arr.remove(arr.find(obj => obj.fieldName === fieldName));
    }
  }

  @action
  async fieldValidation(fieldName: FieldNames | 'claimsIssue', sectionName: SectionNames): Promise<void> {
    // create yup schema for validation
    try {

      await schema[fieldName].validate(this[fieldName]);
      runInAction(() => {
        this.formErrors[fieldName] = undefined;
        // @ts-ignore
        ContactSupportStore._removeFromArrayByFieldName(fieldName, this['formError' + sectionName]);
      });
    } catch (error: any) {
      runInAction(() => {
        this.formErrors[fieldName] = 'error';
        // @ts-ignore
        ContactSupportStore._addUniqueToArr(fieldName, error.message, this['formError' + sectionName]);
      });
    }
  }

  @action
  clearForm(): void {
    this._clearFormValues();
    this._clearErrors();
  }

  private _clearErrors(): void {
    this.formErrors = {
      claimsIssue: undefined,
      issueDetails: undefined,
      claimNumber: undefined,
      customerName: undefined,
      vehicleYearMakeModel: undefined,
      shopName: undefined,
      shopAddress: undefined,
      shopCity: undefined,
      shopState: undefined,
      shopZipCode: undefined,
      shopEmail: undefined,
      shopPhoneNumber: undefined
    };
  }

  private _clearFormValues(): void {
    this.claimsIssue = [];
    this.issueDetails = '';
    this.claimNumber = '';
    this.customerName = '';
    this.vehicleYearMakeModel = '';
    this.shopName = '';
    this.shopAddress = '';
    this.shopCity = '';
    this.shopState = '';
    this.shopZipCode = '';
    this.shopEmail = '';
    this.shopPhoneNumber = '';
  }

  @action
  updateValue(key: FieldNames, value: string) : void {
    this[key] = value;

    this.formSubmittable = this._vCheckIssueForm() && this._vCheckCustomerForm() && this._vCheckShopForm();
  }

  @action
  updateCheckboxValue(key: 'claimsIssue' | 'issueDetails', value: string, checked: boolean): void {

    if (key === 'claimsIssue') {
      if (checked && this[key].indexOf(value) === -1) {
        this[key].push(value);
      } else if (checked === false && this[key].indexOf(value) >= 0) {
        this[key].splice(this[key].indexOf(value), 1);
      }
    } else if (key === 'issueDetails') {
      this[key] = value;
    }

    try { 

      schema.issueSchema.validateSync({
        claimsIssue: this.claimsIssue,
        issueDetails: this.issueDetails
      });

      runInAction(() => {
        this.formErrors['claimsIssue'] = undefined;
        // @ts-ignore
        ContactSupportStore._removeFromArrayByFieldName('claimsIssue', this['formErrorIssueInfo']);
      });
    } catch (error: any) {
      runInAction(() => {
        this.formErrors['claimsIssue'] = 'error';
        // @ts-ignore
        ContactSupportStore._addUniqueToArr('claimsIssue', error.message, this['formErrorIssueInfo']);
      });
    }

    this.formSubmittable = this._vCheckIssueForm() && this._vCheckCustomerForm() && this._vCheckShopForm();
  }


  @action 
  async submitContactSupportForm(e: any): Promise<boolean> {

    e.preventDefault();

    const isFormValid = await schema.formSchema.isValid(this);
    if (isFormValid) {
      try {
      //clear values
        const data : ISupportForm = {
          claimsIssue: this.claimsIssue.map(v => escapeString(v)),
          issueDetails: escapeString(this.issueDetails.replace(/[\r\n]/g, '')),
          claimNumber: escapeString(this.claimNumber),
          customerName: escapeString(this.customerName),
          vehicleYearMakeModel: escapeString(this.vehicleYearMakeModel),
          shopName: escapeString(this.shopName),
          shopAddress: escapeString(this.shopAddress),
          shopCity: escapeString(this.shopCity),
          shopState: escapeString(this.shopState),
          shopZipCode: escapeString(this.shopZipCode),
          shopPhoneNumber: escapeString(this.shopPhoneNumber),
          shopEmail: escapeString(this.shopEmail)
        };
        this.sendSupportEmailLoading = true;
        await sendSupportEmailService.sendSupportEmail(data);
        runInAction(() => {
          this.sendSupportEmailLoading = false;
        
        });
        
      } catch (error: any) {
        throw error;
      }
      
 
      this._clearFormValues();
      this._clearErrors();

      return true;
    } else {

      try {
        await schema.customerSchema.validate({...this}, { abortEarly: false });
      } catch (error: any) {

      }
    }

    return false;
  }

  @action
  private _vCheckIssueForm(): boolean {
    return schema.issueSchema.isValidSync({
      claimsIssue: this.claimsIssue,
      issueDetails: this.issueDetails
    });
  }

  @action
  private _vCheckCustomerForm(): boolean {
    return schema.customerSchema.isValidSync({
      claimNumber: this.claimNumber,
      customerName: this.customerName,
      vehicleYearMakeModel: this.vehicleYearMakeModel
    });
  }

  @action
  private _vCheckShopForm(): boolean {
    return schema.shopSchema.isValidSync({
      shopName: this.shopName,
      shopAddress: this.shopAddress,
      shopCity: this.shopCity,
      shopState: this.shopState,
      shopZipCode: this.shopZipCode,
      shopEmail: this.shopEmail,
      shopPhoneNumber: this.shopPhoneNumber
    });
  }
};

export const contactSupportStore = new ContactSupportStore();
