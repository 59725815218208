
import AppraisalService from './appraisal.service';
import AppraisalHistoryService from './appraisal-history-service';
import CancelAppraisalService from './cancel-appraisal-service';
import DocumentsService from './get-documents-service';
import GetDocumentUrlService from './get-document-url-service';
import SendSupportEmailService from './send-vendor-user-support-email';

export const appraisalService = new AppraisalService();
export const appraisalHistoryService = new AppraisalHistoryService();
export const cancelAppraisalService = new CancelAppraisalService();
export const getDocumentsService = new DocumentsService();
export const getDocumentUrlService = new GetDocumentUrlService();
export const sendSupportEmailService = new SendSupportEmailService();
