import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';
import analyticsManager from '@lmig/crab-rangoons';
import './app.scss';
import FooterComponent from './features/layout/components/footer/footer-component';
import HeaderComponent from './features/layout/components/header/header-component';
import AppLayout from './features/layout/components/app-layout';
import GlobalBanners from './features/layout/components/global-banner/global-banners';
import ClaimStatus from './features/claim-status/claim-status-page';
import Registration from './features/registration/registration-page';
import RegistrationConfirmationPage from './features/registration/components/registration-confirmation-page';
import RegistrationErrorPage from './features/registration/components/registration-error-page';
import { useAuth0Authentication } from './hooks/use-auth0-authentication';
import { authService } from './features/common/services';
import CallBackPage from './features/common/callback-page';
import ClaimSearchPage from './features/claim-search/claim-search-page';
import env from './utils/env';
import { Envs } from './features/common/models/generic-enum';
import { rumAnalytics } from './utils/rum-analytics';
import { trackCustomHeapEvent } from './utils/heap';

const PublicRoute = ({ children }: any) => {
  const [isAuthenticated] = useAuth0Authentication();
  if (
    !(env.appEnv === Envs.Local || env.appEnv === Envs.Demo) &&
    isAuthenticated
  ) {
    // user is logged in, log the user out logged in users should not access
    authService.logout();
  }
  return children;
};

const PrivateRoute = ({ children }: any) => {
  const [isAuthenticated] = useAuth0Authentication();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/callback" state={{ from: location }} replace />;
  }

  if (!(env.appEnv === Envs.Local || env.appEnv === Envs.Demo)) {
    if (isAuthenticated && !authService.isAuthorized()) {
      trackCustomHeapEvent('unauthorized-user-role', {});
      return authService.logout();
    }
  }
  return children;
};


const NotFoundRoute = () : any  => {
  const isAuthorized = authService.isAuthorized();
  const location = useLocation();
 
  if (env.appEnv === Envs.Local || env.appEnv === Envs.Demo || isAuthorized) {
    return <Navigate to="/claim-search" state={{ from: location }} replace />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

function App() {
  rumAnalytics();
  analyticsManager.init(null, {
    isProd: env.appEnv === Envs.Production,
    galileoUrl: env.galileoUrl,
    tealiumUrl: env.tealiumUrl,
    pageName: 'ClaimInformationPortal',
    debugLogging: env.appEnv === Envs.Local || env.appEnv === Envs.Demo
  });

  return (
    <BrowserRouter basename={'/'}>
      <AppLayout
        banners={<GlobalBanners />}
        header={<HeaderComponent />}
        content={
          <Routes>
            {/* PUBLIC ROUTES */}
            <Route
              path="/"
              element={
                <PublicRoute>
                  <CallBackPage />
                </PublicRoute>
              }
            />
            <Route path="/callback" element={<CallBackPage />} />
            <Route
              path="/claim-status"
              element={
                <PublicRoute>
                  <ClaimStatus />
                </PublicRoute>
              }
            />
            <Route
              path="/registration"
              element={
                <PublicRoute>
                  <Registration />
                </PublicRoute>
              }
            />
            <Route
              path="/confirmation"
              element={
                <PublicRoute>
                  <RegistrationConfirmationPage />
                </PublicRoute>
              }
            />
            <Route
              path="/error"
              element={
                <PublicRoute>
                  <RegistrationErrorPage />
                </PublicRoute>
              }
            />
            {/* PRIVATE ROUTES */}
            <Route
              path="/claim-search"
              element={
                <PrivateRoute>
                  <ClaimSearchPage />
                </PrivateRoute>
              }
            />
            {/* ERROR ROUTES */}
            <Route path='*' element={
              <NotFoundRoute />
            } 
            />
          </Routes>
        }
        footer={<FooterComponent />}
      />
    </BrowserRouter>
  );
}

export default App;


