import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { IIncidentDetails } from '../models/claim-interface';
import { ErrorMessages } from '../../../utils/errorMessages';
const getClaimsIncidentDetails : any = loader('../../../graphql/queries/get-claims-incident-details.graphql');

class GetClaimsIncidentDetailsService {

  async getClaimsIncidentDetails(claimNumber:string): Promise<IIncidentDetails> {
    try {
      const response = await client(true).query({
        query: getClaimsIncidentDetails,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          clientType: 'VENDOR_USER'
        },
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }

      if (!response?.data?.getClaimsIncidentDetails) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      trackCustomHeapEvent('get-claims-incidnet-details-service-success', {});

      return response?.data?.getClaimsIncidentDetails;
    } catch (error) {
      trackCustomHeapEvent('get-claims-incidnet-details-service-service-error', {});
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  }
}

export default GetClaimsIncidentDetailsService;
