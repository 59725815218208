import moment from 'moment-timezone';

export const EXPRESS_PHOTO: string = 'express_photo';

const defaultDateError = '-';

export const convertToShortDate = (date: string) => {
  let _date = new Date(date);
  const retDate = moment(_date)
    // IMPORTANT: here we are not using DayJS because it has compatibility issue with IE11.
    .tz('America/New_York')
    .format('MM/DD/YYYY');
  return retDate === 'Invalid date' ? defaultDateError : retDate;
};

export const convertToShortDateTime = (date: Date | string) => {
    
  if (!date) {
    return defaultDateError;
  }

  if (typeof(date) === 'string') {
    date=new Date(date);
  }
    
  const retDate = moment(date)
    // IMPORTANT: here we are not using DayJS because it has compatibility issue with IE11.
    .tz('America/New_York')
    .format('MM/DD/YYYY [at] hh:mm a');

  return retDate === 'Invalid date' ? defaultDateError : retDate;
};

export const formatCurrency = (amount: string) => `$${parseFloat(amount).toFixed(2)}`;

export const escapeString = (inputString: string) => {
  return inputString
    .replace(/\\/g, '\\')
    .replace(/\\'/g, '\\\'')
    .replace(/\\n/g, '\\n')
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f');
    
};
