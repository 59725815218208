
import isUsingIe11 from '../../../../utils/ie11-check';
import Banner from '../../../common/components/banner/banner';

const browserIsIe11 = isUsingIe11();
const GlobalBanners = (): JSX.Element => (
  <>
    {
      browserIsIe11 ?
        <Banner type="error">
          Please change your browser. Internet Explorer is outdated. For the best experience, please use Chrome, Firefox, or Edge.
        </Banner>
        : null
    }
  </>
);

export default GlobalBanners;
