import env from './env';
import { Envs } from '../features/common/models/generic-enum';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function rumAnalytics() {
  const isProdEnv = env.appEnv === Envs.Production;
  if (Envs.Production === env.appEnv || Envs.Staging === env.appEnv) {
    datadogRum.setGlobalContextProperty(
      'troux_uuid',
      'A9CB9A25-C6FB-471A-B2D6-2394237BC49B'
    );
    datadogRum.init({
      applicationId: '87c75602-f13b-4b51-955d-c66ddb376437',
      clientToken: 'pubfc512483c7b49aa9f718e3862832e388',
      site: 'datadoghq.com',
      service: 'cipautobody.libertymutual.com',
      env: isProdEnv ? 'prod' : 'non-prod',
      allowedTracingUrls: [
        {
          match: isProdEnv
            ? 'https://eservice.libertymutual.com/apptrove/api/graphql'
            : 'https://ete-eservice.libertymutual.com/apptrove/api/graphql',
          propagatorTypes: ['tracecontext']
        }
      ],
      traceSampleRate: 20,
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask'
    });

    datadogRum.startSessionReplayRecording();

    datadogLogs.init({
      clientToken: 'pubfc512483c7b49aa9f718e3862832e388',
      service: 'cipautobody.libertymutual.com',
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100
    });
  }
}
