import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { ErrorMessages } from '../../../utils/errorMessages';

const getDocumentUrlQuery: any = loader('../../../graphql/queries/get-document-url.graphql');

class GetDocumentUrlService {
  async getDocumentUrl(documentId:string): Promise<any> {
    try {
      const response = await client(true).query({
        query: getDocumentUrlQuery,
        variables: {
          documentId: documentId,
          clientType: 'VENDOR_USER'
        },        
        fetchPolicy: 'no-cache'
      });

      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }
      if (!response?.data?.getDocumentURL) {
        throw new Error(ErrorMessages.DOCUMENT_URL_NOT_FOUND);
      }

      trackCustomHeapEvent('get-document-url-success', { response });
      return response?.data?.getDocumentURL;

    } catch (error) {
      trackCustomHeapEvent('get-document-url-error', { error });
      throw new Error(ErrorMessages.SERVICE_UNAVAILABLE);
    }
  } 
}

export default GetDocumentUrlService;

