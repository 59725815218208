import React, { ReactElement } from 'react';
import { GridCol, GridRow, Link } from '@lmig/lmds-react';
import CPRAIcon from './cpra-icon-component';
import env from '../../../../utils/env';
import { Envs } from '../../../common/models/generic-enum';

const CaliforniaPrivacyChoices: React.FC = (): ReactElement => {
  const CPRA_PROD_URL: string =
    'https://privacyportal.onetrust.com/hosted-webform/consent/50577d86-8d39-4310-9583-33e6d3da8e17/9b108b70-4ef6-4d93-b7ec-60e41f03b292';
  const CPRA_NON_PROD_URL: string =
    'https://privacyportaluat.onetrust.com/hosted-webform/consent/7acbfc95-48e8-451b-90d0-b392f6597d85/3fcd7033-3566-4030-ac46-37f202757f2b';

  return (
    <>
      <GridRow id="claim-information-footer">
        <GridCol md={10} sm={12}>
          <ul>
            <li>
              <CPRAIcon />{' '}
              <Link
                data-name="Footer-PrivacyPolicy-Link"
                variant="navigational"
                onBackground="blue"
                href={
                  env.appEnv === Envs.Production
                    ? CPRA_PROD_URL
                    : CPRA_NON_PROD_URL
                }
              >
                Your California Privacy Choices
              </Link>
            </li>
            <li>
              <Link
                data-name="Footer-CPRA-notice"
                variant="navigational"
                onBackground='blue'
                onClick={() => {
                  window.utag.gdpr.showDoNotSellPrompt();
                }}
              >
                Do Not Sell or Share My Personal Information (CA Residents Only)
              </Link>
            </li>
          </ul>
        </GridCol>
      </GridRow>
    </>
  );
};

export default CaliforniaPrivacyChoices;
