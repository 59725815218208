import { Alert, BodyText, Button, Caption, Heading } from '@lmig/lmds-react';
import EmptySpace from '../../common/components/empty-space/empty-space-component';

const ContactSupportFormError = (props: { onClose: Function }) => {
  return (
    <>
      <Heading type='h3-light'>Contact CIP Support</Heading>
      <BodyText>
        <Alert highlightType="negative">Your support ticket was unable to be submitted.</Alert>
      </BodyText>
      <Caption>There was an error during submission, please try again later.</Caption>
      <EmptySpace height={40} />
      <Button 
        dynamicWidth 
        variant="primary"
        onClick={() => { props.onClose(); }}>
          Close
      </Button>
    </>
  );
};

export default ContactSupportFormError;
