import { client } from '../../common/client/apollo-client';
import { loader } from 'graphql.macro';
import { trackCustomHeapEvent } from '../../../utils/heap';
import { ErrorMessages } from '../../../utils/errorMessages';
// import { IDocument } from '../../payments/models/payment-info-interface';

const getDocumentsQuery: any = loader('../../../graphql/queries/get-documents.graphql');

class DocumentsService {
  // async getDocuments(claimNumber:string): Promise<IDocument []> {
  async getDocuments(claimNumber:string){
    try {
      const response = await client(true).query({
        query: getDocumentsQuery,
        variables: {
          claimNumber: claimNumber.split('-')[0],
          exposureNumber: claimNumber.split('-')[1]
        },        
        fetchPolicy: 'network-only'
      });
      if (response?.errors?.[0]?.message) {
        throw new Error(response?.errors?.[0]?.message);
      }

      if (!response?.data?.getDocuments) {
        throw new Error(ErrorMessages.CLAIM_NOT_FOUND);
      }
      trackCustomHeapEvent('get-documents-query-success', {});
      return response?.data?.getDocuments?.documents || [];      
    } catch (error) {
      trackCustomHeapEvent('get-documents-query-error', {});
      throw new Error(ErrorMessages.DOCUMENTS_NOT_FOUND);
    }
  } 
}

export default DocumentsService;

