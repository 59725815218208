import {scroller} from 'react-scroll/modules';
import {animateScroll as scroll} from 'react-scroll';


class Scroller {

  // Elements
  private _mainElement: HTMLElement | null = window.document.querySelector('#claimant-app-main');
  private _stickyHeaderAndWayfinder: HTMLElement | null = window.document.querySelector('.lm-Header');
  private _lmsWayfinder: HTMLElement | null = window.document.querySelector('#claimant-subheader');

  // Private methods
  private _getClientHeight = (): number => window.document.documentElement.clientHeight;
  private _getInnerHeight = (): number => window.innerHeight;
  private _getCurrentFieldElement = (currentFieldId: string): HTMLElement | null => window.document.querySelector(`#${currentFieldId}`);


  /**
     * Updates the padding on the bottom of the screen to ensure it's long enough to
     * allow the scrolling feature to move the question to the top of the screen.
     * @private
     */
  private _setBottomPadding = (fieldHeight: number, headerHeight: number): void => {
    const windowHeight: number = Math.max(this._getClientHeight(), this._getInnerHeight() || 0);
    const padding: number = windowHeight - fieldHeight - headerHeight;
    const paddingStr: string = (padding > 0) ? `${padding}px` : '0px';
    if (this._mainElement) this._mainElement.style.paddingBottom = paddingStr;
  };


  /**
     * Handles calculating the correct distance to scroll based on the size of the header,
     * wayfinder, ghosting overlay, and top margin of the field.
     * @param currentFieldId
     */
  scrollToNext(currentFieldId: string): void {

    const field: HTMLElement | null = this._getCurrentFieldElement(currentFieldId);

    if (this._stickyHeaderAndWayfinder && this._lmsWayfinder && field) {

      const fieldHeight: number = field.offsetHeight;
      const stickyHeaderAndWayfinderHeight: number = this._stickyHeaderAndWayfinder.offsetHeight;
      const ghostingOverlay: number = this._lmsWayfinder.offsetHeight;
      const fieldMargin: number = parseInt(window.getComputedStyle(field).marginTop, 10);

      this._setBottomPadding(fieldHeight + fieldMargin, stickyHeaderAndWayfinderHeight);

      scroller.scrollTo(currentFieldId, {
        delay: 0,
        duration: 500,
        offset: -stickyHeaderAndWayfinderHeight - fieldMargin - ghostingOverlay * 1.5,
        smooth: 'easeInOutQuart'
      });

    }
  }

  scrollToTop(): void {
    scroll.scrollToTop();
  }


}

export default Scroller;
