import { observable, action, runInAction, configure, makeObservable } from 'mobx';
import { FieldNames, IFormError, SectionNames } from '../models/registration-common-types';
import * as schema from '../validation/registration-validation-schema';
import { MessageType } from '../models/registration-common-types';
import IRegistrationRequest from '../models/registration-request-interface';
import {registrationService} from '../services/registration-service';
import RegistrationStatuses from '../models/registration-statuses-enum';
import Scroller from '../components/scroller';
import RegistrationStatusCheck from '../services/registration-status-check';
import {trackCustomHeapEvent} from '../../../utils/heap';
import {providerSearchService} from '../services/provider-search-service';
import { IShop } from '../models/shop-interface';

const ANIMATION_DELAY = 150;
const VALIDATION_FAILURE_TAXID = 'We\'re sorry but the Tax ID you entered doesn\'t match our records. Please double-check and try again or you can reference the top of your appraisal or supplemental documents for your Tax ID information.';
const VALIDATION_FAILURE_LMID = 'We\'re sorry but the Liberty Mutual ID you entered doesn\'t match our records. Please double check or try to register using your federal Tax ID.';

// Enable Restrict mode. Recommended
// configure({ enforceActions: 'observed' });

/**
 * Registration Store
 *
 * @export
 * @class RegistrationStore
 */

configure({ enforceActions: 'observed' });
export default class RegistrationStore {
  
  constructor() {
    makeObservable(this); //Newer version of mobX 
  }

  @observable shops: IShop[] | null = null;
  @observable shop: IShop | null = null;
  
  @observable loading: boolean = false; // when api is loading...
  @observable error: string | null = null; // API error message. Not shown if null
  @observable creatingUser: boolean = false;

  @observable formErrorUserInfo: MessageType[] = []; // list of error messages for about user section
  @observable formErrorShopInfo: MessageType[] = []; // list of error messages for shop info section

  @observable firstName: string = '';
  @observable lastName: string = '';
  @observable email: string = '';
  @observable taxId: string = '';
  @observable lmId: string = '';
  @observable phone: string = '';
  @observable zipCode: string = '';

  @observable searchByLmId: boolean = false;

  @observable formErrors: IFormError = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    taxId: undefined,
    lmId: undefined,
    phone: undefined,
    zipCode: undefined
  };

  @observable continueForm: boolean = false;
  @observable formSubmittable: boolean = false; // control if form is submittable. When all errors are resolved.
  @observable allSubmittable: boolean = false;
  // add unique MessageType obj to an array.
  private static _addUniqueToArr(fieldName: FieldNames, message: string, arr: MessageType[]): void {
    if (!arr.find((obj: MessageType) => obj.text === message)) {
      arr.push({ fieldName, text: message, type: 'error' });
    }
  }

  // remove all objects by the specified field name from array.
  private static _removeFromArrayByFieldName(fieldName: FieldNames, arr: MessageType[]): void {
    while (arr.find(obj => obj.fieldName === fieldName)) {
      // @ts-ignore
      arr.remove(arr.find(obj => obj.fieldName === fieldName));
    }
  }


  @action
  async fieldValidation(fieldName: FieldNames, sectionName: SectionNames): Promise<void> {
    // create yup schema for validation
    try {
      await schema[fieldName].validate(this[fieldName]);
      runInAction(() => {
        this.formErrors[fieldName] = undefined;
        // @ts-ignore
        RegistrationStore._removeFromArrayByFieldName(fieldName, this['formError' + sectionName]);

      });
    } catch (error: any) {
      runInAction(() => {
        this.formErrors[fieldName] = 'error';
        // @ts-ignore
        RegistrationStore._addUniqueToArr(fieldName, error.message, this['formError' + sectionName]);
      });
    }
  }

  @action
  onChange(key: FieldNames, value: string | undefined, taxOrLmId: boolean = false): void {
    if (taxOrLmId && this.shops && this.shops.length > 0) {
      this.shops = null;
      this.shop  = null;
    }
    // @ts-ignore
    this[key] = value;
    if (!this.continueForm) {
      this.formSubmittable = this._vCheckUserForm();
    } else {
      this.formSubmittable = this._vCheckShopFormTaxID() || this._vCheckShopFormLmID();
      this.allSubmittable = this._vCheckUserForm() && (this._vCheckShopFormTaxID() || this._vCheckShopFormLmID());
    }
  }

  @action
  private _vCheckUserForm(): boolean {
    return schema.userSchema.isValidSync({
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email
    });
  }

  @action
  private _vCheckShopFormTaxID(): boolean {
    return schema.shopTaxIDSchema.isValidSync({
      taxId: this.taxId,
      phone: this.phone,
      zipCode: this.zipCode
    });
  }

  @action
  private _vCheckShopFormLmID(): boolean {
    return schema.shopLmIDSchema.isValidSync({
      lmId: this.lmId,
      phone: this.phone,
      zipCode: this.zipCode
    });
  }

  @action
  changeSearch(): void {
    // @ts-ignore
    this.searchByLmId = !this.searchByLmId;
  }

  @action
  setShop(value: IShop | undefined): void {
    // @ts-ignore
    this.shop = value;
  }

  @action
  async sendConfirmationEmail() {
    this.loading = true;
    return new Promise(resolve => setTimeout(resolve, 2000));
  }

  @action
  async getShops() {
    try {
      // get shop data
      this.loading = true;
      const shops: IShop[] = await providerSearchService.getProviders(this.phone, this.zipCode);
      trackCustomHeapEvent('registration-get-shop-list-success', {});
      runInAction(() => {
        this.shops = shops;
        this.shop = null;
        this.loading = false;
      });
    } catch (error: any) {

      trackCustomHeapEvent('registration-get-shop-list-error', {});
      runInAction(() => {
        this.loading = false;
        this.setError(error.message);
      });
    }
  }

  @action
  setContinueForm(): void {
    this.continueForm = true;
    this.formSubmittable = false;
    trackCustomHeapEvent('continue-button-clicked', {});
  }

  private setError(errMessage: string) {
    runInAction(() => {
      this.loading = false;
      this.creatingUser = false;
      this.error = errMessage;
      setTimeout(() => new Scroller().scrollToTop(), ANIMATION_DELAY);
    });
  }

  private clearFields(): void {
    this.firstName = '';
    this.lastName = '';
    this.taxId = '';
    this.lmId = '';
    this.phone = '';
    this.zipCode = '';
    this.shop = null;
    this.error = null;
    this.creatingUser = false;
  }

  @action
  async createUser(navigate: any): Promise<void> {

    // as soon as we have the tracking id, show the claimant loader
    this.loading = false;
    this.creatingUser = true;

    const formData: IRegistrationRequest = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      requestSource: 'vendor_user',
      taxId: this.taxId,
      lmId: this.lmId,
      recipientId: this.shop?.recipientId || ''
    };

    if (this.searchByLmId) {
      formData.taxId = ' ';
    } else {
      formData.lmId = ' ';
    }

    try {
      const { data }: any = await registrationService.createUser(formData);
      trackCustomHeapEvent('registration-create-user-success', { searchByLmId: this.searchByLmId });

      const trackingId = data.trackingId;
      // We got the trackingId now we need to check the status periodically

      const registrationStatusCheck = new RegistrationStatusCheck(trackingId);

      registrationStatusCheck.start();

      let lastStatus = '';

      registrationStatusCheck.onRegistrationStatusChange((status: string, terminating: boolean) => {
        // if status is not changed then don't do anything and return
        if (lastStatus === status) {
          return;
        }

        lastStatus = status;

        if (terminating) { // something happened and it's final
          switch (status) {
          // SUCCESS CASE
          case RegistrationStatuses.Completed:
            runInAction(() => {
              // ADD CUSTOM HEAP EVENT 
              trackCustomHeapEvent('registration-confirmation-page-displayed', {});
              navigate('/confirmation');              
              this.clearFields();
              setTimeout(() => new Scroller().scrollToTop(), ANIMATION_DELAY);
            });
            break;
            // SPECIFIC ERROR CASE
          case RegistrationStatuses.ValidationFailed:            
            trackCustomHeapEvent('registration-validation-failed', {});
            if (formData.taxId !== ' ') {
              this.setError(VALIDATION_FAILURE_TAXID);
            } else {
              this.setError(VALIDATION_FAILURE_LMID);
            }
            
            break;          
            // SPECIFIC ERROR CASE
          case RegistrationStatuses.IdentityCreationFailedDuplicate:            
            trackCustomHeapEvent('registration-identity-creation-failed-duplicate', {});
            this.setError('DUPLICATE_ACCOUNT_ERROR');
            break;
          case 'timed_out':            
            trackCustomHeapEvent('registration-timed-out-page-displayed', {});
            navigate('/error');
            this.clearFields();
            break;
            // ALL OTHER ERROR CASES.
          default:            
            trackCustomHeapEvent('registration-error-page-displayed', {});
            navigate('/error');
            this.clearFields();
            break;
          }
        }
      });

    } catch (error) {
      trackCustomHeapEvent('registration-create-user-error', { searchByLmId: this.searchByLmId });
      this.clearFields();
      navigate('/error');
    }
  }
}

export const registrationStore = new RegistrationStore();
