import {
  Heading,
  BodyText,
  Link
} from '@lmig/lmds-react';

const NeedHelp = () => {
  return (
    <>
      <Heading type={'h3-light'}>Need help?</Heading> 
      <BodyText type={'article'}>
        <p>
          Contact technical support. We will respond within 24 hours.
        </p>
      </BodyText>        
      <Link caretPosition="none" href="mailto: CIPSupport@LibertyMutual.com" variant="standalone">       
        CIPSupport@LibertyMutual.com
      </Link>
    </>
  );
};

export default NeedHelp;
