import React, { useState } from 'react';
import {
  Card,
  Button,
  Form,
  AlphanumericInput,
  GridRow,
  GridCol,
  FieldGroup
} from '@lmig/lmds-react';
import { claimsStatusStore as store } from '../store/claims-status-store';
import { observer } from 'mobx-react';
import styles from './claim-status-component.module.scss';

const ClaimStatusSearchCard = observer(({ handleSubmit, handleClear }: any) => {

  const [disabled, setDisabled] = useState<boolean | undefined>(true);
  const [error, setError] = useState<boolean | undefined>(false);
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.setClaimNumber(event.target.value.trim());
    setDisabled(store.validClaimNumber);
    if (!store.validClaimNumber)
      setError(false);
  };

  // const submit =  (event: React.FormEvent<HTMLInputElement>) => { ??
  const submit =  (event: any) => {
    event.preventDefault();
    handleSubmit(store.claimNumber);
  };
  const clear = () => {
    setError(false);
    handleClear();
    setDisabled(true);
  };

  return (
    <Card className={styles.searchcard}>
      <Form id="frmClaimSearch" onSubmit={submit}>
        <GridRow>
          <GridCol>
            <FieldGroup
              className={styles.lmFieldGroup}
              labelStyle="h3-light"
              labelTag="h3"
              labelVisual="View Claim Status"
              messages={!error ? [] : [
                {
                  text: ' Please enter a valid claim number',
                  type: 'error'
                }

              ]}
            />

            <AlphanumericInput
              labelVisual="Enter claim number. (eg. 123456789-0002)"
              type="text"
              name="claimNumber"
              highlightType={error ? 'error' : undefined}
              id="input--error"
              data-testid="search-claim"
              value={store.claimNumber}
              onChange={handleOnChange}
              onBlur={() => setError(store.validClaimNumber)}
            />
          </GridCol>
        </GridRow>

        <GridRow gutters>
          <GridCol md={12} lg={6}>
            <Button
              size="large"
              variant="primary"
              dynamicWidth={true}
              data-testid="search"
              disabled={disabled}
              type="submit"
            >
              Search
            </Button>
          </GridCol>
          <GridCol md={12} lg={6}>
            <Button
              size="large"
              variant="secondary"
              dynamicWidth={true}
              data-testid="clear"
              onClick={clear}
            >
              Clear
            </Button>
          </GridCol>
        </GridRow>
      </Form>
    </Card>
  );
});

export default ClaimStatusSearchCard;
