import { Card } from '@lmig/lmds-react';
import { DataTable, TableHeader, TableBody, Table, TableCell, TableRow} from '@lmig/lmds-react-table';
import { IExposureInfo } from '../../claim-search/models/claim-interface';
interface PropsType {
  exposureInfo: IExposureInfo | null;
}

const DeductibleInformation = (props:PropsType): JSX.Element => (
  <DataTable>           
    <Card
      elevation="raised"
    >
      <p><b>Deductible information</b></p>
      <Table id="deductible-information-table">
        <TableHeader>
          <TableRow>{<></>}</TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <b>Amount</b>
            </TableCell>
            <TableCell textAlign={'right'}>
              { props.exposureInfo && props.exposureInfo.coverageSummary.coverageDeductible.amount ? `$${props.exposureInfo.coverageSummary.coverageDeductible.amount}` : 'N/A'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Waived</b>
            </TableCell>
            <TableCell textAlign={'right'}>
              <b>{ props.exposureInfo && props.exposureInfo.coverageSummary.coverageDeductible.waived ? props.exposureInfo.coverageSummary.coverageDeductible.waived : 'No'}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  </DataTable> 
);

export default DeductibleInformation;
