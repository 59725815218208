import { GridRow, GridCol, Heading } from '@lmig/lmds-react';

import {
  DataTable,
  TableHeader,
  TableBody,
  Table,
  TableCell,
  TableRow
} from '@lmig/lmds-react-table';
import styles from '../../claim-search/claim-search.module.scss';
import { IAppraisalHistory } from '../model/appraisal-Info-interface';
import tableSkeleton from '../../common/components/table/table-skeleton-component';

interface PropsType {
  //appraisal: IAppraisal | null;
  appraisalHistory: IAppraisalHistory[] | null;
}

const AppraisalHistory = (props: PropsType): JSX.Element => {
  const renderAppraisalBody = () => 
    props.appraisalHistory?.map(
      (info: IAppraisalHistory, i): JSX.Element => (
        <TableRow key={i}>
          <TableCell key={0}>
            {info.date || 'N/A'}
          </TableCell>
          <TableCell key={1}>
            {info.type}
          </TableCell>
          <TableCell key={2}>{info.amount}</TableCell>
          <TableCell key={3}>{info.status}</TableCell>
          {/* <TableCell key={4}>{info.document}</TableCell> */}
        </TableRow>
      )
    );
    
  const renderAppraisal = () => {
    if (props.appraisalHistory === null || props.appraisalHistory?.length === 0) {
      return (<p data-testid="no-data-message">No appraisal history currently available at the moment</p>);
    } else {
      return (
        <GridRow gutters justify={'between'} className={styles.tableScroll}>
          <GridCol base={12}>
            <DataTable>
              <Table
                id="appraisal-history-table"
                rowStyle={'zebra'}
                data-testid="appraisal-history-table"
              >
                <TableHeader>
                  <TableRow>
                    <TableCell key={0} type="colHead">
                      Date
                    </TableCell>
                    <TableCell key={1} type="colHead">
                      Type
                    </TableCell>
                    <TableCell key={2} type="colHead">
                      Amount
                    </TableCell>
                    <TableCell key={3} type="colHead">
                      Status
                    </TableCell>
                    {/* <TableCell key={4} type="colHead">
                      Document
                    </TableCell> */}
                  </TableRow>
                </TableHeader>
                <TableBody data-testid="appraisal-history-table-body">
                  {false ? tableSkeleton(4) : renderAppraisalBody()}
                </TableBody>
              </Table>
            </DataTable>
          </GridCol>
        </GridRow>
      );
    };
  };

  return (
    <>
      <Heading type="h4-bold">Appraisal history</Heading>
      {renderAppraisal()}     
    </>
  );
};

export default AppraisalHistory;
