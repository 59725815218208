import { AuthClient } from '@lmig/dsc-auth0-token-manager';
import env from './env';

const auth0Config = env.auth0Config;

/**
 * This configuration is needed for Auth0
 */
let auth0Client: AuthClient;
// This check is necessary for jest to work.
if (!navigator.userAgent.includes('jsdom')) {
  auth0Client = new AuthClient({
    domain: auth0Config.domain,
    clientId: auth0Config.clientId,
    redirectUri: auth0Config.redirectUri,
    refreshDurationOffset: auth0Config.refreshDurationOffset,
    enableAutomaticTokenRefresh: auth0Config.enableAutomaticTokenRefresh,
    inactivityTimeoutDuration: auth0Config.inactivityTimeoutDuration,
    enableIdleTimeout: auth0Config.enableIdleTimeout,
    debug: auth0Config.debug || false
  });
}

export { auth0Client };
