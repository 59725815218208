import { Fragment } from 'react';
import { observer } from 'mobx-react';
import {
  BodyText,
  Button,
  Disclaimer,
  FieldGroup,
  GridCol,
  GridRow,
  Heading,
  Link,
  Radio,
  RadioGroup
} from '@lmig/lmds-react';
import { registrationStore as store } from '../store/registration-store';
import styles from './shop-list.module.scss';
import { useNavigate } from 'react-router-dom';
import EmptySpace from '../../common/components/empty-space/empty-space-component';
import { IShop } from '../models/shop-interface';

type ShopNameAndAddressProps = {
  name: string;
  address: string;
};
const ShopNameAndAddress = ({ name, address }: ShopNameAndAddressProps) => {
  return (
    <>
      <BodyText type="default-medium">
        <p className={styles.shopName}>{name}</p>
      </BodyText>
      <BodyText>
        <p className={styles.shopName}>{address}</p>
      </BodyText>
    </>
  );
};

const ShopList = observer(() => {
  let navigate = useNavigate();
  return (
    <Fragment>
      {((store.shops?.length === 0 ? <BodyText>No shops found</BodyText> :
        <FieldGroup
          className={styles.fieldGroup}
        >
          <GridRow gutters>
            <GridCol base={12} md={6}>
              <Heading type={'h4-light'}>Select your shop</Heading>
              <RadioGroup
                name="RadioGroup--default"
              >
                {store.shops?.map((shop: IShop) => (
                  <Radio
                    id={shop.recipientId}
                    key={shop.recipientId}
                    name="shopOptions"
                    data-testid="shop-list-item"
                    onChange={e => store.setShop(shop)}
                    labelVisual={
                      <ShopNameAndAddress
                        name={shop.dba || shop.name}
                        address={shop.address?.addressLine1}
                      />
                    }
                    value={shop.name}
                  ></Radio>
                ))}
              </RadioGroup>
              <EmptySpace height={20} />
              <Button
                data-name="cru-create-account-btn"
                data-testid="send-confirmation-email"
                size={'large'}
                variant={'primary'}
                dynamicWidth
                disabled={store.loading || !store.shop || !store.allSubmittable || store.creatingUser}
                onClick={e => {
                  e.preventDefault();
                  store.createUser(navigate);
                }}
                type={'submit'}
              >
                Send confirmation email
              </Button>
            </GridCol>
          </GridRow>
          <GridRow>
            <Disclaimer className={styles.termsAndCondTxt}>
              By selecting "Send Confirmation Email," you acknowledge that you have read and agree to our{' '}
              <Link href="https://libertymutualgroup.com/about-lm/corporate-information/terms-and-conditions" target="_blank" rel="noreferrer noopener">
                Terms and Conditions
              </Link>.
            </Disclaimer>
          </GridRow>
        </FieldGroup>
      ))}
    </Fragment>
  );
});
export default ShopList;
