import * as yup from 'yup';

export const firstName = yup.string()
  .required('Please enter a valid first name.');

export const lastName = yup.string()
  .required('Please enter a valid last name.');

export const email = yup.string()
  .email('Please enter a valid email.')
  .required('Please enter a valid email.')
  .max(60, 'email must be maximum of 60 characters.')
  .matches(/^(?!.*[&*+`;/\\'",�<>�%()#\\$!])/g, 'email must not contain characters: & * + `; / \' , % # $ !');


export const phone = yup.string()
  .required('Please Enter a 10 digit phone number.')
  .matches(/(^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$)/, 'Please enter a valid phone number.');

export const zipCode = yup.string().matches(/^[0-9]{5}$/, 'Zip Code must be 5 digits');

export const taxId = yup.string().matches(/(^[0-9]{9}$)|(^[0-9]{2}-[0-9]{7}$)/, 'Tax ID Must be 9 digits');

export const lmId = yup.string()
  .matches(/^[a-zA-Z0-9]*$/, 'Liberty Mutual ID must be alphanumeric.  No dashes or sepcial characters.')
  // .matches(/^CCC*$/, 'Liberty Mutual ID begin with CCC or TCCC')
  .min(10, 'Liberty Mutual ID must be a minimum of 10 characters')
  .max(15, 'Liberty Mutual ID must be a maximum of 15 characters');

export const registrationSchema = yup.object({
  firstName,
  lastName,
  email,
  phone,
  zipCode,
  taxId,
  lmId
});

export const userSchema = yup.object({
  firstName,
  lastName,
  email
});

export const shopTaxIDSchema = yup.object({
  taxId,
  phone,
  zipCode
});

export const shopLmIDSchema = yup.object({
  lmId,
  phone,
  zipCode
});
