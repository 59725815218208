import { DataTable, Table, TableBody, TableCell, TableHeader, TableRow } from '@lmig/lmds-react';
import TableShimmer from './table-shimmer-component';

const tableSkeleton = (colCount: number) =>
  (

    <DataTable>
      <Table
        id="payment-for-repairs-table"
        rowStyle={'zebra'}
        data-testid="payment-table-body"
      >
        <TableHeader>
          <TableRow>
            <TableCell key={0} type="colHead">
                    Date issued
            </TableCell>
            <TableCell key={1} type="colHead">
                    Payment method
            </TableCell>
            <TableCell key={2} type="colHead">
                    Status
            </TableCell>
            <TableCell key={3} type="colHead">
                    Amount
            </TableCell>
            <TableCell key={4} type="colHead">
                    Payee
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody data-testid="payment-table-body"> 
          {Array(3)
            .fill({})
            .map(
              (x:JSX.Element, i:number) => (
                <TableRow key={i}>
                  {Array(colCount)
                    .fill({})
                    .map(
                      (y:JSX.Element, j:number)  => { 
                        return (
                          <TableCell key={j}>
                            <TableShimmer />
                          </TableCell>
                        );
                      }
                    )}
                </TableRow>
              )
            )}
        </TableBody>
      </Table>
    </DataTable>
  );


export default tableSkeleton;
