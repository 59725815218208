import { Response } from 'miragejs';

export const incidentDetailsRequestRouter = (
  claimNumber: string
) => {
  if (claimNumber === '056359701') {
    return new Response(500, {}, {});
  } else if (claimNumber === '056359702') {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimsIncidentDetails: null
        },
        errors: [
          {
            path: ['getClaimsIncidentDetails'],
            data: null,
            errorType: '3000',
            errorInfo: null,
            locations: [
              {
                line: 2,
                column: 3,
                sourceName: null
              }
            ],
            message: 'getClaimsIncidentDetails Service error'
          }
        ]
      }
    );
  } else if (claimNumber === '056359703') {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimsIncidentDetails: [
            {
              lossCause: 'insuredHitInRear',
              lossDate: '2018-05-11',
              lossTime: '16:00',
              lossCategory: 'multiVehicle',
              lossLocation: {
                city: 'Orlando',
                state: 'FL'
              }
            }
          ]
        }
      }
    );
  } else if (claimNumber === '123456789') { 
    return new Response(
      200,
      {},
      {
        data: {
          getClaimsIncidentDetails: 
            {
              lossCause: 'insuredHitInRear',
              lossDate: '2018-05-11',
              lossTime: '16:00',
              lossCategory: 'multiVehicle',
              lossLocation: {
                city: 'Orlando',
                state: 'FL'
              }
            }
        }
      }
    );
  } else if (claimNumber === '056359705') { 
    return new Response(
      200,
      {},
      {
        data: {
          getClaimsIncidentDetails: 
            {
              lossCause: '',
              lossDate: '',
              lossTime: '',
              lossCategory: '',
              lossLocation: {
                city: '',
                state: ''
              }
            }
        }
      }
    );
  } else {
    return new Response(
      200,
      {},
      {
        data: {
          getClaimsIncidentDetails: {}
        }
      }
    );
  }
};

export default incidentDetailsRequestRouter;
