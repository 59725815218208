import { Response } from 'miragejs';

export const paymentInformationRouter = (claimNumber: string, exposureNumber: string) => {
  if (claimNumber === '123456789' && exposureNumber === '0005'){
    return new Response(200, {},
      {
        'data': {
          'getPaymentInfo': {
            'results': [
              {
                'id': '123',
                'paymentId': '033684605-0005-0000001',
                'deliveryMethod': '',
                'paymentMethod': '',
                'dateIssued': '',
                'dateCreated': '',
                'paymentStatus': 'voided',
                'netAmount': '',
                'checkNumber': '',
                'payees': []
              },
              {
                'id': '124',
                'paymentId': '033684605-0005-0000001',
                'deliveryMethod': 'central',
                'paymentMethod': 'manual',
                'dateIssued': '2022-01-21T00:22:47.549Z',
                'dateCreated': '2022-01-21T00:22:47.549Z',
                'paymentStatus': 'cleared',
                'netAmount': '3447.53',
                'checkNumber': '123',
                'payees': ['Boyd Group (US) Inc']
              }
            ]
          }
        }
      });
  } 
  if (claimNumber === '123456789' && exposureNumber === '0006'){
    return new Response(404, {},
      {
        'data': {
          'getPaymentInfo': [{}]
        }
      });
  } else if (claimNumber === '123456789' && exposureNumber === '0007'){
    return new Response(200, {},
      {
        'data': {
          'getPaymentInfo': {
            'results': [
              {
                'id': '123',
                'paymentId': '033684605-0005-0000001',
                'deliveryMethod': 'central',
                'paymentMethod': 'check',
                'dateIssued': '2022-03-17T00:20:52.339Z',
                'dateCreated': '2022-03-17T00:20:52.339Z',
                'paymentStatus': 'cleared',
                'netAmount': '351.38',
                'checkNumber': '123',
                'payees': ['Boyd Group (US) Inc']
              },
              {
                'id': '124',
                'paymentId': '033684605-0005-0000001',
                'deliveryMethod': 'central',
                'paymentMethod': 'manual',
                'dateIssued': '2022-01-21T00:22:47.549Z',
                'dateCreated': '2022-01-21T00:22:47.549Z',
                'paymentStatus': 'cleared',
                'netAmount': '3447.53',
                'checkNumber': '123',
                'payees': ['Boyd Group (US) Inc']
              }
            ]
          }
        }
      });  
  } else {
    return new Response(200, {},
      {
        'data': {
          'getPaymentInfo': {
            'results': [
              {
                'id': '123',
                'paymentId': '033684605-0005-0000001',
                'deliveryMethod': 'central',
                'paymentMethod': 'check',
                'dateIssued': '2022-03-17T00:20:52.339Z',
                'dateCreated': '2022-03-17T00:20:52.339Z',
                'paymentStatus': 'cleared',
                'netAmount': '1251.38',
                'checkNumber': '1233',
                'payees': ['Boyd Group (US) Inc']
              },
              {
                'id': '124',
                'paymentId': '033684605-0005-0000001',
                'deliveryMethod': 'central',
                'paymentMethod': 'manual',
                'dateIssued': '2022-01-21T00:22:47.549Z',
                'dateCreated': '2022-01-21T00:22:47.549Z',
                'paymentStatus': 'cleared',
                'netAmount': '3447.53',
                'checkNumber': '125',
                'payees': ['Boyd Group (US) Inc']
              }
            ]
          }
        }
      });  
  }
};

export default paymentInformationRouter;
