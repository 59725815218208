import { Card, Heading, Notification } from '@lmig/lmds-react';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow
} from '@lmig/lmds-react-table';
import {
  getPrimaryPhoneNumber,
  getSecondaryPhoneNumber
} from '../../../utils/get-phone-number';

import styles from '../../claim-search/claim-search.module.scss';
import { IExposureInfo } from '../../claim-search/models/claim-interface';
import CardShimmer from '../../common/components/card-shimmer/card-shimmer-component';

interface PropsType {
  isLoading: boolean;
  exposureInfo: IExposureInfo | null;
}

const ContactInformation = (props: PropsType): JSX.Element =>
  props.isLoading ? (
    <CardShimmer></CardShimmer>
  ) : (
    <DataTable>
      <Card className={styles.card} elevation="raised">
        <Heading type="h5-bold">Customer contact information</Heading>
        {props.exposureInfo?.claimant ? (
          <>
            <Table id="additional-information-table">
              <TableHeader>
                <TableRow>{<></>}</TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell
                    data-testid="additional-information-owner-name"
                    textAlign={'right'}
                  >
                    {props.exposureInfo?.claimant.name || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Primary phone</b>
                  </TableCell>
                  <TableCell textAlign={'right'}>
                    {getPrimaryPhoneNumber(
                      props.exposureInfo?.claimant?.communication?.phoneList
                    ) || '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>Secondary phone</b>
                  </TableCell>
                  <TableCell textAlign={'right'}>
                    {getSecondaryPhoneNumber(
                      props.exposureInfo?.claimant?.communication?.phoneList
                    ) || '-'}
                  </TableCell>
                </TableRow>               
                <TableRow>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell
                    data-testid="additional=information-owner-email"
                    textAlign={'right'}
                    className={styles.wordBreak}
                  >
                    {props.exposureInfo?.claimant?.communication
                      ?.emailList?.[0] ? (
                        <a
                          href={`mailto:${props.exposureInfo?.claimant.communication.emailList?.[0]}`}
                        >
                          {
                            props.exposureInfo?.claimant.communication
                              .emailList?.[0]
                          }
                        </a>
                      ) : (
                        'N/A'
                      )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        ) : (
          <Notification
            alert="There is no owner contact info"
            highlightType="negative"
          >
            Please try a different claim number.
          </Notification>
        )}
      </Card>
    </DataTable>
  );

export default ContactInformation;
