import GetExposureService from './get-exposure-service';
import LiabilityInvestigationService from './get-liability-investigation-service';
import RepairStatusService from './repair-status-service';
import PaymentInformationService from './get-payment-info-service';
import GetContactsService from './get-contacts-service';
import GetClaimsIncidentDetailsService from './get-claims-incident-details-service';

export const getExposureService = new GetExposureService();
export const liabilityInvestigationService = new LiabilityInvestigationService();
export const repairStatusService = new RepairStatusService();
export const paymentInformationService = new PaymentInformationService();
export const getContactsService = new GetContactsService();
export const getClaimsIncidentDetailsService = new GetClaimsIncidentDetailsService();
