import { GridRow, GridCol } from '@lmig/lmds-react';
import {claimsStatusStore as store} from '../store/claims-status-store';

const ClaimStatusSearchNoResultCard = ({claimNumber} : any) => {
  return (
    <>
      <GridRow data-testid='claimStatusNoResultData'>
        <GridCol>
          No results found for “{store.claimNumber}" 
          {/* {store.error.message} */}
        </GridCol>
      </GridRow>
    </>
  );
};

export default ClaimStatusSearchNoResultCard;
