import { Response } from 'miragejs';
import liabilityInvestigationRouter from './liability-investigation-router';
import exposuresRequestRouter from './exposures-request-router';
import repairStatusRouter from './repair-status-router';
import paymentInformationRouter from './payment-information-router';
import shopListRouter from './shop-list-router';
import contactsRouter from './contacts-router';
import appraisalDetailsRouter from './appraisal-details-router';
import cancelAppraisalRouter from './cancel-appraisal-router';
import getDocumentsRouter from './get-documents-router';
import getDocumentUrlRouter from './getDocumentUrlRouter';
import incidentDetailsRequestRouter from './get-claims-incident-details-router';
import claimStatusRouter from './claim-status-router';

export const mainRouter = (schema: any, request: any) => {
  const { variables, query } = JSON.parse(request.requestBody);
  let claimNumber, exposureNumbers, exposureNumber, incidentId, cancelationReason, documentId;
  switch (true) {
  case query.includes('getClaimStatus'):
    ({ claimNumber, exposureNumber } = variables);  
    return claimStatusRouter(claimNumber, exposureNumber);
  case query.includes('getLiabilityInvestigation'):
    ({ claimNumber, exposureNumber } = variables);    
    return liabilityInvestigationRouter(claimNumber, exposureNumber);
  case query.includes('findExposures'):
    ({ claimNumber, exposureNumbers } = variables); 
    return exposuresRequestRouter(claimNumber, exposureNumbers[0]);
  case query.includes('getRepairStatus'):
    ({ claimNumber, incidentId } = variables['repairStatusRequest']);
    return repairStatusRouter(claimNumber, incidentId);
  case query.includes('getPaymentInfo'):
    ({ claimNumber, exposureNumber } = variables);
    return paymentInformationRouter(claimNumber, exposureNumber);
  case query.includes('getProviders'):
    return shopListRouter(schema, request); 
  case query.includes('getContactsByClaimNumber'):
    ({ claimNumber, exposureNumber } = variables);    
    return contactsRouter(claimNumber);
  case query.includes('getAppraisalDetails'):
    ({ claimNumber, incidentId } = variables);
    return appraisalDetailsRouter(claimNumber, incidentId);
  case query.includes('cancelAppraisal'):
    ({ claimNumber, incidentId, exposureNumber, cancelationReason } = variables); 
    return cancelAppraisalRouter(claimNumber, incidentId, exposureNumber, cancelationReason);
  case query.includes('getDocuments'):
    ({ claimNumber, exposureNumber } = variables);
    return getDocumentsRouter(claimNumber, exposureNumber);
  case query.includes('getDocumentURL'):
    ({ documentId } = variables);
    return getDocumentUrlRouter(documentId);    
  case query.includes('getClaimsIncidentDetails'):
    ({ claimNumber, exposureNumber } = variables);
    return incidentDetailsRequestRouter(claimNumber);
  default:
    return new Response(400, {}, { message: 'No Router Found' });
  }  
};

export default mainRouter;

