import { Accordion, AccordionTab, Caption, Heading } from '@lmig/lmds-react';
import CannotViewAppraisalImg from '../images/ICantViewAppraisalInPortalWhyIsThisHappening_2.jpg';
import NotSeeingPaymentsImg from '../images/IfImNotSeeingPaymentsImLookingFor_1.jpg';

const generalQuestions = [
  { 
    id: 'q_how_to_find_rep',
    question: 'How can I find a customer\'s assigned Claims Representative? ', 
    answer: (
      <div>
        <p>
          You can contact the Repair Network Specialist (RNS) assigned to your shop 
          and they can help answer any questions you may have.
        </p>
      </div>
    )
  },
  { 
    id: 'q_what_claims_are_accessible',
    question: 'What claims are accessible in the Claim Information Portal (CIP)?', 
    answer: (
      <div>
        <p>
          The Claim Information Portal (CIP) provides information on any Liberty Mutual 
          or Safeco personal claims associated with your shop that are currently in open 
          status and those that have been closed <b>within the past 90 days</b>. It doesn't 
          currently support commercial claims.
        </p>
      </div>
    )
  }
]; 

const paymentQuestions = [
  { 
    id: 'q_not_seeing_payment',
    question: 'If I\'m not seeing the payment I\'m looking for, what should I do next?', 
    answer: (
      <div>
        <p>
          If you are a Guaranteed Repair Network (GRN) or Superior Service Program (SSP) shop 
          with Liberty Mutual or Safeco, please confirm that the following steps have been completed 
          in the CCC ONE&reg; estimating system:
        </p>
        <span>
          <ul>
            <li>Submit all payment requests by using the <b>Final Bill Indicator</b> event (see below)</li>
            <li>Complete the <b>Repair Captured</b> event</li>
            <li>Make sure <b>Direction to Pay (DTP)</b> is set to <b>Yes</b></li>
          </ul>
        </span>
        <p>
          <img 
            src={NotSeeingPaymentsImg} 
            alt='Screenshot showing CCC ONE&reg; estimating system with an arrow pointing to main menu link called Events' 
            width='100%'/>
        </p>
        <p>
          If you still don't see a payment and it's <b>been longer than 90 days</b> since the date of loss, please 
          select <b>Contact Support</b> in the menu above and fill out a support form or contact your Repair Network 
          Specialist. We will respond within 1 business day.
        </p>
        <p>
          If you're not a GRN or SSP shop, we recommend contacting the assigned Claims Representative.
        </p>
      </div>
    )
  },
  {
    id: 'q_how_long_for_payment',
    question: 'How long does it take for a payment to be issued to my shop?',
    answer: (
      <div>
        <p>For GRN or SSP shops, payments are issued after you've captured the <b>Final Bill</b> Indicator event in CCC ONE&reg;.</p>
        <p>For all other shops, payments are issued after we've reviewed and approved an estimate. All payments take <b>between 7-10 business days</b> to arrive via USPS.</p>
        <p>Once a payment has been issued, you can locate it in the <b>Payments</b> section of CIP. If you're having trouble finding a payment, be sure you followed the <a href='#q_not_seeing_payment'>required steps</a> to requesting payments.</p>
      </div>
    )
  },
  {
    id: 'q_how_do_i_set_dtp',
    question: 'How do I set up Direction to Pay (DTP) to pay my shop?',
    answer: (
      <div>
        <p>
          At drop off, verify that the assignment's “Direction to Pay” customer information matches the <b>Direction to 
          Pay</b> information found in CIP. If CIP's <b>Direction to Pay</b> status needs to be updated, please select <b>Contact 
          Support</b> in the menu above and fill out a support form. We will respond within 1 business day. 
        </p>
      </div>
    )
  }
];

const claimAssignmentsQuestions = [
  { 
    id: 'q_who_do_i_reach_out_to',
    question: 'I need an assignment. Who do I reach out to?', 
    answer: (
      <div>
        <p>
          If you are a Multi-Shop Operator (MSO) and need assistance, please follow your MSO procedure. 
          For all other shops, please contact your Repair Network Specialist or select <b>Contact Support</b> in 
          the menu above and fill out a support form. We will respond within 1 business day.
        </p>
      </div>
    )
  },
  { 
    id: 'q_why_i_cannot_view_appraisal',
    question: 'I cannot view my appraisal in the portal. Why is this happening? ', 
    answer: (
      <div>
        <p>
        If you are a Guaranteed Repair Network or Superior Service Program shop, please confirm your appraisal is 
        locked in the CCC ONE&reg; estimating system by first going to the <b>Estimate</b> tab and then 
        click <b>Lock Estimate</b> (see below).
        </p>
        <p>
          <img 
            src={CannotViewAppraisalImg} 
            alt='Screenshot showing CCC ONE&reg; estimating system with an arrow pointing to sub-menu item called Lock Estimate' 
            width='100%'/>
        </p>
        <p>
          For shops not affiliated with our Guaranteed Repair Network program, please reach out to your 
          assigned Claims Representative if it has been <b>more than 72 hours</b> since the estimate was submitted.
        </p>
      </div>
    )
  }
];

const FaqDetails = () => {
  return (
    <>
      <Heading data-testid='heading-faq-info' type='h3-light'>CIP Frequently Asked Questions</Heading>
      <Caption>We try to respond to inquiries within 1 business day, <b>Monday - Friday, 8 AM - 8 PM ET.</b></Caption>

      <Heading type='h4-light'>General</Heading>

      <Accordion as='h5'>
        {generalQuestions.map(({id, question, answer}, index) => {
          return (
            <AccordionTab id={id} key={'general_' + index} labelVisual={question}>
              {answer}
            </AccordionTab>
          );
        })}
      </Accordion>

      <Heading type='h4-light'>Payment</Heading>

      <Accordion as='h5'>
        {paymentQuestions.map(({id, question, answer}, index) => {
          return (
            <AccordionTab id={id} key={'payment_' + index} labelVisual={question}>
              {answer}
            </AccordionTab>
          );
        })}
      </Accordion>

      <Heading type='h4-light'>Claim Assignments</Heading>

      <Accordion as='h5'>
        {claimAssignmentsQuestions.map(({id, question, answer}, index) => {
          return (
            <AccordionTab id={id} key={'claims_' + index} labelVisual={question}>
              {answer}
            </AccordionTab>
          );
        })}
      </Accordion>
    </>
  );
};

export default FaqDetails;
