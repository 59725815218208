import { Response } from 'miragejs';

export const getDocumentsRouter = (claimNumber: string, exposureNumber: string) => {
  if (claimNumber !== '123456789' && exposureNumber !== '0002') {
    return new Response(404, {}, { message: 'No Documents Found' });
  } else {
    return new Response(200, {}, {
      'data': {
        'getDocuments': {
          'documents': [
            {
              'claimNumber': '055312337',
              'documentId': 'lYOyZezkd3KxLMp7',
              'documentType': 'Supplemental Appraisal',
              'mimeType': 'application/pdf',
              'fileName': 'Unknown.pdf',
              'createDate': '2022-12-23T19:59:31Z'
            },
            {
              'claimNumber': '055312339',
              'documentId': 'lYOyZezkd3KxLMp8',
              'documentType': 'Supplemental Appraisal',
              'mimeType': 'application/pdf',
              'fileName': 'Unknown2.pdf',
              'createDate': '2022-12-23T19:59:31Z'
            }
          ]
        }
      }
    });
  }
};

export default getDocumentsRouter;

