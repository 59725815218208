import { Alert, Button, Caption, BodyText, Heading } from '@lmig/lmds-react';
import EmptySpace from '../../common/components/empty-space/empty-space-component';

const ContactSupportFormSuccess = (props: { onClose: Function }) => { 
  return (
    <div 
      id='div-contact-support-form-success'
      data-testid='div-contact-support-form-success'
    >
      <Heading type='h3-light'>Contact CIP Support</Heading>
      <BodyText>
        <Alert highlightType="positive">Your support ticket was successfully submitted.</Alert>
      </BodyText>
      <Caption>A CIP support team member will review your issue and contact you soon.</Caption>
      <EmptySpace height={40} />
      <Button 
        dynamicWidth 
        variant="primary"
        onClick={() => { props.onClose(); }}>
          Close
      </Button>
    </div>
  );
};

export default ContactSupportFormSuccess;
