
import { Content, Notification } from '@lmig/lmds-react';
import styles from './banner.module.scss';

type BannerProps = {
  type?: 'informational' | 'error' | 'success';
  children: JSX.Element | string;
}

const Banner = ({type, children}: BannerProps) => (
  <Content fullWidth={true} className={styles.banner}>
    <Notification type={type} className={styles.notification}>
      { children }
    </Notification>
  </Content> 
);

export default Banner;
