import axiosInstance from '../../../utils/axios-instance';
import env from '../../../utils/env';
import IRegistrationRequest from '../models/registration-request-interface';

class RegistrationService {
  async createUser(registrationRequest: IRegistrationRequest) {
    return axiosInstance({
      method: 'POST',
      url: env.registrationRequestApiEndpoint,
      data: registrationRequest,
      withCredentials: false
    });
  }
}

export default RegistrationService;

export const registrationService = new RegistrationService();
